import { useContext, useState } from "react"
import { ArrowUpIcon, ChevronDown } from "lucide-react"
import { useBreakpoint } from "../misc/useBreakpoint"
import { UserContext } from "../contexts/UserProvider"

const BUTTON_STYLE = {
  display: "flex", alignItems: "center", gap: "0.25rem", padding: "0.75rem", borderRadius: "0.75rem", border: "none", background: "var(--color-backgroundTertiary)", color: "var(--color-text3)", fontSize: "0.95rem", cursor: "pointer"
}

export const ContentCreate_Container = ({ children, styleOverride }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '700px', alignSelf: 'center', height: '100%', width: '100%', margin: "auto", paddingBottom: "4rem", ...styleOverride }}>
      {children}
    </div>
  )
}


export const ContentCreate_Header = ({ title, subtitle, styleOverride, titleStyleOverride, subtitleStyleOverride }) => {

  const { isTabletAndBelow, isMobileAndBelow, isPhoneAndBelow } = useBreakpoint()

  const titleSize = !isMobileAndBelow ? '3rem' : '2rem'
  const subtitleSize = !isMobileAndBelow ? '1.2rem' : '1.1rem'
  const marginBottom = !isMobileAndBelow ? '3rem' : '2rem'

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: marginBottom, width: "100%", ...styleOverride }}>
      <h1 style={{ fontSize: titleSize, fontWeight: '600', textAlign: 'center', lineHeight: '1.2', ...titleStyleOverride }}>
        {title.split('**').map((part, i) => 
          i % 2 === 0 ? part : <span key={i} style={{ color: 'var(--color-primary)', backgroundColor: 'var(--color-backgroundSecondary)', padding: '.25rem .65rem', borderRadius: '.75rem', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>{part}</span>
        )}
      </h1>
      <div style={{ width: "80%" }}>
        <h2 style={{ fontSize: subtitleSize, fontWeight: '400', textAlign: 'center', color: 'var(--color-text3)', marginTop: '1.5rem', lineHeight: '1.4', ...subtitleStyleOverride }}
          >{subtitle}
        </h2>
      </div>
    </div>
  )
}

export const ContentCreate_Input = ({ placeholder, value, setValue, handleSubmit, children }) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const isActive = value !== ''

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && value !== '') {
      e.preventDefault();
      onSubmit(e);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return
    try {
      setIsLoading(true)
      await handleSubmit(e);
    }
    catch (error) {
      console.error('Error submitting:', error);
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: '100%', background: 'var(--color-background)', borderRadius: '1.5rem', padding: "1rem", gap: "1rem" }}
    >
      <div style={{ display: "flex", width: '100%', alignItems: 'center' }}>
        <input style={{ width: '100%', border: 'none', background: 'transparent', fontSize: '16px', outline: 'none', padding: "0 .4rem", }}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          aria-label="Search input"
        />
        <button style={{ width: '40px', height: '40px', backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.75rem', cursor: isActive ? 'pointer' : 'not-allowed', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isActive ? 1 : 0, position: 'relative' }}
          type="submit"
          aria-label="Submit search"
          onMouseEnter={(e) => isActive && (e.currentTarget.style.opacity = '0.7')}
          onMouseLeave={(e) => isActive && (e.currentTarget.style.opacity = '1')}
          disabled={!isActive}
          onClick={onSubmit}
        >
          <ArrowUpIcon size={24} />
          { isLoading && (
            <div style={{ display: 'flex', position: "absolute", inset: 0, alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--color-text1)', borderRadius: '.75rem' }}>
              <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
              <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
            </div>
          )}
        </button>
      </div>

      {children}
    </div>
  )
}

export const ContentCreate_InputSubButton = ({ label, onClick, children }) => {
  return (
    <button style={{ display: "flex", alignItems: "center", gap: "0.25rem", padding: "0.75rem", borderRadius: "0.75rem", border: "none", background: "var(--color-backgroundTertiary)", color: "var(--color-text3)", fontSize: "0.95rem", cursor: "pointer"}} 
      onClick={onClick}
      onMouseEnter={(e) => e.currentTarget.style.opacity = '0.5'}
      onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
    >
      {label}
      {children}
    </button>
  )
}

export const ContentCreate_InputSubButton_ModelLevel = ({  }) => {
  const { user } = useContext(UserContext)
  const [ modelLevel, setModelLevel ] = useState('Basic Model')
  
  if ( user?.isMember ) return null
  else return (
    <div style={{ ...BUTTON_STYLE, position: 'relative' }}
      onClick={() => document.getElementById('modelLevel').focus()}
      onMouseEnter={(e) => e.currentTarget.style.opacity = '0.5'}
      onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
    >
      <select style={{ appearance: 'none', position: 'absolute', inset: 0, width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
        id="modelLevel"
        value={modelLevel}
        onChange={(e) => setModelLevel("Basic Model")} 
      >
        {/* <option value="">Model Level</option> */}
        <option value="Basic Model">Basic Model</option>
        <option value="Advanced Model" disabled>Advanced (Members Only)</option>
      </select>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px', pointerEvents: 'none' }}>
        <span>{modelLevel ? modelLevel : 'Model Level'}</span>
        <ChevronDown size={14} strokeWidth={1.5} color="var(--color-text2)" />
      </div>
    </div> 
  )
}

export const ContentCreate_DemoButton = ({ onClick }) => {
  const { user } = useContext(UserContext)

  if ( user?.isMember ) return null
  return (
    <button style={{ background: 'none', border: 'none', color: 'var(--color-text4)', fontSize: '14px', padding: '4px', cursor: 'pointer', marginTop: '1rem', padding: '0.5rem 1rem', borderRadius: '0.5rem', }}
      onClick={onClick}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
    >
      See Example
    </button>
  )
}