import React, { useContext, useEffect } from 'react';
import { Menu, Sparkles } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { UserContext } from '../contexts/UserProvider';
import axios from 'axios';
import { API, dbRefs, stripeSubscriptionStates } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
const ICON_COLOR = 'var(--color-text3)'

export const SidebarContainer = ({ openSidebar, setOpenSidebar, children }) => {
  const { isTabletAndBelow } = useBreakpoint()

  useEffect(() => {
    if (isTabletAndBelow) {
      setOpenSidebar(false);
    }
    else {
      setOpenSidebar(true);
    }
  }, [isTabletAndBelow]);

  return (
    <div style={{ position: !isTabletAndBelow ? 'relative' : 'fixed', width: openSidebar ? '220px' : '0px', backgroundColor: 'var(--color-background)', display: 'flex', flexDirection: 'column', overflowY: 'auto', transition: "all 0.3s ease-in-out", left: 0, top: 0, bottom: 0, zIndex: 6, padding: openSidebar ? ".5rem" : "0px", borderRight: openSidebar ? '1px solid var(--color-backgroundTertiary)' : 'none', flexShrink: 0 }}>
      {children}
    </div>
  )
}

export const SidebarTab = ({ item, index, activeTab, handleTabClick,  }) => {
  // const selected = activeTab?.route === item?.route
  const selected = activeTab === item?.route
  let effectiveColor = selected ? "var(--color-text1)" : ICON_COLOR

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", padding: ".7rem .4rem", backgroundColor: "transparent", color: effectiveColor, borderRadius: ".5rem", overflow: "hidden", flexShrink: 0,
      ...(selected && { backgroundColor: "var(--color-backgroundTertiary)", })
     }}
     onClick={() => handleTabClick(item)}
    >
      <div style={{ minWidth: "1.5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {React.cloneElement(item?.icon, { size: "1.2rem", strokeWidth: 1.5, color: effectiveColor, })}
      </div>
      <span style={{ fontSize: "1rem", marginLeft: ".7rem", whiteSpace: "nowrap", textOverflow: "ellipsis",  }}
        >{item.title}
      </span>
    </div>
  )
}

export const SidebarTab2 = ({ item, index, activeTab, handleTabClick,  }) => {
  const selected = activeTab === item?.route
  let effectiveColor = selected ? "var(--color-text1)" : ICON_COLOR

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer", padding: ".7rem .4rem", backgroundColor: "transparent", color: effectiveColor, borderRadius: ".5rem", overflow: "hidden", flexShrink: 0, borderLeft: `1px solid var(--color-backgroundTertiary)`, marginLeft: '.5rem',
      ...(selected && { backgroundColor: "var(--color-backgroundTertiary)", })
     }}
     onClick={() => handleTabClick(item)}
    >
      <span style={{ fontSize: "1rem", marginLeft: ".7rem", whiteSpace: "nowrap", textOverflow: "ellipsis",  }}
        >{item.title}
      </span>
    </div>
  )
}

export const SidebarGroupHeader2 = ({ title, icon }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '1.5rem', marginBottom: '.5rem', paddingLeft: '.5rem', fontSize: '.85rem', fontWeight: '500', color: 'var(--color-text1)', letterSpacing: '0.02rem', textTransform: 'uppercase', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {React.cloneElement(icon, { size: 14, style: { marginRight: '6px' } })}
      {title}
    </div>
  )
}

export const SidebarGroupHeader = ({ title }) => {
  return (
    <div style={{ marginTop: '1.5rem', marginBottom: '.5rem', paddingLeft: '.5rem', fontSize: '.85rem', fontWeight: '500', color: 'var(--color-text3)', letterSpacing: '0.02rem', textTransform: 'uppercase', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {title}
    </div>
  )
}



export const SidebarUnderlay = ({ underlayIsVisible, setOpenSidebar, setUnderlayIsVisible }) => {
  const { isTabletAndBelow } = useBreakpoint()

  const handleClick = () => {
    setOpenSidebar(false);
    setUnderlayIsVisible(false);
  }

  if ( !isTabletAndBelow ) return null
  return (
    <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 5, opacity: underlayIsVisible ? 1 : 0, visibility: underlayIsVisible ? 'visible' : 'hidden', transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out"}}
      onClick={handleClick}
    />
  )
}


export const SidebarCollapsedHeader = ({ setOpenSidebar, setUnderlayIsVisible }) => {

  const { isMobileAndBelow, isPhoneAndBelow, isTabletAndBelow } = useBreakpoint()

  const handleClick = () => {
    setOpenSidebar(true);
    setUnderlayIsVisible(true);
  }

  if ( !isTabletAndBelow ) return null
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', 
      ...(isMobileAndBelow && { paddingLeft: '.5rem', paddingRight: '.5rem' }),
      ...(isPhoneAndBelow && { paddingLeft: '.25rem', paddingRight: '.25rem' })
    }}>
      <button 
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', color: 'var(--color-text1)' }}
        onClick={handleClick}
        aria-label="Open sidebar"
      >
        <Menu style={{ width: '1.5rem', height: '1.5rem', color: 'var(--color-text1)' }} />
      </button>
    </div>
  )
}


export const SidebarUpgradeButton = ({ }) => {

  const { user, setUser, setShowCheckoutModal } = useContext(UserContext)
  const navigate = useNavigate()

  const handleClick = async () => {
    try {
      let result = await axios.get(API + '/generalFindOne', { params: { 
        queryObj: JSON.stringify({ _id: user._id }), 
        dbRef: dbRefs.users 
      }});
      let results = result?.data;

      let userSubscriptionStatus = results?.stripeSubscription?.status

      if ( userSubscriptionStatus === stripeSubscriptionStates.active || userSubscriptionStatus === stripeSubscriptionStates.trialing ) {
        setUser({ ...user, ...results, isMember: true });
        alert("You are already a member! We just updated your account with full access.");
      }
      else {
        navigate(Routes.JOIN);
        // setShowCheckoutModal(true);
      }
    } 
    catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  }

  if ( !user?.isMember ) return (
    <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', padding: '.75rem 1rem', fontSize: '.95rem', fontWeight: '500', cursor: 'pointer', margin: '1rem', marginTop: 'auto', display: 'flex', alignItems: 'center', gap: '.5rem', cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      onClick={handleClick}
      onMouseEnter={(e) => e.currentTarget.style.opacity = '.7'}
      onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
    >
      <Sparkles color="var(--color-textButton)" size={16} />
      Upgrade to Plus
    </button>
  )

  else return null
}