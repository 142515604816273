'use client';
import React, { useContext } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { ArrowRight } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';


const TextInputModal = ({ open, setOpen, handleOnClick }) => {
  const { user,  } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();


  const handleClose = () => {
    setOpen(false);
  }

  return (
      <Drawer.Root open={open} onOpenChange={() => {}} dismissible={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000 }} 
          onClick={handleClose}
        />
        <Drawer.Content style={{ display: "flex", flexDirection: "column", position: "fixed", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", zIndex: 1001, backgroundColor: "var(--color-backgroundSecondary)",
          ...(isPhoneAndBelow && { bottom: 0, left: 0, right: 0 }),
          ...(!isPhoneAndBelow && { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '500px', margin: '0 auto', maxHeight: '90vh', borderRadius: '10px', overflow: 'hidden', animation: "none", })
         }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ width: '100%', margin: '0 auto', overflowY: 'auto', padding: isPhoneAndBelow ? '1.5rem' : '2rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '1.5rem' }}>
            
            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', fontSize: '1.8rem' }}
              id="drawer-title"
              >Enter Text
            </Drawer.Title>

            <textarea style={{ width: '100%', padding: '.75rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', fontSize: '16px', marginTop: '1rem', height: '150px', resize: 'none', background: 'var(--color-backgroundSecondary)', color: 'var(--color-text1)' }}
              placeholder="Enter your text here..."
            />

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '1.5rem' }}>

            <button style={{ flex: 1, padding: '1rem', borderRadius: '.5rem', border: 'none', fontSize: '1rem', cursor: 'pointer', backgroundColor: 'var(--color-text1)', color: 'var(--color-white)' }}
                onClick={handleOnClick}
                onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                >Submit
              </button>

              <button style={{ flex: 1, padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', fontSize: '1rem', cursor: 'pointer', backgroundColor: 'transparent', color: 'var(--color-text1)' }}
                onClick={handleClose}
                onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'} 
                onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
                >Cancel
              </button>


            </div>

          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default TextInputModal; 

