import React, { } from 'react';
import { CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import SchoolGoat from '../assets/schoolgoat.webp';

import { ArrowDown, PaperclipIcon, Refresh, RotateCcw } from 'lucide-react';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import { InlineMath, BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus, vs,  } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { COLOR_ARRAY, OPENAI_CHAT_ROLES } from '../misc/constants';


const defaultStyle = { marginBottom: '10px', fontSize: "1rem", lineHeight: 1.7, textAlign: "left", color: "var(--color-text1)" };

const preprocessLatex = (content) => {
  return content
    .replace(/\\\[([\s\S]*?)\\\]/g, (_, p1) => `$$ ${p1.trim().replace(/\s+/g, ' ')} $$`) // Convert \[ ... \] to $$ ... $$, preserving line breaks
    .replace(/\\\(([\s\S]*?)\\\)/g, (_, p1) => `$ ${p1.trim().replace(/\s+/g, ' ')} $`)  // Convert \( ... \) to $ ... $, preserving line breaks
    .replace(/\[([^[\]]*?)\]/g, (_, p1) => `$$ ${p1.trim().replace(/\s+/g, ' ')} $$`) // Convert single [ ... ] to $$ ... $$, preserving line breaks
    .replace(/\\(?![($])([a-zA-Z]+)/g, '\\\\$1') // Escape backslashes in text, but not in LaTeX commands
    .replace(/\\\\([a-zA-Z]+)/g, '\\$1'); // Remove extra backslashes before LaTeX commands
};

const CodeBlock = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      style={vscDarkPlus}
      customStyle={{ fontSize: "1rem", textAlign: "left", lineHeight: 1.3, marginTop: "-10px", marginBottom: "-10px", borderRadius: "10px", width: "100%", whiteSpace: "pre-wrap", wordWrap: "break-word", overflowWrap: "break-word",  }}
      language={match[1]}
      PreTag="div"
      {...props}
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code className={className} {...props}>
      {children}
    </code>
  );
};


const Teach_Message = ({ item, index, isPhoneAndBelow }) => {

  // const maxWidth = isPhoneAndBelow ? "90%" : "90%";
  const maxWidth = "100%"

  if (item.role === OPENAI_CHAT_ROLES.system) {
    return null;
  }

  else if ( item.role === OPENAI_CHAT_ROLES.assistant ) {
    
    const preprocessedLatex = preprocessLatex(item?.content)

    return (

      <div style={{ display: "flex", flexDirection: "row", maxWidth: maxWidth,}}>

        { item?.content ? 
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", textAlign: "left", maxWidth: "100%", position: "relative", }}>
            <ReactMarkdown 
              remarkPlugins={[remarkBreaks, remarkMath, ]}
              rehypePlugins={[rehypeKatex, rehypeRaw]}
              components={{
                h1: ({ node, ...props }) => <h1 style={{ ...defaultStyle, fontSize: '1.3rem', fontWeight: "600" }} {...props} />,
                h2: ({ node, ...props }) => <h2 style={{ ...defaultStyle, fontSize: '1rem', fontWeight: "600" }} {...props} />,
                h3: ({ node, ...props }) => <h3 style={{ ...defaultStyle, fontSize: '1rem', fontWeight: "600" }} {...props} />,
                h4: ({ node, ...props }) => <h4 style={{ ...defaultStyle, fontSize: '1rem', fontWeight: "600" }} {...props} />,
                h5: ({ node, ...props }) => <h5 style={{ ...defaultStyle, fontSize: '1rem', fontWeight: "600" }} {...props} />,
                h6: ({ node, ...props }) => <h6 style={{ ...defaultStyle, fontSize: '1rem', fontWeight: "600" }} {...props} />,
                ul: ({ node, ...props }) => <ul style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'disc' }} {...props} />,
                ol: ({ node, ...props }) => <ol style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'decimal' }} {...props} />,
                blockquote: ({ node, ...props }) => <blockquote style={{ ...defaultStyle, paddingLeft: '10px', borderLeft: '3px solid #ccc' }} {...props} />,
                code: CodeBlock,
                p: ({ node, ...props }) => {
                  const content = node?.value || "";
                  return content.includes('$') ? <Latex style={defaultStyle} {...props} /> : <p style={defaultStyle} {...props}/>
                },
                li: ({ node, ...props }) => {
                  const content = node?.value || "";
                  return content.includes('$') ? <Latex style={defaultStyle} {...props} /> : <li style={{ paddingLeft: '0px', marginBottom: "5px", listStyle: 'inherit', lineHeight: 1.7 }} {...props} />;
                },
                pre: ({ node, ...props }) => <pre style={{ ...defaultStyle, backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '4px', overflowX: 'auto' }} {...props} />,
                a: ({ node, ...props }) => <a style={{ color: '#007bff' }} {...props} />,
                hr: ({ node, ...props }) => <hr style={{ ...defaultStyle }} {...props} />,
                // strong: ({ node, ...props }) => <strong style={{ ...defaultStyle, fontWeight: "400", color: "var(--color-text)" }} {...props} />,
              }}
              >{preprocessedLatex}
            </ReactMarkdown>    
          </div>

        : 
          <CircularProgress size={13} color={"grey"} style={{ marginTop: "13px" }} />
        }
      </div>  
    )
  }

  else {

    if ( index !== 0 ) {
      return (
        // <ArrowDown size={25} color={"var(--color-text1)"} strokeWidth={1.5} style={{ alignSelf: "center" }} />
        
        // <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", height: "0", backgroundColor: "var(--color-backgroundTertiary)", margin: "2.5rem 0", borderTop: "2px solid var(--color-separatorOpaque)",  }}>
        // </div>

        <div style={{ ...defaultStyle, marginTop: "1rem", marginBottom: "1rem", backgroundColor: "var(--color-text1)", color: "white", padding: ".6rem 1rem", borderRadius: ".5rem", alignSelf: "flex-end", maxWidth: maxWidth, textAlign: "left", lineHeight: 1.7}}
        >{item?.content}
        </div>
      )
    }

    else return null
  }
};

export default Teach_Message;

