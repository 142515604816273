import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { API, dbRefs, MIXPANEL_EVENTS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { ChevronLeft, ChevronRight, ArrowRight, ArrowLeft, RotateCcw } from 'lucide-react';
import mixpanel from 'mixpanel-browser';

const cardStyle = { position: 'absolute', width: '100%', height: '100%', backfaceVisibility: 'hidden', backgroundColor: 'var(--color-backgroundSecondary)', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '1rem', fontSize: '1.4rem', padding: '4rem', lineHeight: '1.4', textAlign: 'center', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)"}
const buttonStyle = { background: 'var(--color-backgroundSecondary)', border: 'none', cursor: 'pointer', padding: '.75rem 1.5rem', borderRadius: '.75rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)'}


const RotateIcon = () => {
  return (
    <div style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
      <RotateCcw size={20} color="var(--color-primary)" />
    </div>
  )
}

const Resource_Flashcards = ({ isActive = true, data = {}, setData, setRefreshData }) => {
  const { user, } = useContext(UserContext)
  const [ content, setContent ] = useState([])
  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [isFlipped, setIsFlipped] = useState(false);
  const isFlippedRef = useRef(false);
  const handleFlip = () => setIsFlipped(!isFlipped);

  useEffect(() => {
    if ( isActive && data?.flashcards?.content ) {
      setContent(data?.flashcards?.content)
      setSelectedIndex(0)
    }
  }, [data, isActive])

  const handlePrev = () => {
    isFlippedRef.current = isFlipped
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : content.length - 1));

    mixpanel.track(MIXPANEL_EVENTS.flashcard_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  };

  const handleNext = () => {
    isFlippedRef.current = isFlipped
    setSelectedIndex((prev) => (prev < content.length - 1 ? prev + 1 : 0));

    mixpanel.track(MIXPANEL_EVENTS.flashcard_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  };  


  if ( !isActive ) return null
  if ( data === null ) return null
  // if ( !content || content.length === 0 ) return <GenerateComponent />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: "100%", height: "100%", }}>
      <div style={{ perspective: '7000px', width: "100%", height: "100%"}}>
        <div style={{ width: '100%', height: '100%', position: 'relative', transition: 'transform 0.5s', transformStyle: 'preserve-3d', transform: isFlipped ? 'rotateY(180deg)' : '', cursor: 'pointer', }}
          onClick={handleFlip}
        >
          {/* <Tile item={content?.[selectedIndex]} isFlippedRef={isFlippedRef.current} /> */}

          <div style={{...cardStyle, transform: isFlippedRef.current ? 'rotateY(180deg)' : ''}}>
            {content?.[selectedIndex]?.term}
            <RotateIcon />
          </div>
          <div style={{...cardStyle, transform: !isFlippedRef.current ? 'rotateY(180deg)' : ''}}>
            {content?.[selectedIndex]?.description}
            <RotateIcon />
          </div>


        </div>
      </div>

      {/* <span style={{ fontSize: '.9rem', color: 'var(--color-text3)', fontWeight: '500' }}>{data?.title}</span> */}
      
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
        <button style={buttonStyle}
          onClick={handlePrev} 
        >
          <ArrowLeft size={28} color="var(--color-text3)" />
        </button>
        <span style={{ fontSize: '1.2rem', color: 'var(--color-text3)', width: "5rem", textAlign: "center" }}>
          {`${selectedIndex + 1} / ${content.length}`}
        </span>
        <button style={buttonStyle}
          onClick={handleNext} 
        >
          <ArrowRight size={28} color="var(--color-text3)" />
        </button>
      </div>

    </div>
  )

}

export default Resource_Flashcards;
