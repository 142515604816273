import React from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';

const ScreenHeader = ({ title, subtitle, rightContent, actions = [], leftContent, styleOverride = {} }) => {
  const navigate = useNavigate();
  const { isTabletAndBelow, isMobileAndBelow } = useBreakpoint();

  const renderRightContent = () => {
    if (rightContent) {
      return rightContent;
    }

    return actions.map((action, index) => {
      if (React.isValidElement(action)) {
        return <React.Fragment key={index}>{action}</React.Fragment>;
      }

      return (
        <button
          style={{ padding: '8px', color: '#4b5563', cursor: 'pointer', border: 'none', background: 'none', display: 'flex', alignItems: 'center', gap: '4px' }}
          key={index}
          onClick={action.onClick}
          title={action.label}
        >
          {action.icon}
          {/* {action.label && <span style={{ fontSize: '14px' }}>{action.label}</span>} */}
        </button>
      );
    });
  };

  return (
    <header style={{ width: '100%', flexShrink: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "1rem", ...styleOverride }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {leftContent}

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
            {title && (
              <h1 style={{ flexShrink: 0, fontSize: '2rem', fontWeight: 600, flexShrink: 0, display: 'flex', }}
                >{title}
              </h1>
            )}
            {subtitle && (
              <p style={{ flexShrink: 0, fontSize: '1rem', marginTop: '.45rem', flexShrink: 0, display: 'flex', alignItems: 'center', color: 'var(--color-text4)', lineHeight: '1.25'  }}
                >{subtitle}
              </p>
            )}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {renderRightContent()}
        </div>
    </header>
  );
};

export default ScreenHeader;

          