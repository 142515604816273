'use client';
import React, { useState } from 'react';
import { Drawer } from 'vaul';

const ResourceProcessingModal = ({ open, setOpen }) => {

  return (
    <Drawer.Root open={open} onOpenChange={setOpen}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ maxWidth: '450px', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '32px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>

            <Drawer.Title className="modalTitle"
              id="drawer-title"
              >Notes processing
            </Drawer.Title>
            <Drawer.Description className="modalDescription" 
              id="drawer-description"
              >Your notes are generating now. Audio files typically take just a few minutes. <u>We will email you when they are ready.</u> Feel free to upload more files in the meantime.
            </Drawer.Description>
            <button className="modalButton"
              onClick={() => setOpen(false)}
              >Close
            </button>
          </div>

        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default ResourceProcessingModal;
