import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Ban, CircleAlert, CircleCheck, FileText, Loader2 } from 'lucide-react';
import Subheader from '../components/Subheader';
import { API, MIXPANEL_EVENTS, PAYWALL_TYPES } from '../misc/constants';
import mixpanel from 'mixpanel-browser';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs } from '../misc/constants';
import { v4 as uuidv4 } from 'uuid';
import { Resource_Loader } from '../components/Resource_Loader';

const VERTICAL_SPACING = '2.5rem';
const labelStyle = { fontSize: '1rem', fontWeight: '500', marginBottom: '0.75rem' }
const optionalInputStyle = { width: '100%', padding: '.75rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', fontSize: '16px', fontFamily: 'inherit', outline: 'none' }

const riskLevels = {
  low: { value: .33, label: 'Low', color: 'rgb(16 185 129)', colorLight: 'rgb(209 250 229)' },
  medium: { value: .66, label: 'Medium', color: 'rgb(245 158 11)', colorLight: 'rgb(254 243 199)' },
  high: { value: 1, label: 'High', color: 'rgb(239 68 68)', colorLight: 'rgb(254 226 226)' },
}

const getRiskLevel = (value) => {
  if (value < riskLevels.low.value) return riskLevels.low;
  if (value < riskLevels.medium.value) return riskLevels.medium;
  return riskLevels.high;
}

const Tile_Total = ({ score = .65 }) => {
  const riskLevel = getRiskLevel(score);
  const effectiveScore = Math.round(score * 100);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)', textAlign: 'center' }}>
      <div style={{ fontSize: '3rem', fontWeight: 700, color: riskLevel.color, lineHeight: '1', backgroundColor: riskLevel.colorLight, padding: '.5rem', borderRadius: '.5rem' }}>
          {effectiveScore.toFixed(0)}%
        </div>
      <div>
        <h2 style={{ fontSize: '1.25rem', fontWeight: 500, color: 'var(--color-text1)', margin: 0 }}>
          Total AI Probability
        </h2>
        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', marginTop: '.6rem' }}>
          There is a <u>{riskLevel.label} Risk</u> that this was AI-generated
        </p>
      </div>

      {/* <div style={{ width: '100%', height: '8px', backgroundColor: '#f3f4f6', borderRadius: '4px', background: `linear-gradient(to right, ${riskLevel.color} ${effectiveScore}%, #f3f4f6 ${effectiveScore}%)` }} /> */}
    </div>
  );
};

const Tile_Dashboard = ({ item }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '.5rem' }}>
        <span style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text1)',  padding: '.5rem', borderRadius: '.5rem' }}
          >{item.name}
        </span>
      </div>
      
      <div style={{ fontSize: '1.8rem', fontWeight: 'bold', color: 'var(--color-text1)', marginBottom: '.45rem' }}
        >{item.percentage}%
      </div>
      
      <div style={{ fontSize: '.9rem', color: 'var(--color-text4)', display: 'flex', alignItems: 'center' }}>
        of tokens in text
      </div>
    </div>
  );
};

const Tile_Sentence = ({ item }) => {
  const riskLevel = getRiskLevel(item.score);

  return (
    <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#374151', backgroundColor: riskLevel.colorLight, padding: '2px 0px', borderRadius: '0px', transition: 'background-color 0.2s ease' }}>
      {item.sentence}
    </span>
  );
};




const AIDetectionScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext);
  const tabs = { input: "Input", evaluation: "Evaluation" }
  const [ activeTab, setActiveTab ] = useState(tabs.input);
  const [ inputText, setInputText ] = useState('');
  const [ data, setData ] = useState(null);

  const isSubmittable = inputText && inputText.length > 10;
  const [ loading, setLoading ] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.ai_detection)
      if ( !hasPermission ) {
        setLoading(false)
        return;
      }

      const response = await axios.post(`${API}/ai-detector`, { text: inputText });
      const results = response.data;
      const { data: responseData } = results;

      const sentences = responseData.tokens.map((item, index) => {
        if (!item.includes('<') || !item.includes('>')) {
          return {
            sentence: item,
            score: responseData.token_probs[index],
          };
        }
      }).filter(Boolean);

      const sentenceCount = sentences.length
      let lowRiskCount = 0;
      let mediumRiskCount = 0;
      let highRiskCount = 0;
      
      for (const item of sentences) {
        if (item.score < riskLevels.low.value) {
          lowRiskCount++;
        } else if (item.score < riskLevels.medium.value) {
          mediumRiskCount++;
        } else {
          highRiskCount++;
        }
      }

      const dashboardData = [
        { name: "Low Risk", value: lowRiskCount, percentage: Math.round(lowRiskCount / sentenceCount * 100), icon: <CircleCheck />, color: riskLevels.low.color, colorLight: riskLevels.low.colorLight },
        { name: "Medium Risk", value: mediumRiskCount, percentage: Math.round(mediumRiskCount / sentenceCount * 100), icon: <CircleAlert />, color: riskLevels.medium.color, colorLight: riskLevels.medium.colorLight },
        { name: "High Risk", value: highRiskCount, percentage: Math.round(highRiskCount / sentenceCount * 100), icon: <Ban />, color: riskLevels.high.color, colorLight: riskLevels.high.colorLight },
      ]

      const dataObj = {
        score: responseData.score,
        dashboard: dashboardData,
        sentences: sentences,
      }

      console.log(dataObj);

      setData(dataObj);
      setActiveTab(tabs.evaluation);
      mixpanel.track(MIXPANEL_EVENTS.essay_ai_detection, { user_id: user?._id, user_email: user?.email });

      const evaluationObj = {
        _id: uuidv4(),
        user_id: user?._id,
        user_email: user?.email,
        date: new Date(),
        updatedAt: new Date(),
        creationTime: Date.now(),
      }

      await axios.post(`${API}/generalInsertOne`, { doc: evaluationObj, dbRef: dbRefs.ai_detection });
    } 
    catch (err) {
      // const { msg } = err.response.data;
      console.log({err: err});
      alert(err);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div id="wrapper">          
      <div id="main" >
        <div className="inner" >

          {/* <ScreenHeader title="AI Detection" subtitle="Check for AI-generated text in your essays" /> */}

          { data && <Subheader activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} /> }

          { loading ?
            <Resource_Loader isActive={true} text="Analyzing your essay" subText="This may take ~10 seconds" />
          
          : activeTab === tabs.input ?
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
              <label htmlFor="essay" style={labelStyle}>
                Essay Text <span style={{ color: '#ef4444' }}>*</span>
              </label>
              <textarea style={{ ...optionalInputStyle, height: "20rem", width: '100%', color: 'var(--color-text2)', lineHeight: '1.3' }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                placeholder="Paste your essay here..."
              />

              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'flex-start', marginTop: '1rem' }}>
                <button style={{ padding: '.75rem 1.5rem', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', fontSize: '1rem', cursor: 'pointer', width: '100%',
                  ...(!isSubmittable && { cursor: 'not-allowed', opacity: .3 }),
                }}
                  onClick={getData}
                  disabled={!isSubmittable || loading}
                  onMouseEnter={e => { if (isSubmittable) { e.target.style.opacity = '0.7'; } }}
                  onMouseLeave={e => { if (isSubmittable) { e.target.style.opacity = '1'; } }}
                >
                  Analyze Essay
                </button>
              </div>
            </div>
          
          : activeTab === tabs.evaluation ?

            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>

              <Tile_Total score={data?.score} />

              <div style={{ display: 'flex', justifyContent: "space-evenly", flexWrap: 'wrap', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
                {data?.dashboard.map((item) => (
                  <Tile_Dashboard key={item.name} item={item} />
                ))}
              </div>

              <div style={{ backgroundColor: 'white', borderRadius: '.5rem', padding: '1.5rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', }}>
                {data?.sentences.map((item, index) => (
                  <Tile_Sentence key={index} item={item} />
                ))}
              </div>

            </div>
        
          : null }

        </div>
      </div>
    </div>
  );  
}

export default AIDetectionScreen;






      // const sentenceCount = responseData.sentence_scores.length
      // let lowRiskCount = 0;
      // let mediumRiskCount = 0;
      // let highRiskCount = 0;
      
      // for (const item of responseData.sentence_scores) {
      //   if (item.score < riskLevels.low.value) {
      //     lowRiskCount++;
      //   } else if (item.score < riskLevels.medium.value) {
      //     mediumRiskCount++;
      //   } else {
      //     highRiskCount++;
      //   }
      // }

      // console.log(responseData, responseData?.token_probs)

      // const dashboardData = [
      //   { name: "Low Risk Sentences", value: lowRiskCount, percentage: Math.round(lowRiskCount / sentenceCount * 100), icon: <CircleCheck />, color: 'green' },
      //   { name: "Medium Risk Sentences", value: mediumRiskCount, percentage: Math.round(mediumRiskCount / sentenceCount * 100), icon: <CircleAlert />, color: 'orange' },
      //   { name: "High Risk Sentences", value: highRiskCount, percentage: Math.round(highRiskCount / sentenceCount * 100), icon: <Ban />, color: 'red' },
      // ]