import React, { useEffect, useRef, useState } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Resource_Teach from '../components/Resource_Teach3';
import { Resource_Loader } from '../components/Resource_Loader';
import { getResourceTextUrl } from '../misc/utils';



const ResourceTeachScreen = ({ }) => {
  const { data, setData, setRefreshData } = useOutletContext();
  const [ loading, setLoading ] = useState(!data?.teach?.content || data?.teach?.content?.length === 0);
  const lastLoadedIdRef = useRef();
  
  useEffect(() => {
    const needsDataLoad = (
      data?._id && 
      data?._id !== lastLoadedIdRef.current && 
      ( !data?.teach?.content || data?.teach?.content?.length === 0 )
    );

    if (needsDataLoad) {
      generateContent();
      lastLoadedIdRef.current = data?._id;
    }
  }, [data?._id]); 

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      await axios.post(`${API}/generateTeach2`, { resource_id: data._id, content_url });
      setRefreshData((prev) => !prev);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleSave = async ({ messages, index }) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: data?._id },
        updateObj: { $push: { [`teach.content.${index}.messages`]: { $each: messages } }},
        dbRef: dbRefs.resources,
      })

      setData(prev => ({
        ...prev,
        teach: {
          ...prev.teach,
          content: prev.teach.content.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                messages: [
                  ...item.messages,
                  ...messages
                ]
              }
            }
            return item
          })
        }
      }))
    }
    catch (error) {
      console.error('Error in handleSave:', error);
    }
  }

  return (
    <div id="wrapper" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', backgroundColor: 'var(--color-backgroundSecondary)' }}>   
      <Resource_Loader isActive={loading} text="Generating tutorial" subText="This may take ~10 seconds" />

      { !loading && data?.teach?.content && (
        <Resource_Teach isActive={!loading} data={data} handleSave={handleSave} />
      )}
          
    </div>
  );  
};


export default ResourceTeachScreen;

