import React, { useContext } from 'react';
import { RefreshCw, TrendingUp, TrendingDown } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import { PAYWALL_TYPES } from '../misc/constants';

const tabs = [
  {
    id: 'refresh',
    icon: <RefreshCw className="h-8 w-8" />,
    title: 'Refresh Questions',
    description: 'Create a new set of questions on this material',
    instructions: "Generate a new set of questions on this material at the same difficulty level"
  },
  {
    id: 'harder',
    icon: <TrendingUp className="h-8 w-8" />,
    title: 'Increase Difficulty',
    description: 'Generate a more difficult version of this quiz',
    instructions: "Generate a more difficult version of this quiz with more challenging questions"
  },
  {
    id: 'easier',
    icon: <TrendingDown className="h-8 w-8" />,
    title: 'Decrease Difficulty',
    description: 'Generate an easier version of this quiz',
    instructions: "Generate an easier version of this quiz with less challenging questions"
  }
];

const Resource_Quiz_Edit = ({ data, handleEdit }) => {
  const { user, setShowPaywallType } = useContext(UserContext);

  const handleTabClick = async (tab) => {
    if (!user?.isMember) {
      setShowPaywallType(PAYWALL_TYPES.MEMBERS_ONLY_FEATURE);
      return;
    }

    await handleEdit(tab.instructions);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '1rem' }}>
        { Object.values(tabs).map(tab => {
          return (
            <div key={tab.title} style={{ fontSize: '1rem', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', background: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', padding: '1.25rem', borderRadius: '.5rem',  display: 'flex', flexDirection: 'column', alignItem: "flex-start", justifyContent: "flex-start", height: '100%', cursor: 'pointer', width: "100%", maxWidth: "600px", position: "relative" }}
              onClick={async() => await handleTabClick(tab)}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
            >
              <div style={{ backgroundColor: '#EEE7FE', borderRadius: '30px', padding: '.5rem', display: 'inline-flex', marginBottom: '1rem', alignSelf: 'flex-start' }}>
                {React.cloneElement(tab.icon, { size: 20, color: 'var(--color-primary)', style: { } })}
              </div>
              <span style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem' }}
                >{tab.title}
              </span>
              <span style={{ fontSize: '1rem', color: 'var(--color-text4)', marginTop: '.75rem'  }}
                >{tab.description}
              </span>

              {/* {!user?.isMember && (
                <div style={{ position: 'absolute', top: '.5rem', right: '.5rem', backgroundColor: 'var(--color-backgroundTertiary)', padding: '0.35rem 0.35rem', borderRadius: '0.25rem', fontSize: '0.75rem', color: 'var(--color-text4)' }}>
                  Members Only
                </div>
              )} */}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Resource_Quiz_Edit;