import React, { useState, useEffect } from 'react';

const RefundScreen = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <main style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "50px"}}>
      <h1 id="terms-title">Refund Policy</h1>
        <section aria-labelledby="terms-title">
          <p style={{whiteSpace: "pre-wrap", fontSize: "16px", color: "var(--color-text)", maxWidth: "1000px", margin: "0 auto", padding: "20px", borderRadius: "5px", color: "black"}}
            tabIndex="0" 
            role="region" 
            aria-label="Terms of Service content"
            
          >
            Refunds are provided at the sole discretion of School Goat and on a case-by-case basis and may be refused. School Goat will refuse a refund request if we find evidence of fraud, refund abuse, or other manipulative behaviour that entitles School Goat to counterclaim the refund. This does not affect your rights as a Consumer in relation to Products which are not as described, faulty or not fit for purpose.

        </p>
      </section>
    </main>
  );
};

export default RefundScreen;