import React, { useState, useContext, useEffect, useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import { UserContext } from '../contexts/UserProvider';
import { logPaywallAction } from '../misc/utils';
import { PAYWALL_EVENTS, PAYWALL_TYPES } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';

const standardHeader = "You've used your free credits"
const standardSubheader = "We're thrilled you are enjoying using School GOAT. To continue using, please see your options below."

const PAYWALL_CONFIG = {
  [PAYWALL_TYPES.DUPLICATE_ACCOUNT]: {
    title: "Duplicate Account",
    description: "You've already used your free trial on another account. Please sign up for a membership. This will get you access to all features. If you do not, you will lose access.",
  },
  [PAYWALL_TYPES.MEMBERS_ONLY_FEATURE]: {
    title: "PLUS Members Only",
    description: "This feature is reserved for School Goat PLUS members. To get access, please see your options below.",
  },
  default: {
    title: standardHeader,
    description: standardSubheader
  }
}


const FreeTrialPopup = () => {
  const { user,  showPaywallType, setShowPaywallType, setShowCheckoutModal } = useContext(UserContext);
  const { isMobileAndBelow } = useBreakpoint();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const paywallConfig = PAYWALL_CONFIG[showPaywallType] || PAYWALL_CONFIG?.default;
  const buttonInProgressRef = useRef(false);


  useEffect(() => {
    if (showPaywallType) {
      initPaywall();
    }
    else {
      setIsVisible(false);
    }
  }, [showPaywallType]);

  const initPaywall = async () => {
    try {
      setTimeout(() => { setIsVisible(true) }, 50);
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.PAYWALL_SHOWN, funnel: showPaywallType });
    }
    catch (error) {
      console.error('Error with paywall init:', error);
    }
  }

  const handleAccept = async () => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;

    try {
      await Promise.all([
        logPaywallAction({ user, eventType: PAYWALL_EVENTS.ACCEPTED, funnel: showPaywallType }),
        logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_SHOWN, funnel: showPaywallType }),
      ]);
    }
    catch (error) {
      console.error('Error with paywall accept:', error);
    }
    finally {
      navigate(Routes.JOIN);
      // setShowCheckoutModal(true);
      setIsVisible(false);
      setShowPaywallType(null);
      buttonInProgressRef.current = false;
    }
  }

  const handleCancel = async () => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;
    
    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.DECLINED, funnel: showPaywallType });
    }
    catch (error) {
      console.error('Error with paywall cancel:', error);
    }
    finally {
      setIsVisible(false);
      setShowPaywallType(null);
      buttonInProgressRef.current = false;
    }
  }

  // Button styles
  const buttonBaseStyle = { padding: '.8rem 1.2rem', borderRadius: '6px', cursor: 'pointer', fontSize: '14px', fontWeight: '500' };

  if (!showPaywallType) return null;
  return (
    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', minHeight: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '16px', zIndex: 1000, 
      opacity: isVisible ? 1 : 0, 
      transition: 'opacity 300ms ease-in-out' }}>
    <div style={{ padding: "1rem", width: "95%", }}>
    <div style={{maxWidth: '500px', margin: '0 auto', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1.8rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
      <div style={{ marginBottom: '1.5rem' }}>
        <h2 style={{ fontSize: '1.2rem', fontWeight: '600', marginBottom: '16px' }}>
          {paywallConfig?.title}
        </h2>
        <p style={{ color: 'var(--color-text3)', fontSize: '1rem', lineHeight: '1.5' }}>
          {paywallConfig?.description}
        </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>
        <button 
          style={{ ...buttonBaseStyle, backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)' }}
          onClick={handleCancel}
          onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
        >
          Cancel
        </button>
        <button style={{ ...buttonBaseStyle, backgroundColor: 'var(--color-text1)', color: 'white', border: 'none', fontWeight: 500 }}        
          onClick={handleAccept}
          onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-text3)'}
          onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-text1)'}
        >
          See Options
          <ArrowRight style={{ marginLeft: '6px', width: '16px', height: '16px' }} />
        </button>
      </div>
    </div>
  </div>
  </div>
  )

};

export default FreeTrialPopup;
