import React, { useState, useRef, useEffect, } from 'react';
import { Lightbulb, Book, PenTool, Layout, MessageSquare, Check, ArrowRight } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { ChevronDown, ChevronUp, CheckCircle, XCircle, Wrench, ArrowLeft } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import EssayFeedbackModal from '../modals/EssayFeedbackModal';

const riskLevels = {
  a: {
    value: .9,
    label: 'A',
    color: '#10b981',
    colorLight: '#d1fae5',
  },
  b: {
    value: .8,
    label: 'B',
    color: '#84cc16',
    colorLight: '#ecfccb',
  },
  c: {
    value: .7,
    label: 'C',
    color: '#eab308',
    colorLight: '#fef9c3',
  },
  d: {
    value: .6,
    label: 'D',
    color: '#f97316',
    colorLight: '#ffedd5',
  },
  f: {
    value: 0,
    label: 'F',
    color: '#ef4444',
    colorLight: '#fee2e2',
  },
}

const getRiskLevel = (value) => {
  if (value >= riskLevels.a.value) return riskLevels.a;
  if (value >= riskLevels.b.value) return riskLevels.b;
  if (value >= riskLevels.c.value) return riskLevels.c;
  if (value >= riskLevels.d.value) return riskLevels.d;
  return riskLevels.f;
}

const dummyMetrics = [
  { title: 'Technical', score: 65, icon: Book, explanation: 'This is a feedback', suggestions: ['This is an actionable advice', 'This is an actionable advice', 'This is an actionable advice'] },
  { title: 'Potential', score: 95, icon: Lightbulb, explanation: 'This is a feedback', suggestions: ['This is an actionable advice', 'This is an actionable advice', 'This is an actionable advice'] },
  { title: 'Creativity', score: 82, icon: PenTool, explanation: 'This is a feedback', suggestions: ['This is an actionable advice', 'This is an actionable advice', 'This is an actionable advice'] },
  { title: 'Structure', score: 91, icon: Layout, explanation: 'This is a feedback', suggestions: ['This is an actionable advice'] },
  { title: 'Clarity', score: 92, icon: MessageSquare, explanation: 'This is a feedback', suggestions: ['This is an actionable advice'] },
  { title: 'Grammar', score: 50, icon: Check },
  { title: 'Grammar2', score: 50, icon: Check }
];

const Essay_Feedback = ({ isActive, responses, essayText }) => {
  const { isMobileAndBelow } = useBreakpoint();
  const [ expanded, setExpanded ] = useState(0);
  const scores = dummyMetrics;
  const avgScore = Math.round(scores.reduce((acc, curr) => acc + curr?.score, 0) / scores.length);
  const [ openEssayFeedbackModal, setOpenEssayFeedbackModal ] = useState(false);

  useEffect(() => {
    if (!isActive) {
      setExpanded(false);
    }
  }, [isActive]);

  if (!Array.isArray(responses) || responses.length === 0) {
    responses = dummyMetrics;
  }

  if (!isActive) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      { responses.map((item, index) => {
        const riskLevel = getRiskLevel(item?.score/100);
        return (
          <div style={{ padding: '1.5rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '0.5rem' }}>
                  <div style={{ width: '2rem', height: '2rem', backgroundColor: riskLevel.colorLight, borderRadius: '.5rem' }}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: riskLevel.color, fontSize: '1rem', fontWeight: '600' }}>
                      {Math.round(item?.score)}
                    </div>
                  </div>
                  <h3 style={{ fontSize: '1rem', fontWeight: 600, }}
                    >{item?.title}
                  </h3>
                </div>
                <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.5 }}>{item?.explanation || 'No feedback available.'}</p>
              </div>

              {Array.isArray(item?.suggestions) && item?.suggestions.length > 0 && (
                <div>
                  <h3 style={{ fontSize: '.9rem', fontWeight: 600, marginBottom: '0.5rem', color: 'var(--color-text1)' }}
                    >Suggestions
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  
                    { item?.suggestions.map((suggestion, i) => (
                      <div key={i} style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.5, display: 'flex', alignItems: 'flex-start', gap: '0.5rem', justifyContent: 'space-between' }}>
                        <ArrowRight size={16} style={{color: 'var(--color-text3)', marginTop: '0.2rem', minWidth: '16px'}} />
                        <span style={{ display: 'flex', alignItems: 'flex-start', gap: '0.5rem', flex: 1, marginRight: '1rem' }}>
                          {suggestion}
                        </span>
                        <button style={{ backgroundColor: 'var(--color-backgroundTertiary)', padding: '6px 8px', borderRadius: '4px', color: 'var(--color-text3)', whiteSpace: 'nowrap', fontSize: '0.9rem', fontWeight: '500', border: 'none', cursor: 'pointer' }}
                          onMouseEnter={(e) => e.target.style.opacity = '0.5'} 
                          onMouseLeave={(e) => e.target.style.opacity = '1'}
                          onClick={() => setOpenEssayFeedbackModal(suggestion)}
                        >
                          Show
                        </button>
                      </div>
                    ))}
                </div>
              </div>
              )}
            </div>
          </div>
        );
      })}

      <EssayFeedbackModal open={openEssayFeedbackModal} setOpen={setOpenEssayFeedbackModal} essayText={essayText} />
    </div>
  );
};

export default Essay_Feedback;



      {/* <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap', padding: '0.5rem 0' }}>
        {responses.map((item, index) => {
          const riskLevel = getRiskLevel(item?.score/100);
          const selected = expanded === index;

          return (
            <div 
              key={index}
              onClick={() => setExpanded(index)}
              style={{ 
                padding: '0.5rem 1rem',
                borderRadius: '2rem', 
                backgroundColor: selected ? riskLevel.color : riskLevel.colorLight,
                color: selected ? 'white' : riskLevel.color,
                cursor: 'pointer',
                transition: 'all 0.2s',
                fontSize: '0.9rem',
                fontWeight: '500'
              }}
              onMouseEnter={(e) => {
                if (!selected) {
                  e.target.style.backgroundColor = riskLevel.color;
                  e.target.style.color = 'white';
                }
              }}
              onMouseLeave={(e) => {
                if (!selected) {
                  e.target.style.backgroundColor = riskLevel.colorLight;
                  e.target.style.color = riskLevel.color;
                }
              }}
            >
              {item.title}
            </div>
          );
        })}
      </div> */}