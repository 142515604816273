'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Upload } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { Routes } from '../navigation/routes';
import Uploader_Button from './Uploader_Button';

const Uploader_File = ({ setOpen, setOpenResourceProcessingModal }) => {
  const { user, setRefreshRecentResources } = useContext(UserContext);
  const { isPhoneAndBelow } = useBreakpoint()
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false)

  const fileInputRef = useRef(null);
  
  const audioExtensions = ['.mp3', '.wav', '.ogg', '.m4a', '.aac'];

  const getFileExtension = (file) => {
    let extension = file.name.split('.').pop();
    let fullExtension = `.${extension}`
    return fullExtension
  }

  useEffect(() => {
    if ( file ) {
      checkFileSize(file)
    }
  }, [file])


  const checkFileSize = (file) => {
    if (file.size > 100 * 1024 * 1024) {
      setFile(null)
      alert('File is too large (max 100 MB). Let us know if you need more storage.');
    }
  }
 
  const handleClose = () => {
    setFile(null);
    setIsLoading(false)
    setOpen(false)
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.currentTarget.contains(e.relatedTarget)) return;
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const removeFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  };
  

  const uploadFile = async () => {

    setIsLoading(true)

    if (!file) {
      alert('Please select a file first!');
      setIsLoading(false)
      return;
    }

    let resourceObj = null

    try {

      const extension = getFileExtension(file)

      resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.file })
      await uploadResourceToS3({ resource_id: resourceObj._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.file })

      if ( audioExtensions.includes(extension) ) {
        await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user?.categories })
        handleClose();
        setOpenResourceProcessingModal(true);
      }

      else {
        await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories })
        setRefreshRecentResources(prev => !prev)
        navigate(`/app/resources/${resourceObj._id}/${Routes.SUMMARY}`) 
        handleClose();
      }

    } 
    catch (error) {
      try {
        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file.name}\nfile type: ${file.type}\nfile size: ${file.size}\nUpload method: ${RESOURCES_UPLOAD_METHODS.file}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }
      alert(`There was an error uploading your file. This typically only happens if the file is too large. Please try breaking the file into smaller parts. If this continues, please email us at ryan@schoolgoat.com and we'll get it sorted out!`);
      setFile(null)
    }
    finally {
      setIsLoading(false)
    }
  };


  const dropZoneStyle = {
    
  };

  const fileInfoStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%",  }}>
      <div style={{ ...dropZoneStyle, display: 'flex', flexDirection: 'column', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: '1rem', alignItems: 'center', justifyContent: 'center',border: `1px solid ${isDragging ? 'blue' : 'var(--color-separatorOpaque)'}`, boxShadow: 'var(--borderShadow)',backgroundColor: isDragging || file ? 'rgba(0, 0, 255, 0.1)' : 'transparent', transition: 'all 0.3s', height: "300px",


        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,

        // borderBottomLeftRadius: 0,
        // borderTopLeftRadius: 0, 
        // borderBottomRightRadius: '1rem',
        // borderTopRightRadius: '1rem',
        // position: 'relative',
      }}
        tabIndex="0"
        role="button"
        aria-label={file ? `Selected file: ${file.name}. Click to change file or drop a new file.` : "Drop a file here or click to select"}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onKeyPress={(e) => { if (e.key === 'Enter' || e.key === ' ') { fileInputRef.current.click() } }}
      >
          
        {file ? (
          <div style={fileInfoStyle}>
            <p>{file.name}</p>
            <IconButton onClick={(e) => { e.stopPropagation(); removeFile(); }}
              aria-label="Remove file"
            >
              <CloseIcon />
            </IconButton>
          </div>
              
        ) : (
          <>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

              <Upload style={{ width: '2rem', height: '2rem', color: 'var(--color-text1)', marginBottom: '.5rem' }} />

              <h4 style={{fontSize: '1.4rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5'}}
                >Drop here or <span onClick={() => fileInputRef.current.click()} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '600' }}>choose file</span>
              </h4>
              <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center', marginTop: '.5rem' }}
                // >Drag & drop or <span onClick={() => fileInputRef.current.click()} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: '600' }}>choose file</span>
                >Supports Word Doc, PPT, PDF, and Audio files
              </p>
              {/* <p style={{ fontSize: '.9rem', color: 'var(--color-text3)', lineHeight: '1.3', textAlign: 'center', marginTop: '.75rem' }}
                // >Word Doc • PPT • PDF • Audio
                >Supports Word Doc, PPT, PDF, and Audio files
              </p> */}
            </div>

            <input
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
              aria-label="Select file"
              accept=".pdf,.mp3,.wav,.ogg,.m4a,.aac,.docx,.doc,.pptx,.ppt"
            /> 
          </>
        )}

        {/* <Uploader_Button isActive={file} isLoading={isLoading} onClick={uploadFile} /> */}

      </div>

      <button style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', padding: '.75rem', borderRadius: '.5rem', fontSize: '1rem', fontWeight: '600', transition: 'all 300ms ease-in-out', position: 'relative', marginTop: '1.5rem', opacity: !file ? 0.1 : 1 }}
        disabled={!file || isLoading}
        onClick={uploadFile}
        >
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isLoading ? 0 : 1 }}>
            Continue
            <ArrowRight style={{ width: '1rem', height: '1rem', color: 'var(--color-textButton)', marginLeft: '1rem',   }} />
          </span>
          { isLoading && (
            <div style={{ display: 'flex', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
              <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
            </div>
          )}
      </button>

    </div>
  );
};

export default Uploader_File;










// const validateAndGetAudioDuration = (file) => {
//   return new Promise((resolve, reject) => {
//     const xhr = new XMLHttpRequest();
//     xhr.open('GET', URL.createObjectURL(file), true);
//     xhr.responseType = 'arraybuffer';
//     xhr.onload = function(e) {
//       if (this.status == 200) {
//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         audioContext.decodeAudioData(this.response, 
//           (buffer) => {
//             // setValidationProgress(100);
//             resolve({
//               duration: buffer.duration,
//               isValid: true
//             });
//           },
//           (err) => {
//             reject(new Error('Unable to decode audio data. The file may be corrupted.'));
//           }
//         );
//       }
//     };

//     xhr.onerror = () => {
//       reject(new Error('Error occurred while reading the file.'));
//     };

//     xhr.send();
//   });
// };
