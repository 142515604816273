import React, { useContext, useEffect, useState, } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS, PAYWALL_TYPES, COLOR_ARRAY, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { Mic, Zap, Upload, ArrowRight, } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import AudioRecorder from '../components/AudioRecorder';
import { useNavigate, useOutletContext } from 'react-router-dom';
import UploaderModal from '../modals/UploaderModal';
import ResourceProcessingModal from '../modals/ResourceProcessingModal';
import AudioRecorderModal from '../modals/AudioRecorderModal';
import { ContentCreate_Header, ContentCreate_Input } from '../components/ContentCreateComponents';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { Routes } from '../navigation/routes';
import { Calculator, GraduationCap, PenTool } from 'lucide-react';
import { EXAMPLE_NOTES } from '../misc/constants';

const NEW_USER_ID = 'newuserexample'
const TILE_GAP_DISTANCE = '1.5rem'

const tabs = {
  liveLecture: { title: "Live Lecture", icon: <Mic size={24} color="var(--color-primary)" />, description: "Get notes taken in class", descriptionMobile: "Get notes taken for you in class" },
  classMaterials: { title: "Class Materials", icon: <Upload size={24} color="var(--color-primary)" />, description: "Notes, files, ppts, etc.", descriptionMobile: "Convert your files to study tools" },
  // quickstart: { title: "Quickstart", icon: <Zap size={24} color="var(--color-primary)" />, description: "Start with a topic to learn", descriptionMobile: "Start with a topic to learn" },
}

const Tile = ({ item, handleTileClick }) => {
  const { isMobileAndBelow } = useBreakpoint()

  if ( !isMobileAndBelow ) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '1rem', borderRadius: '1.5rem', cursor: 'pointer', width: '280px', height: '180px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      onClick={async () => await handleTileClick(item)}
      >
        <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginBottom: '.5rem' }}>
          {React.cloneElement(item.icon, { size: 30, color: 'var(--color-primary)', strokeWidth: 1.5 })}
        </div>
        <div style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', textAlign: 'center' }}>
          {item.title}
        </div>
        <div style={{ fontSize: '1rem', color: 'var(--color-text4)', textAlign: 'center', lineHeight: '1.2', marginTop: '.5rem' }}>
          {item.description}
        </div>
      </div>
    )
  } 
  else {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '1.5rem', borderRadius: '1.5rem', cursor: 'pointer', width: '100%', maxWidth: '550px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', }}
      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      onClick={() => handleTileClick(item)}
      >
        <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px', marginRight: '1rem' }}>
          {React.cloneElement(item.icon, { size: 26, color: 'var(--color-primary)', strokeWidth: 1.5 })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)', }}>
            {item.title}
          </div>
          <div style={{ fontSize: '1rem', color: 'var(--color-text4)', lineHeight: '1.2', marginTop: ".5rem" }}>
            {item.descriptionMobile}
          </div>
        </div>
      </div>
    )
  }
}

const Tile_Example = ({ item }) => {
  const { isMobileAndBelow } = useBreakpoint()
  const navigate = useNavigate()

  const handleNoteClick = (noteId) => {
    navigate(`/app/resources/${noteId}/${Routes.SUMMARY}`)
  }

  if ( !isMobileAndBelow ) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1.5rem', cursor: 'pointer', borderRadius: '1.5rem', background: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)',  width: '200px' }}
        onClick={() => handleNoteClick(item._id)}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      >
        <img src={item.image} alt={item.title} style={{ width: '64px', height: '64px', borderRadius: '8px', objectFit: 'cover', }} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '.5rem',  }}>
          <h4 style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', margin: 0, textAlign: 'center' }}>{item.title}</h4>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
            <span style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>{item.type}</span>
          </div>
        </div>
      </div>
    )
  }
  else {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '.5rem', cursor: 'pointer', borderRadius: '1.5rem', background: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', width: '100%' }}
        onClick={() => handleNoteClick(item._id)}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
      >
        <img src={item.image} alt={item.title} style={{ width: '64px', height: '64px', borderRadius: '8px', objectFit: 'cover', marginRight: '1rem' }} />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '.5rem' }}>
          <h4 style={{ fontSize: '1rem', fontWeight: '600', color: 'var(--color-text1)', margin: 0 }}>{item.title}</h4>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
            <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>{item.category}</span>
            <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>•</span>
            <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>{item.type}</span>
          </div>
        </div>
        <ArrowRight size={20} color="var(--color-text4)" strokeWidth={1.5} style={{ marginLeft: 'auto', marginRight: '1rem' }} />
      </div>
    )
  }
}


const HomeScreen = ({ }) => {
  const { user, checkUserPermission, setRefreshRecentResources } = useContext(UserContext)
  const { data, dataCount } = useOutletContext()
  const navigate = useNavigate()
  const { isMobileAndBelow } = useBreakpoint()
  
  // TOOLS
  const [ openAudioRecorderModal, setOpenAudioRecorderModal ] = useState(false)
  const [ triggerRecording, setTriggerRecording ] = useState(false);
  const [ openUploaderModal, setOpenUploaderModal ] = useState(false)
  const [ openResourceProcessingModal, setOpenResourceProcessingModal ] = useState(false);

  const handleTileClick = async (item) => {
    if (item.title == tabs.liveLecture.title) {
      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.recording)
      if ( hasPermission ) {
        setOpenAudioRecorderModal(true)
      }
    } 
    else if (item.title == tabs.classMaterials.title) {
      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.notes)
      if ( hasPermission ) {
        setOpenUploaderModal(true)
      }
    } 
  }

  const [ chatInput, setChatInput ] = useState('')


  const submitQuickstart = async () => {

    let resourceObj = null
    let file = chatInput

    try {

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.notes)
      if ( hasPermission ) {
        resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text, otherData: { quickstart: true } })
        await uploadResourceToS3({ resource_id: resourceObj._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
        await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories  })
        setChatInput('')
        setRefreshRecentResources(prev => !prev)
        navigate(`/app/resources/${resourceObj._id}/${Routes.CHAT}`)
      }
      else {
        setChatInput('')
      }
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      try {
        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file.name}\nfile type: ${file.type}\nfile size: ${file.size}\nUpload method: ${RESOURCES_UPLOAD_METHODS.text}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }

      setChatInput('')
      alert(error.message);
    }
  };

  return (

    <div id="wrapper">
      <div id="main">
        <div className="inner">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '700px', alignSelf: 'center', height: '100%', width: '100%', margin: "auto", paddingBottom: "4rem",
            ...isMobileAndBelow ? { marginTop: '2rem' } : {}
          }}>

            <ContentCreate_Header
              // title="Where would you like to start?"
              title="Where should we **start?**"
              subtitle="Choose how you want to create your notes"
            />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: TILE_GAP_DISTANCE, justifyContent: 'center', flexWrap: 'wrap', width: '100%', marginBottom: TILE_GAP_DISTANCE }}>
                {Object.values(tabs).slice(0, 2).map((item, index) => <Tile key={index} item={item} handleTileClick={handleTileClick} /> )}
              </div>

              <ContentCreate_Input
                placeholder="Or quickstart with a topic to learn..."
                value={chatInput}
                setValue={setChatInput}
                handleSubmit={submitQuickstart}
              >
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%", paddingLeft: ".5rem", marginTop: ".25rem" }}>
                  <div style={{ fontSize: "0.9rem", color: "var(--color-text4)" }}>
                    e.g. "The process of photosynthesis"
                  </div>
                </div>

              </ContentCreate_Input>


              { dataCount == 0 && 
                <div style={{ width: '100%', marginTop: '6rem' }}>
                  <div style={{ fontSize: '0.9rem', color: 'var(--color-text4)', textAlign: 'center', textTransform: 'uppercase' }}>
                    Explore examples
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '1rem', justifyContent: 'center', marginTop: '1.5rem' }}>
                    { EXAMPLE_NOTES.map(note => <Tile_Example key={note._id} item={note} />)}
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
      </div>

      <AudioRecorderModal 
        open={openAudioRecorderModal} 
        setOpen={setOpenAudioRecorderModal} 
        setTriggerRecording={setTriggerRecording}
      />
      
      <AudioRecorder 
        triggerRecording={triggerRecording} 
        setTriggerRecording={setTriggerRecording} 
        setOpenResourceProcessingModal={setOpenResourceProcessingModal} 
      />

      <UploaderModal 
        open={openUploaderModal} 
        setOpen={setOpenUploaderModal} 
        setOpenResourceProcessingModal={setOpenResourceProcessingModal} 
      />

      <ResourceProcessingModal 
        open={openResourceProcessingModal} 
        setOpen={setOpenResourceProcessingModal} 
      />


    </div>
  );  
}


export default HomeScreen;






  {/* <div style={{ marginTop: '2rem', width: '100%', marginTop: '6rem' }}>
    <div style={{ fontSize: '0.9rem', color: 'var(--color-text4)', marginBottom: '1rem', textAlign: 'center', textTransform: 'uppercase' }}>
      Other tools
    </div>

    <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', justifyContent: 'center', alignItems: 'center' }}>
      {[
        { icon: <Calculator size={24} />, label: 'Solver', route: Routes.SOLVE },
        { icon: <GraduationCap size={24} />, label: 'Tutor', route: Routes.TEACH },
        { icon: <PenTool size={24} />, label: 'Writing', route: Routes.ESSAY_EVALUATION }
      ].map((tool, index) => (
        <div key={index} 
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.75rem', cursor: 'pointer' }}
              onClick={() => navigate(`/app/${tool.route}`)}>
          <div style={{ 
            width: '64px', 
            height: '64px', 
            borderRadius: '16px', 
            backgroundColor: 'var(--color-backgroundSecondary)',
            border: '1px solid var(--color-separatorOpaque)',
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            // boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            border: '1px solid var(--color-separatorOpaque)',
          }}>
            {React.cloneElement(tool.icon, { strokeWidth: 1.5, style: { color: 'var(--color-text3)' }})}
          </div>
          <div style={{ 
            fontSize: '0.9rem', 
            color: 'var(--color-text2)',
            fontWeight: '500'
          }}>
            {tool.label}
          </div>
        </div>
      ))}


    </div>
  </div> */}


// const styles = {
//   container: { maxWidth: '1024px', margin: '0 auto', padding: '80px 24px 0' },
//   heading: { fontSize: '2.25rem', fontWeight: '600', textAlign: 'center', marginBottom: '48px' },
//   actionsContainer: { marginBottom: '48px' },
//   dropArea: { padding: '48px', backgroundColor: 'white', border: '2px dashed #E5E7EB', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px', marginBottom: '24px', cursor: 'pointer' },
//   iconGrid: { display: 'flex', gap: '32px', marginBottom: '8px' },
//   iconBox: { width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F3F4F6', borderRadius: '8px' },
//   uploadText: { fontWeight: '500', fontSize: '16px', color: '#111827', textAlign: 'center' },
//   supportingText: { fontSize: '14px', color: '#6B7280', textAlign: 'center' },
//   divider: { width: '100%', display: 'flex', alignItems: 'center', gap: '16px', margin: '8px 0' },
//   dividerLine: { flex: 1, height: '1px', backgroundColor: '#E5E7EB' },
//   dividerText: { fontSize: '14px', color: '#6B7280' },
//   actionButtons: { display: 'flex', gap: '16px' },
//   actionButton: { flex: 1, padding: '16px', backgroundColor: 'white', border: '1px solid #E5E7EB', borderRadius: '12px', cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '12px' },
//   actionIcon: { width: '40px', height: '40px', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' },
// };


        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', maxWidth: '1000px', }}>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer' }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Mic size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Live Lecture</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Take AI-enhanced notes during class</div>
          </div>

          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px dashed var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer' }}
          // onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
          // onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Upload size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Upload Materials</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Learn from PDFs, videos, and more</div>
          </div>

          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '24px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px dashed var(--color-separatorOpaque)', borderRadius: '12px', cursor: 'pointer', marginTop: "1rem", height: "20rem", flex: 1 }}>
            <div style={{ width: '48px', height: '48px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#EFF6FF', borderRadius: '8px', marginBottom: '16px' }}>
              <Upload size={24} color="#2563EB" />
            </div>
            <div style={{ fontSize: '16px', fontWeight: '500', color: '#111827', marginBottom: '8px', textAlign: 'center' }}>Upload Materials</div>
            <div style={{ fontSize: '14px', color: '#6B7280', textAlign: 'center' }}>Learn from PDFs, videos, and more</div>
          </div> */}

{/* <div style={styles.actionsContainer}>
  <div style={styles.dropArea}>
    <div style={styles.iconGrid}>
      <div style={styles.iconBox}>
        <Upload size={24} color="#6B7280" />
      </div>
    </div>
    
    <div>
      <div style={styles.uploadText}>Drop your files here or click to upload</div>
      <div style={styles.supportingText}>Support for PDFs, video links, and more</div>
    </div>
  </div>

  <div style={styles.actionButtons}>
    <button style={styles.actionButton}>
      <div style={{...styles.actionIcon, backgroundColor: '#EFF6FF'}}>
        <Mic size={20} color="#2563EB" />
      </div>
      <div>
        <div style={styles.uploadText}>Live Lecture</div>
        <div style={styles.supportingText}>Take AI-enhanced notes</div>
      </div>
    </button>

    <button style={styles.actionButton}>
      <div style={{...styles.actionIcon, backgroundColor: '#F3F4F6'}}>
        <LightbulbIcon size={20} color="#6B7280" />
      </div>
      <div>
        <div style={styles.uploadText}>Quick Start</div>
        <div style={styles.supportingText}>Type or paste anything</div>
      </div>
    </button>
  </div>
</div> */}






          {/* {data.length > 0 && (
            <div style={{ marginTop: "3.5rem"  }}>
          <SectionHeader title="Recent Materials" />
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '16px' }}>
                {data.map((file) => (
                  <button key={file.name} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '16px', backgroundColor: 'white', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.75rem', cursor: 'pointer', textAlign: 'left', width: '100%' }}
                  
                  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
                  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
                  onClick={() => navigate(`../${Routes.RESOURCES}/${file._id}/${Routes.SUMMARY}`)}
                  >
                                          <FileText size={20} color="var(--color-text4)" strokeWidth={1.5} style={{ flexShrink: 0, width: '30px' }} />

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px', marginLeft: '8px', width: '90%' }}>
                      <div style={{ fontSize: '14px', fontWeight: '500', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}
                        >{file.title}
                      </div>
                      <div style={{ fontSize: '.8rem', color: 'var(--color-text4)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}
                      >{file.category} · {new Date(file.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </div>
                    </div>

                  </button>
                ))}
              </div>
            </div>
          )} */}


          {/* <div style={{ marginTop: "3.5rem"  }}>
          <SectionHeader title="Other Tools" />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem', marginBottom: '5rem', maxWidth: '1000px', }}>
              {Object.values(altTabs).map((tool, index) => (
                <Tile2 key={tool.title} item={tool} index={index} handleTabClick={() => navigate(tool.route)} />
              ))}
            </div>
          </div> */}








          {/* <h1 style={{ fontSize: '2.25rem', fontWeight: '600', textAlign: 'center', marginBottom: '48px' }}>
            What do you want to learn today?
          </h1>

          <div style={{ marginBottom: '48px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px', padding: '16px', backgroundColor: 'white', border: '1px solid #E5E7EB', borderRadius: '12px' }}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <button style={{ padding: '8px', border: 'none', background: 'none', borderRadius: '8px', cursor: 'pointer' }}>
                  <Paperclip size={20} color="#6B7280" />
                </button>
                <button style={{ padding: '8px', border: 'none', background: 'none', borderRadius: '8px', cursor: 'pointer' }}>
                  <Mic size={20} color="#6B7280" />
                </button>
              </div>
              <input 
                style={{ flex: 1, border: 'none', fontSize: '16px', outline: 'none', color: '#4B5563' }}
                placeholder="Upload PDF, paste YouTube video, or record a lecture"
              />
              <button style={{ backgroundColor: 'black', color: 'white', padding: '8px 16px', border: 'none', borderRadius: '8px', cursor: 'pointer', fontSize: '14px' }}>
                Start
              </button>
            </div>
          </div> */}