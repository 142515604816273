import React, { useState, useRef, useEffect, useContext } from 'react';
import { ArrowUpIcon, Upload, FileText, Paperclip, X } from 'lucide-react';
import SelectResourceModal from '../modals/SelectResourceModal';
import { dbRefs, API } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { format } from 'date-fns';
import { ArrowRight } from 'lucide-react';
import { ContentCreate_Container, ContentCreate_DemoButton, ContentCreate_Header, ContentCreate_Input, ContentCreate_InputSubButton_ModelLevel } from './ContentCreateComponents';


const ContentCreate = ({ onSubmit, isActive, header, placeholder, setIsActive, handleDemo }) => {
  const { user } = useContext(UserContext)
  const [ input, setInput ] = useState('')
  const [ error, setError ] = useState(false)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      await onSubmit({ textInput: input })
    }
    catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(true)
    }
  }

  if ( !isActive ) return null

  return (

    <ContentCreate_Container>

      <ContentCreate_Header
        title={'Explain **this** to me'}
        subtitle={'Get step by step explanations for any topic'}
      />

      <ContentCreate_Input
        placeholder="e.g. How does quantum mechanics work?"
        value={input}
        setValue={setInput}
        handleSubmit={handleSubmit}
      >
        
        { !user?.isMember && (
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%", marginTop: "1rem" }}>
            <ContentCreate_InputSubButton_ModelLevel />
          </div>
        )}
      </ContentCreate_Input>

      <ContentCreate_DemoButton onClick={handleDemo} />

      { error && 
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <p style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}>An error occurred. Please try again.</p>
        </div>
      }

    </ContentCreate_Container>

  )
}

export default ContentCreate



// useEffect(() => {
//   getRecentFiles()
// }, [])

// const getRecentFiles = async () => {
//   try {
//     let result = await axios.get(API + '/generalAggregation', { params: {
//       matchObj: JSON.stringify({ user_id: user?._id, }),
//       sortObj: JSON.stringify({ date: -1 }),
//       currentPage: 0,
//       pageSize: 5,
//       dbRef: dbRefs.resources
//     }})
//     const results = result.data
//     setRecentFiles(results)
//   }
//   catch (error) {
//     console.error('Error getting recent files:', error);
//   }
// }

// const handleFileUpload = async (event) => {
//   const file = event.target.files[0];
  
//   if (file) {
//     try {
//       await onSubmit({ file: file })
//     } 
//     catch (error) {
//       console.error('Error uploading PDF:', error);
//     } 
//   }
// };


// <input
// type="file"
// ref={fileInputRef}
// onChange={handleFileUpload}
// accept=".pdf"
// style={{ display: 'none' }}
// />


// const Tile = ({ item, index, onSelect, }) => {
//   return (
//     <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem', borderBottom: '1px solid var(--color-separatorOpaque)', cursor: 'pointer', textAlign: "left" }}
//       onClick={() => onSelect(item)}
//       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//     >
//       <div style={{ flex: 1, color: 'var(--color-text1)', minWidth: 0, marginRight: '1rem' }}>
//         <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
//           <span style={{ color: 'var(--color-text1)' }}>
//             {item.title || 'Untitled'}
//           </span>
          
//         </div>
//       </div>
//       <span style={{ color: 'var(--color-text3)', fontSize: '.9rem', marginLeft: '4px' }}>
//             {format(new Date(item.date), 'MMM-dd')}
//           </span>
//       {/* <div style={{ display: "flex", flexShrink: 0, alignItems: 'center', justifyContent: 'center' }}>
//         <ArrowRight size={15} style={{ color: 'var(--color-primary)' }} />
//       </div> */}
//     </div>
//   )
// }




        {/* <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          { ["From description", "From your files", "From new file"].map((item, index) => {
            return (
            <button key={index} style={{ display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: 'flex-start', padding: '1rem', width: "250px", cursor: 'pointer', fontSize: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}>
                <p style={{ fontWeight: '600', color: 'var(--color-text1)' }}>{item}</p>

                { index == 0 && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
                    <textarea 
                      style={{ width: '100%', minHeight: '100px', padding: '.75rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text1)', fontSize: '.9rem', resize: 'none' }}
                      placeholder="Describe what you want to learn about..."
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                    />
                  </div>
                )}

                { index == 1 && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', width: '100%', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', padding: '.75rem' }}>
                    <p style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>Select file</p>
                  </div>
                )}

                { index == 2 && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '1rem', width: '100%', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', padding: '.75rem' }}>
                    <p style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>Upload a new file</p>
                  </div>
                )}
              </button>
            )
          })}
        </div>

        <button style={{ marginTop: '1rem', padding: '1rem 1.5rem',  backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', borderRadius: '.5rem', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '600' }}
            type="submit"
            aria-label="Submit search"
            onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
            onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          >
            Create quiz
          </button> */}



        //   <SelectResourceModal
        //   open={selectResourceModalOpen} 
        //   setOpen={setSelectResourceModalOpen} 
        //   onSelect={async(item) => await onSubmit({ resourceObj: item })}
        // />


                    {/* <button style={{ padding: '.75rem 1rem', cursor: 'pointer', fontSize: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '100px', color: 'var(--color-text3)', width: '140px', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}
            onClick={() => setSelectResourceModalOpen(true)}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            Show all files
          </button>

          <button style={{ padding: '.75rem 1rem', cursor: 'pointer', fontSize: '1rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '100px', color: 'var(--color-text3)', width: '140px', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}
            onClick={() => fileInputRef.current.click()}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
          >
            Upload file
          </button> */}
