import React, { useEffect, useState } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { useNavigate,  } from 'react-router-dom';
import {  AlertCircle, CheckCircle } from 'lucide-react';
import SchoolGoatBg from '../assets/SchoolGoatBg.png';

const durationMs = 30000

export const Resource_Loading = ({ }) => {
  const [progress, setProgress] = useState(0);
  const [statusIndex, setStatusIndex] = useState(0);
  const [isStatusVisible, setIsStatusVisible] = useState(true);

  const statusMessages = [
    "Warming neural networks",
    "Reading materials",
    "Extracting concepts",
    "Running algorithm",
    "Organizing notes",
    "Saving your notes",
    "Uploading now",
  ];

  useEffect(() => {
    let progressInterval; let statusInterval; let statusTimeout;

    progressInterval = setInterval(() => {
      setProgress(prev => {
        const increment = 90 / (durationMs / 100);
        const newProgress = prev + increment;
        return newProgress >= 90 ? (clearInterval(progressInterval), 90) : newProgress;
      });
    }, 100);

    statusInterval = setInterval(() => {
      setIsStatusVisible(false);
      statusTimeout = setTimeout(() => {
        setStatusIndex(prev => prev < statusMessages.length - 1 ? prev + 1 : prev);
        setIsStatusVisible(true);
      }, 500);
    }, durationMs / (statusMessages.length + 1));

    return () => {
      clearInterval(progressInterval);
      clearInterval(statusInterval);
      if (statusTimeout) clearTimeout(statusTimeout);
    };
  }, [durationMs]);

  const remainingSeconds = Math.max(0, (durationMs - (progress / 90 * durationMs)) / 1000);

  return (
    <div id="wrapper">
      <div id="main" style={{ maxWidth: "1000px", margin: "0 auto", justifyContent: "center", alignItems: "center", padding: '2rem' }}>
        <div className="inner" style={{ justifyContent: "center", gap: "1.5rem", paddingBottom: "2rem" }}>

          <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
            <img src={SchoolGoatBg} alt="School Goat" style={{ width: "6rem", height: "auto", borderRadius: ".5rem" }} />
            <h2 style={{ fontSize: "1.5rem", fontWeight: 600, textAlign: "center", margin: 0, letterSpacing: "-0.05rem" }}
              >School Goat is generating your notes
            </h2>
            <div style={{ textAlign: "center" }}>
              <span style={{ display: "inline-block", backgroundColor: "var(--color-backgroundTertiary)", borderRadius: ".25rem", padding: ".4rem .8rem", fontSize: "1rem", transition: "all 500ms ease-in-out", opacity: isStatusVisible ? 1 : 0, transform: `translateY(${isStatusVisible ? 0 : 4}px)` }}>
                {statusMessages[statusIndex]}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div style={{ position: "relative", height: "2rem", backgroundColor: "var(--color-backgroundTertiary)", borderRadius: "9999px", overflow: "hidden" }}>
              <div style={{ position: "absolute", left: 0, top: 0, height: "100%", width: `${progress}%`, backgroundColor: "var(--color-text1)", transition: "width 100ms linear" }} />
            </div>
            
            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "14px", color: "#6b7280" }}>
              <span>{Math.round(progress)}%</span>
              <span>{remainingSeconds.toFixed(1)}s</span>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export const Resource_UploadError = ({ resource_id }) => {
  const navigate = useNavigate()


  const onClick = async () => {
    try {
      let result = await axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resource_id }, dbRef: dbRefs.resources } });
      console.log(result)
      navigate('../..')
    }
    catch (error) {
      alert('Error deleting resource. Please try again.')
    }
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', paddingBottom: '2rem'}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', maxWidth: "400px", gap: '1rem'}}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', backgroundColor: 'var(--color-backgroundSecondary)', }}>
          <AlertCircle size={18} style={{ color: 'var(--color-text2)' }} />
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', fontWeight: "600", marginTop: '1rem' }}
            >Error uploading materials
          </div>
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', lineHeight: '1.3', marginTop: '.5rem' }}
            >An error occurred while uploading your materials. Return to the previous screen and try re-uploading them. Contact support if the issue persists.
          </div>
          
        </div>
        <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '1rem', fontWeight: '500', padding: "1rem", backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: 'var(--borderShadow)', width: '100%' }} 
          onClick={onClick}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          >Return to Home
        </button>
      </div>
    </div>
  )
}

export const Resource_Error = ({ onClick = () => {} }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', paddingBottom: '2rem'}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', maxWidth: "400px", gap: '1rem'}}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', backgroundColor: 'var(--color-backgroundSecondary)', }}>
          <AlertCircle size={18} style={{ color: 'var(--color-text2)' }} />
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', fontWeight: "600", marginTop: '1rem' }}
            >Error generating notes
          </div>
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', lineHeight: '1.3', marginTop: '.5rem' }}
            >An error occurred while processing your notes. Click below to re-generate them or notify support if it persists.
          </div>
          
        </div>
        <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '1rem', fontWeight: '500', padding: "1rem", backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: 'var(--borderShadow)', width: '100%' }} 
          onClick={onClick}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          >Generate Notes
        </button>
        {/* <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '1rem', fontWeight: '500', padding: "1rem", backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: 'var(--borderShadow)', width: '100%' }} 
          onClick={onClick}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
        >
          Notify Support
        </button> */}

      </div>
    </div>
  )
}

export const Resource_Submitted = ({ }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', paddingBottom: '2rem'}}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', maxWidth: "400px", gap: '1rem'}}>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', backgroundColor: 'var(--color-backgroundSecondary)', }}>
          <CheckCircle size={18} style={{ color: 'var(--color-text2)' }} />
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', fontWeight: "600", marginTop: '1rem' }}
            >Notes in progress
          </div>
          <div style={{ fontSize: '1rem', color: 'var(--color-text2)', lineHeight: '1.3', marginTop: '.5rem' }}
            >Your materials have been submitted and are being processed. <u>We will email you when they are ready.</u> Contact support if this takes longer than expected.
          </div>
          
        </div>
        {/* <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '1rem', fontWeight: '500', padding: "1rem", backgroundColor: 'var(--color-backgroundSecondary)', color: 'var(--color-text3)', border: '1px solid var(--color-separatorOpaque)', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: 'var(--borderShadow)', width: '100%' }} 
          onClick={onClick}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
        >
          Notify Support
        </button> */}

      </div>
    </div>
  )
}

