import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../contexts/UserProvider';
import Essay_Score from '../components/Essay_Score';
import Essay_Input from '../components/Essay_Input';
import Subheader from '../components/Subheader';
import Essay_Feedback from '../components/Essay_Feedback';
import { useBreakpoint } from '../misc/useBreakpoint';

const EssayEvaluationScreen = () => {
  const { user, } = useContext(UserContext)
  const { isTabletAndBelow } = useBreakpoint();
  const tabs = { input: "Input", score: "Score", feedback: "Feedback" }
  const [ activeTab, setActiveTab ] = useState(tabs.input);
  const [ responses, setResponses ] = useState([]);
  const [ essayText, setEssayText ] = useState('');
  
  return (
    <div id="wrapper">          
      <div id="main" style={{ backgroundColor: "var(--color-backgroundSecondary)" }}>
        <div className="inner" >
          { responses && responses.length > 0 && (
            <Subheader activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
          )}

          <Essay_Input 
            isActive={activeTab === tabs.input} 
            responses={responses} 
            setResponses={setResponses} 
            tabs={tabs}
            setActiveTab={setActiveTab}
            setEssayText={setEssayText}
            essayText={essayText}
          />

          <Essay_Score 
            isActive={activeTab === tabs.score} 
            responses={responses} 
            tabs={tabs}
            setActiveTab={setActiveTab}
          />

          <Essay_Feedback
            isActive={activeTab === tabs.feedback} 
            responses={responses} 
            tabs={tabs}
            setActiveTab={setActiveTab}
            essayText={essayText}
          />
          
        </div>
      </div>
    </div>
  );  
}


export default EssayEvaluationScreen;




