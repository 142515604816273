import React, { useEffect, useState, useRef } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import Resource_Flashcards from '../components/Resource_Flashcards';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Resource_Loader } from '../components/Resource_Loader';
import Subheader from '../components/Subheader';
import Resource_Flashcards_Edit from '../components/Resource_Flashcards_Edit';
import { getResourceTextUrl } from '../misc/utils';


const tabs = { active: "Cards", edit: "Edit" }

const ResourceFlashcardsScreen = ({ }) => {
  const { data, setData, setRefreshData } = useOutletContext();
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(!data?.flashcards?.content || data?.flashcards?.content?.length === 0);
  const [ activeTab, setActiveTab ] = useState(tabs.active);
  const lastLoadedIdRef = useRef();

  useEffect(() => {
    const needsDataLoad = (
      data?._id && 
      data?._id !== lastLoadedIdRef.current && 
      ( !data?.flashcards?.content || data?.flashcards?.content?.length === 0 )
    );

    if (needsDataLoad) {
      generateContent();
      lastLoadedIdRef.current = data?._id;
    }
  }, [data?._id]); 

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      let result = await axios.post(`${API}/generateFlashcards`, { resource_id: data._id, content_url });
      let content = result.data.content;
      setData((prev) => ({ ...prev, flashcards: { content } }));
      setRefreshData((prev) => !prev);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleSave = async (flashcards) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: data._id },
        updateObj: { $set: { 'flashcards.content': flashcards } },
        dbRef: dbRefs.resources,
      })
      setRefreshData((prev) => !prev);
      setActiveTab(tabs.active);
    }
    catch (error) {
      console.error('Error saving flashcards:', error);
    }
  }


  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner" style={{ maxWidth: '1000px', margin: '0 auto' }}>

          <Resource_Loader isActive={loading} text="Generating flashcards" subText="This may take ~10 seconds" />

          { !loading && (
            <>

              { !data?.previewMode && (
                <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
              )}
              
              { activeTab === tabs.active && (
                <Resource_Flashcards isActive={!loading} data={data} setRefreshData={setRefreshData} />
              )}

              { activeTab === tabs.edit && (
                <Resource_Flashcards_Edit data={data} handleSave={handleSave} />
              )}

            </>
          )}


        </div>
      </main>
    </div>
  );  
};


export default ResourceFlashcardsScreen;

