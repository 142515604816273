'use client';
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { ArrowUpIcon, Camera, ChevronDown, ChevronRight, Image, Paperclip } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { ContentCreate_Container, ContentCreate_DemoButton, ContentCreate_Header, ContentCreate_Input, ContentCreate_InputSubButton, ContentCreate_InputSubButton_ModelLevel } from './ContentCreateComponents';

const operatingSystemTypes = { macOS: { _id: 'macOS', shortcut: `⌘ + Shift + 4` }, windows: { _id: 'windows', shortcut: `Win + Shift + 4` }, linux: { _id: 'linux', shortcut: `Shift + Prt Sc` }, unknown: { _id: 'unknown', shortcut: '' } }


const SolveInitialComponent = ({ handleMessage, isActive, chatInput, setChatInput, handleDemo, handleImage }) => {
  const { user, } = useContext(UserContext);
  const { isPhoneAndBelow, isMobileAndBelow } = useBreakpoint();  
  const fileInputMobileRef = useRef(null);

  const platform = navigator.platform.toLowerCase();
  const operatingSystemType = platform.includes('mac') ? operatingSystemTypes.macOS 
    : platform.includes('win') ? operatingSystemTypes.windows
    : platform.includes('linux') ? operatingSystemTypes.linux 
    : operatingSystemTypes.unknown;


  if ( isActive ) return (
    <div id="wrapper">
      <div id="main">
        <div className="inner">
          <ContentCreate_Container>

          <ContentCreate_Header
            title="Solve this **problem**"
            subtitle={operatingSystemType?.shortcut ? `Screenshot the problem and drop it here (${operatingSystemType?.shortcut})` : 'Take a photo of the problem'}
          />

          <ContentCreate_Input
            // placeholder="e.g. What's $1,000 at 6% interest after 5 years?"
            // placeholder="e.g. What is inelastic demand?"
            placeholder="e.g. Solve the equation 3x² + 5x - 2 = 0"
            value={chatInput}
            setValue={setChatInput}
            handleSubmit={handleMessage}
          >
          <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%", marginTop: "1rem" }}>
            
            <>
              <ContentCreate_InputSubButton
                label={isPhoneAndBelow ? 'Take photo' : 'Add image'}
                onClick={() => fileInputMobileRef.current.click()}
              >
                {isPhoneAndBelow ? <Camera size={16} style={{ color: 'var(--color-text3)' }} strokeWidth={1.35} /> : <Image size={16} style={{ color: 'var(--color-text3)' }} strokeWidth={1.35} />}
              </ContentCreate_InputSubButton>
              <input
                type="file"
                ref={fileInputMobileRef}
                onChange={(e) => handleImage(e.target.files[0])}
                accept="image/*"
                capture="environment"
                style={{display: 'none'}}
              />
            </>
              <ContentCreate_InputSubButton_ModelLevel />
          </div>

          </ContentCreate_Input>

          <ContentCreate_DemoButton onClick={handleDemo} />

          </ContentCreate_Container>
        </div>
      </div>
    </div>
  );
  else return null;
};

export default SolveInitialComponent;


{/* <div id="main">
<div className="inner" style={{paddingBottom: "4rem"}}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '700px', alignSelf: 'center', height: '100%', width: '100%', margin: "auto",  }}>

<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '48px' }}>
<h1 style={{ fontSize: '3rem', fontWeight: '600', textAlign: 'center', }}>Help me solve <span style={{ color: 'var(--color-primary)' }}>[ this ]</span></h1>
<h2 style={{ fontSize: '1.2rem', fontWeight: '400', textAlign: 'center', color: 'var(--color-text3)', marginTop: '1.5rem' }}>Input a question and see how to solve it</h2>
</div>


<div style={{ display: "flex", flexDirection: "column", width: '100%', gap: "0.75rem" }}>
<div style={{ display: "flex", flexDirection: "column", width: '100%', background: 'var(--color-background)', borderRadius: '1.5rem', padding: "1rem" }}>
<div style={{ display: "flex", width: '100%', alignItems: 'center' }}>
<input style={{ width: '100%', border: 'none', background: 'transparent', fontSize: '16px', outline: 'none', }}
placeholder="e.g. What is inelastic demand?"
value={chatInput}
onChange={(e) => setChatInput(e.target.value)}
onKeyDown={handleKeyPress}
aria-label="Search input"
/>
<button style={{ height: '40px', width: '40px', backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.75rem', cursor: chatInput !== '' ? 'pointer' : 'not-allowed', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', opacity: chatInput !== '' ? 1 : 0.1}}
type="submit"
aria-label="Submit search"
disabled={chatInput === ''}
onClick={handleMessage}
onMouseEnter={(e) => chatInput !== '' && (e.currentTarget.style.opacity = 0.7)}
onMouseLeave={(e) => chatInput !== '' && (e.currentTarget.style.opacity = 1)}
>
<ArrowUpIcon size={24} />
</button>
</div>

</div>
</div>



</div>


</div>
</div> */}


    {/* <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem', marginTop: '.75rem' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem', border: 'none',  }}
      >
        <Paperclip size={16} style={{ color: 'var(--color-text3)' }} />
      </div>
      <span style={{ fontSize: '.9rem', color: 'var(--color-text4)' }}>
        Pro tip: You can screenshot the problem and drop here ({operatingSystemType?.shortcut})
      </span>
    </div> */}


      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', maxWidth: '700px', alignSelf: 'center', height: '100%', width: '100%', margin: "auto", padding: "1rem", paddingBottom: isPhoneAndBelow ? "0rem" : "0rem", }}>

          { isPhoneAndBelow && (
            <p style={{ fontSize: '1.1rem', color: 'var(--color-text3)', lineHeight: '1.4', textAlign: 'center', width: "80%" }}
              >Take a photo of the problem or type it in and see how to solve it!
            </p>
          )}

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1rem', marginTop: '1.5rem', width: "100%",  }}>
            <div  style={{ display: "flex", marginBottom: '20px', width: '100%', background: 'var(--color-backgroundSecondary)', borderRadius: '.5rem', padding: ".5rem", alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}>
              <input style={{ width: '100%', border: 'none', background: 'transparent', fontSize: '16px', outline: 'none', padding: "0 .4rem", }}
                placeholder="e.g. What is inelastic demand?"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyDown={handleKeyPress}
                aria-label="Search input"
              />
              <button style={{ height: '40px', padding: '0rem 1rem', backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '.5rem', cursor: chatInput !== '' ? 'pointer' : 'not-allowed', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem',  }}
                type="submit"
                aria-label="Submit search"
                disabled={chatInput === ''}
                onClick={handleMessage}
              >
                Solve
              </button>
            </div>
          </div>

          { !isPhoneAndBelow ? (
            <>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '.5rem', border: '1px solid var(--color-separatorOpaque)', padding: '1rem', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', height: "200px", }}>
                <Camera size={36} style={{color: 'var(--color-text1)', }} strokeWidth={1.35} />
                <h4 style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', marginTop: '.5rem' }}
                  >Screenshot & Drop Here
                </h4>
                <span style={{ fontSize: '.9rem', color: 'var(--color-text4)', marginTop: '.25rem', fontWeight: '500' }}
                  >Pro tip: Use {operatingSystemType?.shortcut} to screenshot
                </span>
              </div>

              <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px', backgroundColor: 'var(--color-backgroundTertiary)', color: 'var(--color-text1)', padding: '8px 12px', fontSize: '0.95rem', borderRadius: '.25rem', border: 'none', cursor: 'pointer', alignSelf: 'center', marginTop: '1rem' }}
                onClick={handleDemo}
                >Watch demo
                <ChevronRight size={18} style={{ marginRight: "-2px" }} />
              </button>
            </>

          ) : (

            <>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", gap: "0.5rem", marginTop: "1rem" }}>
                <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px', backgroundColor: 'var(--color-backgroundTertiary)', color: 'var(--color-text1)', padding: '8px 12px', fontSize: '0.95rem', borderRadius: '.25rem', border: 'none', cursor: 'pointer', alignSelf: 'flex-start' }}
                  onClick={() => fileInputMobileRef.current.click()}
                  >Take photo
                  <ChevronRight size={18} style={{ marginRight: "-2px" }} />
                </button>
                <input
                  type="file"
                  ref={fileInputMobileRef}
                  onChange={(e) => handleImage(e.target.files[0])}
                  accept="image/*"
                  capture="environment"
                  style={{display: 'none'}}
                />
                <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '2px', backgroundColor: 'var(--color-backgroundTertiary)', color: 'var(--color-text1)', padding: '8px 12px', fontSize: '0.95rem', borderRadius: '.25rem', border: 'none', cursor: 'pointer', alignSelf: 'flex-start' }}
                  onClick={handleDemo}
                  >Watch demo
                  <ChevronRight size={18} style={{ marginRight: "-2px" }} />
                </button>
              </div>
            </>
          )}

          </div> */}