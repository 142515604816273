import React, { useContext, useEffect, useState, } from 'react';
import { API, dbRefs, RESOURCES_UPLOAD_METHODS, PAYWALL_TYPES, EXAMPLE_NOTES } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { Mic, Upload, Zap } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ResourcesTable from '../components/ResourcesTable4';
import { Routes } from '../navigation/routes';
import ScreenHeader from '../components/ScreenHeader3';

const NEW_USER_ID = 'newuserexample'

const ResourcesScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const navigate = useNavigate()

  // TABLE
  const [ data, setData ] = useState(null)
  const [ dataCount, setDataCount ] = useState(0)
  const [ currentPage, setCurrentPage ] = useState(0)
  const [ itemsPerPage ] = useState(20)
  const totalPages = Math.ceil(dataCount / itemsPerPage);

  
  useEffect(() => {
      getData()
  }, [currentPage, ])

  const getData = async () => {

    try {
    let results = []
    
    if ( user ) {
      const [docsResult, countResult] = await Promise.all([
        axios.get(API + '/generalAggregation', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'sourceContent.url': { $exists: true } }),
          projectionObj: JSON.stringify({ _id: 1, title: 1, sourceContent: 1, date: 1, category: 1, uploadMethod: 1, uploadStatus: 1,  }),
          sortObj: JSON.stringify({ date: -1 }),
          currentPage: currentPage,
          pageSize: itemsPerPage,
          dbRef: dbRefs.resources
        }}),
        axios.get(API + '/generalCount', { params: {
          matchObj: JSON.stringify({ user_id: user?._id, 'sourceContent.url': { $exists: true } }),
          dbRef: dbRefs.resources
        }})
      ]);

      const docsResults = docsResult.data;
      const countResults = countResult.data;

      if (docsResults.length === 0) {
        setData(EXAMPLE_NOTES)
        setDataCount(EXAMPLE_NOTES.length)
      }
      else {
        setData(docsResults)
        setDataCount(countResults)
      }
    }
    else {
      setData([])
      }
    }
    catch (err) {
      console.error('Error getting resources:', err);
    }
  }

  const handleRowClick = (e, item) => {
    navigate(`/app/${Routes.RESOURCES}/${item._id}/${Routes.SOURCE}`)
  };

  const onPreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const onNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div id="wrapper">
      <div id="main" style={{ paddingBottom: 0 }}>
        <div className="inner" style={{ }}>

          <ScreenHeader title="Your materials" />

          { data == null || data?.length == 0 ? null : 
            <>
              
              <ResourcesTable
                data={data} 
                setData={setData} 
                handleRowClick={handleRowClick} 
              />

            
              { totalPages > 1 &&
                <nav aria-label="Pagination" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px', padding: "1rem", bottom: 0, left: 0, right: 0, backgroundColor: 'var(--color-backgroundSecondary)', borderTop:    '1px solid var(--color-separatorOpaque)', position: "sticky", bottom: 0, left: 0, right: 0 }}>
                  <button
                    style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
                    onClick={onPreviousPage}
                    disabled={currentPage === 0}
                    aria-label="Go to previous page"
                    aria-disabled={currentPage === 1}
                  >
                    ←
                  </button>
                  <span style={{ color: 'var(--color-text3)', fontSize: '14px' }} aria-live="polite" aria-atomic="true">
                    Page {currentPage + 1} of {Math.ceil(dataCount / itemsPerPage)} | {dataCount} items
                  </span>
                  <button
                    style={{ padding: '8px 16px', backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid #ccc', borderRadius: '20px', cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer', fontSize: '16px', opacity: currentPage === totalPages - 1 ? 0.5 : 1, boxShadow: "var(--borderShadow)" }}
                    onClick={onNextPage}
                    disabled={currentPage === totalPages - 1}
                    aria-label="Go to next page"
                    aria-disabled={currentPage === totalPages - 1}
                  >
                    →
                  </button>
                </nav>
              }

            </>
          }
            
        </div>
      </div>
    </div>
  );  
}


export default ResourcesScreen;



// const Tile = ({ item, index, handleTabClick }) => {
//   const { isPhoneAndBelow } = useBreakpoint()
  
//   if ( !isPhoneAndBelow ) {
//     return (
//       <button key={index} style={{ fontSize: '1rem', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', background: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', padding: '1.25rem', borderRadius: '1rem', transition: 'all 0.2s ease-in-out', display: 'flex', flexDirection: 'column', cursor: 'pointer', width: '100%', maxWidth: '220px', textAlign: 'left', alignItems: 'flex-start', justifyContent: 'flex-start' }}
//       onClick={() => handleTabClick(item)}
//         onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//         onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//       >
//         <div style={{ backgroundColor: '#EEE7FE', borderRadius: '30px', padding: '.5rem', display: 'inline-flex', }}>
//           {React.cloneElement(item.icon, { size: 20, color: 'var(--color-primary)', style: { } })}
//         </div>
//         <span style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem', marginTop: '.75rem'  }}
//           >{item.title}
//         </span>
//         <span style={{ fontSize: '1rem', color: 'var(--color-text3)', marginTop: '.35rem'  }}
//           >{item.description}
//         </span>
//       </button>
//     )
//   }
//   else {
//     return (
//       <button key={index} style={{ fontSize: '1rem', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', background: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', padding: '1.25rem', borderRadius: '.5rem', transition: 'all 0.2s ease-in-out', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', cursor: 'pointer', width: '100%' }}
//       onClick={() => handleTabClick(item)}
//         onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//         onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundSecondary)'}
//       >
//         <div style={{ backgroundColor: '#EEE7FE', borderRadius: '30px', padding: '.5rem', display: 'inline-flex', }}>
//           {React.cloneElement(item.icon, { size: 20, color: 'var(--color-primary)', style: { } })}
//         </div>
//         <div style={{ display: 'flex', flexDirection: 'column', height: '100%', cursor: 'pointer', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', paddingLeft: '1rem', lineHeight: '1.2' }}>
//           <div style={{ fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)', textAlign: 'left' }}
//             >{item.title}
//           </div>
//           <div style={{ fontSize: '1rem', color: 'var(--color-text3)', marginTop: '.15rem', textAlign: 'left',  }}
//             >{item.descriptionMobile || item.description}
//           </div>
//         </div>
//       </button>
//     )
//   }
// }


              {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '0rem' }}>
                <h2 style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem' }}
                  >Create new
                </h2>
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '1rem', marginBottom: '1rem' }}>
                { Object.values(altTabs).map((tab, index) => 
                  <Tile key={index} item={tab} index={index} handleTabClick={handleTabClick} />
                )}
              </div>


              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '2rem' }}>
                <h2 style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem' }}
                  >Your materials
                </h2>
              </div> */}



// { Object.values(altTabs).map(tab => {
//   return (
//     <button key={tab.title} style={{ fontSize: '1rem', color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', background: 'var(--color-background)', boxShadow: 'var(--borderShadow)', padding: '2rem', borderRadius: '.5rem', transition: 'all 0.2s ease-in-out', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%', cursor: 'pointer',  }}
//     onClick={() => handleTabClick(tab)}
//       onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'var(--color-backgroundTertiary)'}
//       onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'var(--color-background)'}
//     >
//       <div style={{ backgroundColor: 'black', borderRadius: '.5rem', padding: '6px', display: 'inline-flex', marginBottom: '.5rem' }}>
//         {React.cloneElement(tab.icon, { size: 16, color: 'white', style: { } })}
//       </div>
//       <span style={{ fontSize: '1.1rem', fontWeight: '600', color: 'var(--color-text1)', letterSpacing: '-0.04rem'  }}
//         >{tab.title}
//       </span>
//     </button>
//   )
// })}