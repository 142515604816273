import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { useBreakpoint } from '../misc/useBreakpoint';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const pdfUrl = "https://storage.googleapis.com/a2p-v2-storage/a89185ff-0719-4d3c-b19f-ea591bf6450c"
// const pdfUrl = "https://storage.googleapis.com/a2p-v2-storage/17d42c48-6cbe-4c38-b2e1-914466b1ba58"


const TextPreview = ({ isActive = false, data }) => {
  const [ content, setContent ] = useState(null)
  const { isMobileAndBelow } = useBreakpoint()

  useEffect(() => {
    if ( isActive ) {
      loadContent()
    }
  }, [isActive, data?._id])

  const loadContent = async () => {
    try {
      const result = await axios.get(data.sourceContent.url);
      const results = result?.data;
      setContent(results);
    }
    catch (error) {
      console.error('Error fetching text content:', error);
    }
  }

  if ( !isActive ) return null
  return (
    <div style={{ whiteSpace: 'pre-wrap', color: 'var(--color-text1)', fontSize: '1rem', maxWidth: '910px', width: '100%', alignSelf: 'center', minHeight: '100%',
    }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '.75rem',  }}>
        <h1 style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text4)', textTransform: 'uppercase' }}>
          Source Text
        </h1>
      </div>
      <div style={{ whiteSpace: 'pre-wrap', color: 'var(--color-text1)', fontSize: '1rem', lineHeight: '1.5rem', }}>
        {content}
      </div>
    </div>
  )
}

const PdfPreview = ({ isActive = false, data }) => {

  const pdfUrl = data?.sources?.[0]?.urlPdf || (data?.sources?.[0]?.fileType === 'pdf' ? data?.sources?.[0]?.url : null) || null

  if ( !isActive ) return null
  return (          
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer
        fileUrl={pdfUrl}
      />
    </Worker>
  )
}

const ResourceSourceScreen = ({  }) => {
  const { data } = useOutletContext()
  const textPreviewTrue = data?.uploadMethod === 'text'
  const pdfPreviewTrue = data?.uploadMethod === 'file' && ( data?.sources?.[0]?.fileType === 'pdf' || data?.sources?.[0]?.urlPdf )

  return (
    <div id="wrapper">
      <div id="main" >
        <div className="inner" >

          <TextPreview isActive={textPreviewTrue} data={data} />
          <PdfPreview isActive={pdfPreviewTrue} data={data} />

          {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer
        fileUrl={pdfUrl}
      />
    </Worker> */}

          { !textPreviewTrue && !pdfPreviewTrue && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'var(--color-text3)', fontSize: '1.1rem', padding: '2rem' }}>
              No file preview available
            </div>
          )}

      </div>
      </div>
    </div>
  );
};

export default ResourceSourceScreen;