import React, { useEffect, useRef, useState } from 'react';
import { API, dbRefs, } from '../misc/constants';
import axios from 'axios';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Resource_Quiz from '../components/Resource_Quiz';
import { Resource_Loader } from '../components/Resource_Loader';
import Subheader from '../components/Subheader';
import Resource_Quiz_Edit from '../components/Resource_Quiz_Edit';
import { getResourceTextUrl } from '../misc/utils';

const tabs = { active: "Quiz", edit: "Edit" }


const ResourceQuizScreen = ({ }) => {
  const { data, setData, setRefreshData } = useOutletContext();
  const [ loading, setLoading ] = useState(!data?.quiz?.content || data?.quiz?.content?.length === 0);
  const [ activeTab, setActiveTab ] = useState(tabs.active);
  const lastLoadedIdRef = useRef();

  useEffect(() => {
    const needsDataLoad = (
      data?._id && 
      data?._id !== lastLoadedIdRef.current && 
      ( !data?.quiz?.content || data?.quiz?.content?.length === 0 )
    );

    if (needsDataLoad) {
      generateContent();
      lastLoadedIdRef.current = data?._id;
    }
  }, [data?._id]); 

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      let result = await axios.post(`${API}/generateQuiz2`, { resource_id: data._id, content_url });
      let content = result.data.content;
      setData((prev) => ({ ...prev, quiz: { content } }));
      setRefreshData((prev) => !prev);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
    }
    finally {
      setLoading(false);
    }
  }

  const handleEdit = async (instructions) => {
    setLoading(true);

    try {
      await axios.post(`${API}/updateQuiz`, { resource_id: data._id, instructions: instructions });
      setRefreshData((prev) => !prev);
      setActiveTab(tabs.active);
    } 
    catch (error) {
      console.error('Error updating quiz:', error);
    }
    finally {
      setLoading(false);
    }

    
  }

  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner" style={{ maxWidth: '1000px', margin: '0 auto' }}>

          <Resource_Loader isActive={loading} text="Generating quiz" subText="This may take ~15 seconds" />

          { !loading && (
            <>
              <Subheader tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
              
              { activeTab === tabs.active && (
                <Resource_Quiz isActive={!loading} data={data} setRefreshData={setRefreshData} />
              )}

              { activeTab === tabs.edit && (
                <Resource_Quiz_Edit data={data} handleEdit={handleEdit} />
              )}

            </>
          )}

        </div>
      </main>
    </div>
  );  
};


export default ResourceQuizScreen;

