'use client';
import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { API, dbRefs } from '../misc/constants';
import CreatableSelect from 'react-select/creatable';
import { Autocomplete, TextField } from '@mui/material';
import { Trash2 } from 'lucide-react';

const ResourceInfoModal = ({ resourceInfoModalId, setResourceInfoModalId, setData}) => {
  const { user, setUser, setRefreshRecentResources } = useContext(UserContext)
  const [ fileName, setFileName ] = useState('')
  const [ selectedClass, setSelectedClass ] = useState(null);

  useEffect(() => {
    if ( resourceInfoModalId ) {
      getData()
    }
  }, [resourceInfoModalId])


  const getData = async () => {
    try {
      let result = await axios.get(`${API}/generalFindOne`, { params: { 
        queryObj: JSON.stringify({ _id: resourceInfoModalId }),
        dbRef: dbRefs.resources
      }})
      let results = result?.data

      setFileName(results?.title)

      if ( results?.category ) {
        let classOption = { value: results?.category, label: results?.category }
        setSelectedClass(classOption)
      }
    }
    catch (error) {
      console.error(error)
    }
  }

  const onSelectClass = (e) => {
    setSelectedClass(e)
    console.log(e.value)
  }

  const onSelectFileName = (e) => {
    const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9\s(){}[\].-_+!@#$%^&*=]/g, '');
    setFileName(sanitizedValue);
  }

  const onClose = () => {
    setFileName('')
    setSelectedClass(null)
    setResourceInfoModalId(null)
  }

  const onDelete = async () => {
    try {
      await axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceInfoModalId }, dbRef: dbRefs.resources }})
      setData((prevData) => prevData.filter(item => item._id !== resourceInfoModalId));
      setRefreshRecentResources((prev) => !prev)
      onClose()
    }
    catch (error) {
      console.error(error)
    }
  }


  const handleSubmit = async () => {

    try {

      let selectedClassEffective = selectedClass?.value || null

      const updateObj = {
        ...(fileName && { title: fileName }),
        ...(selectedClassEffective && { category: selectedClassEffective })
      };
  
      if (Object.keys(updateObj).length === 0) {
        return;
      }

      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: resourceInfoModalId },
        updateObj: { $set: updateObj },
        dbRef: dbRefs.resources,
      });

      setData((prevData) => prevData.map(item => 
        item._id === resourceInfoModalId 
          ? { ...item, ...updateObj }
          : item
      ));

      if ( !user?.categories.includes(selectedClassEffective) ) {
        await axios.put(`${API}/generalUpdateOne`, {
          matchObj: { _id: user._id },
          updateObj: { $push: { categories: selectedClassEffective } },
          dbRef: dbRefs.users,
        });
        setUser({ ...user, categories: [...user.categories, selectedClassEffective] })
      }

      console.log('File updated successfully');
    } 
    catch (error) {
      console.error('Error updating file:', error);
      alert('Error updating file')
    }
    finally {
      onClose()
    }
  }

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      border: '1px solid var(--color-separatorOpaque)',
      height: '3rem', 
      borderRadius: '0.5rem',
      fontSize: '16px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected 
        ? 'var(--color-primary)' 
        : state.isFocused 
          ? 'var(--color-primaryLight)' 
          : 'transparent',
      color: state.isSelected || state.isFocused ? 'white' : 'var(--color-text1)',
      fontSize: '16px',
      // ':hover': {
      //   backgroundColor: 'var(--color-primaryLight)',
      //   color: 'white',
      // },
    }),
    menu: (provided) => ({
      ...provided,
      // bottom: '100%',
      // top: 'auto',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
  };

  return (
    <Drawer.Root open={resourceInfoModalId} onOpenChange={setResourceInfoModalId} repositionInputs={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)', zIndex: 1000 }} />
        <Drawer.Content style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', position: 'fixed', bottom: 0, left: 0, right: 0, maxHeight: '82vh', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', zIndex: 1001 }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ height: "100%", maxWidth: '28rem', width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '2rem' }}>
            <Drawer.Handle />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem' }}>
              <Drawer.Title className="modalTitle"
                id="drawer-title"
                >File information
              </Drawer.Title>
              <Trash2 
                size={20} 
                color="var(--color-text3)" 
                strokeWidth={1.5} 
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  if (window.confirm('Are you sure you want to delete this file?')) {
                    await onDelete()
                  }
                }}
              />
            </div>
            <label htmlFor="name" className="modalFormLabel"
              >Class
            </label>
            <CreatableSelect 
              styles={selectStyle}
              options={ user?.categories?.filter(category => category != null && category.length > 0).map(category => ({ value: category, label: category })) || [] } 
              menuPlacement="auto"        
              value={selectedClass}
              onChange={onSelectClass}
            />
            <label htmlFor="name" className="modalFormLabel"
              >Name
            </label>
            <input className="modalFormInput" 
              type="text" 
              id="fileName" 
              onChange={onSelectFileName}
              value={fileName}
            />
            
            <button className="modalButton"
              onClick={handleSubmit}
              >Submit
            </button>

            
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default ResourceInfoModal;