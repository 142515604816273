import React, { useContext, useEffect, useState, useRef } from 'react';
import { API, PAYWALL_TYPES, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { useOutletContext } from 'react-router-dom';
import { ArrowDownToLine, Lock, ArrowRight } from 'lucide-react';
import Resource_Notes from '../components/Resource_Notes2';
import { Resource_Error, Resource_Loading, Resource_Submitted, Resource_UploadError } from '../components/Resource_RouteStates';

const loadingStates = { loading: "loading", uploadError: "uploadError", error: "error", success: "success", submitted: "submitted", }


const ResourceNotesScreen = () => {
  const { data, setData, setRefreshData } = useOutletContext();
  const { user, setShowPaywallType, setShowCheckoutModal, checkUserPermission } = useContext(UserContext);
  const [ loadingState, setLoadingState ] = useState(loadingStates.initial)
  const lastLoadedIdRef = useRef();

  useEffect(() => {
    const needsDataLoad = (
      data?._id && 
      data?._id !== lastLoadedIdRef.current && 
      !data?.notes?.urlText
    );

    if (needsDataLoad) {
      generateContent();
      lastLoadedIdRef.current = data?._id;
    }
  }, [data?._id]); 

  const generateContent = async () => {
    if ( data?.notes?.urlText ) { 
      setLoadingState(loadingStates.success); 
    }
    else if ( !data?.sources || data?.sources?.length === 0 ) {
      setLoadingState(loadingStates.uploadError);
    }
    else if ( data?.notes?.error ) {
      setLoadingState(loadingStates.error);
    }
    else if ( data?.notes?.submitted ) {
      setLoadingState(loadingStates.submitted);
    }
    else {
      try {
        setLoadingState(loadingStates.loading);
        await generateNotes();
      } 
      catch (error) {
        setLoadingState(loadingStates.error);
      } 
    }
  };

  const generateNotes = async () => {
    try {
      await axios.post(`${API}/generateNotes`, { file_id: data._id });
      setRefreshData((prev) => !prev);
      setLoadingState(loadingStates.success);
    } 
    catch (error) {
      setLoadingState(loadingStates.error);
    } 
  }


  /////////////////////// COMPONENTS /////////////////////////
  
  const DownloadButtons = ({ }) => {
    const [ loading, setLoading ] = useState(false)
    const notesTypes = { pdf: "PDF", docx: "DOCX" }

    const handleDownload = async (type, url) => {
      try {
        setLoading(type)
        let newUrl = ''

      // if ( user?.isMember ) {
        if ( type === notesTypes.pdf ) {
          const result = await axios.post(API + '/generateNotesPdf', { resource_id: data._id, resource_title: data.title, notes_urlText: data.notes.urlText })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlPdf: newUrl } })

        }
        else if ( type === notesTypes.docx ) {
          const result = await axios.post(API + '/generateNotesDocx', { resource_id: data._id, resource_title: data.title, notes_urlText: data.notes.urlText })
          newUrl = result.data.url
          setData({ ...data, notes: { ...data.notes, urlDocx: newUrl } })
        }


        if ( newUrl ) {
          const link = document.createElement('a');
          link.href = newUrl;
          link.setAttribute('download', `${data.title}.${type.toLowerCase()}`); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      // } 
      // else {
      //   setShowPaywallType(PAYWALL_TYPES.MEMBERS_ONLY_FEATURE)
      // }
      }
      catch (error) {
        alert('Error downloading notes. Please try again.')
        console.error('Error downloading notes:', error);
      }
      finally {
        setLoading(false)
      }
    };

    const loadingSpinner = (type) => {
      return loading == type ? (
        <div style={{ width: '16px', height: '16px', border: '2px solid currentColor', borderTopColor: 'transparent', borderRadius: '50%', animation: 'loading-spinner 1s linear infinite', }} />
      ) : <ArrowDownToLine size={16} />
    }

    if ( data?.notes?.urlText ) return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '.75rem', marginBottom: '.75rem',  justifyContent: 'flex-end', position: "absolute", top: "0", right: "0", zIndex: 1000 }} role="group" aria-label="Download options">

        <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', padding: ".75rem 1rem", backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '5px',  }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          onClick={async () => await handleDownload(notesTypes.pdf, data?.notes?.urlPdf)}
          aria-label="Download PDF"
        >
          {loadingSpinner(notesTypes.pdf)}
          PDF
        </button>

        <button style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', borderRadius: '0.5rem', fontSize: '0.875rem', fontWeight: '500', padding: ".75rem 1rem", backgroundColor: 'var(--color-text1)',color: 'var(--color-textButton)', border: 'none', cursor: 'pointer', transition: 'opacity 0.2s', boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)', gap: '5px' }}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
          onClick={async () => await handleDownload(notesTypes.docx, data?.notes?.urlDocx)}
          aria-label="Download DOCX"
        >
          {loadingSpinner(notesTypes.docx)}
          Docx
        </button>

      </div>
    );
    else return null
  };



  if ( loadingState === loadingStates.loading ) return <Resource_Loading />
  if ( loadingState === loadingStates.uploadError ) return <Resource_UploadError resource_id={data?._id} />
  if ( loadingState === loadingStates.error ) return <Resource_Error onClick={() => generateNotes()} />
  if ( loadingState === loadingStates.submitted ) return <Resource_Submitted />
  if ( data?.notes?.urlText ) return (
    <div id="wrapper">          
      <main id="main" style={{ }}>
        <div className="inner" style={{ maxWidth: '910px', margin: '0 auto' }}>
       
          <DownloadButtons />
          <Resource_Notes isActive={true} data={data} setRefreshData={setRefreshData} />

        </div>
      </main>
    </div>
  );
  else return null
};


export default ResourceNotesScreen;




// const [ permissionToView, setPermissionToView ] = useState(true);

// useEffect(() => {
//   const checkPermission = async () => {
//     const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.notes, false)
//     setPermissionToView(hasPermission)
//   }
//   checkPermission()
// }, [user])


// { !permissionToView && (
//   <>
//     <div style={{display: "flex", flexDirection: "column", position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 1000, }}>
//       <div style={{ height: "500px", width: "100%", background: 'linear-gradient(to bottom, transparent, rgba(255,255,255,0.9))' }}/>
//       <div style={{ display: "flex", flex: 1, width: "100%", background: 'rgba(255,255,255,0.96)' }}/>


//       <div style={{ position: "fixed", zIndex: 1001, left: "50%", bottom: "20%", transform: "translateX(-50%)", backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.5rem', borderRadius: '.5rem', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)', maxWidth: '400px', width: '90%', textAlign: 'center' }}>
//         <Lock size={24} style={{ margin: '0 auto', color: 'var(--color-text1)', strokeWidth: '1.5' }} />
//         <h3 style={{ fontSize: '1.1rem', fontWeight: '600', marginBottom: '.5rem', color: 'var(--color-text1)', textAlign: 'center' }}
//           >You've Exceeded Your Free Credits
//         </h3>
//         <p style={{ color: 'var(--color-text2)', marginBottom: '1rem', lineHeight: '1.3', textAlign: 'center' }}
//           >To unlock this file, enable downloads, and create more note files, upgrade to School Goat Plus
//         </p>
//         <button style={{ width: '100%', padding: '12px 20px', backgroundColor: 'black', color: 'white', border: 'none', borderRadius: '6px', cursor: 'pointer', fontSize: '1rem', fontWeight: '500', transition: 'background-color 0.2s' }}
//           onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
//           onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
//           onClick={() => setShowCheckoutModal(true)}
//         >
//           Continue
//           <ArrowRight size={16} style={{ marginLeft: '5px' }} />
//         </button>
//       </div>

//     </div>
//   </>
// )}