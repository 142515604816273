'use client';
import { Drawer } from 'vaul';
import React, { useRef, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { UserContext } from '../contexts/UserProvider';
import { dbRefs, RESOURCES_UPLOAD_METHODS, SLACK_WEBHOOK_CHANNELS } from '../misc/constants';
import { initResourceObj, sendSlackNotification, uploadResourceToS3 } from '../misc/utils';
import { API } from '../misc/constants';
import { useNavigate } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';
import { ArrowRight, Sparkles } from 'lucide-react';
import { Routes } from '../navigation/routes';
import Uploader_Button from './Uploader_Button';

const Uploader_Text = ({ 
  placeholder = "Paste text from your class materials (readings, notes, etc.)...",
  styleOverride = {},

 }) => {
  const { isPhoneAndBelow } = useBreakpoint();
  const { user, setRefreshRecentResources } = useContext(UserContext);
  const navigate = useNavigate();
  const [file, setFile] = useState('');
  const textareaRef = useRef(null);
  const [ isLoading, setIsLoading ] = useState(false)

  const handleClose = () => {
    setFile('');
  };

  const uploadFile = async () => {

    let resourceObj = null

    try {
      setIsLoading(true)

      resourceObj = await initResourceObj({ user, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await uploadResourceToS3({ resource_id: resourceObj._id, file: file, uploadMethod: RESOURCES_UPLOAD_METHODS.text })
      await axios.post(`${API}/generateSourceContent`, { resource_id: resourceObj._id, userCategories: user.categories  })
      handleClose();
      setRefreshRecentResources(prev => !prev)
      navigate(`/app/resources/${resourceObj._id}/${Routes.SUMMARY}`)
    } 
    catch (error) {
      console.error('Error uploading file:', error);
      try {
        const fullMessage = `Error uploading file: ${error.message}\nuser: ${user.email}\nfile: ${file.name}\nfile type: ${file.type}\nfile size: ${file.size}\nUpload method: ${RESOURCES_UPLOAD_METHODS.text}`;
        await Promise.all([
          sendSlackNotification(fullMessage, SLACK_WEBHOOK_CHANNELS.issues),
          axios.delete(`${API}/generalDeleteOne`, { data: { matchObj: { _id: resourceObj._id }, dbRef: dbRefs.resources } })
        ]);
      } 
      catch (error) {
      }

      setFile('')
      alert(error.message);
    }
    finally {
      setIsLoading(false)
    }
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%' }}>
        <textarea 
          ref={textareaRef} 
          style={{
            display: 'flex',
            flexDirection: 'column', 
            gap: '1rem',
            height: '300px',
            backgroundColor: 'var(--color-backgroundSecondary)',
            padding: '1rem',
            borderRadius: '.5rem',
            border: '1px solid var(--color-separatorOpaque)',
            // boxShadow: 'var(--borderShadow)',
            alignItems: 'center',
            justifyContent: 'center',
            resize: 'none',
            fontSize: isPhoneAndBelow ? '16px' : '1rem',
            color: 'var(--color-text2)',
            lineHeight: '1.4',
            outline: 'none',
            width: '100%',

            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,

            // borderBottomLeftRadius: 0,
            // borderTopLeftRadius: 0,
            // borderBottomRightRadius: '1rem', 
            // borderTopRightRadius: '1rem',
            ...styleOverride
          }}
          placeholder={placeholder}
          value={file}
          onChange={(e) => setFile(e.target.value)}
          aria-label="Text to convert"
        />
        {/* <div style={{ position: 'absolute', bottom: '.25rem', right: '.25rem', zIndex: 1 }}> */}
          {/* <Uploader_Button isActive={file} isLoading={isLoading} onClick={uploadFile} /> */}
        {/* </div> */}
      </div>

      <button style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', padding: '.75rem', borderRadius: '.5rem', fontSize: '1rem', fontWeight: '600', transition: 'all 300ms ease-in-out', position: 'relative', marginTop: '1.5rem', opacity: !file ? 0.1 : 1 }}
        disabled={!file || isLoading}
        onClick={uploadFile}
        >
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isLoading ? 0 : 1 }}>
            Continue
            <ArrowRight style={{ width: '1rem', height: '1rem', color: 'var(--color-textButton)', marginLeft: '1rem',   }} />
          </span>
          { isLoading && (
            <div style={{ display: 'flex', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
              <div style={{ width: '20px', height: '20px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
              <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
            </div>
          )}
      </button>

    </>
    
  );
};

export default Uploader_Text;


                {/* { isUploading ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem' }}>
                  <div style={{ width: '40px', height: '40px', border: '3px solid var(--color-separatorOpaque)', borderTop: '3px solid var(--color-primary)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
                </div>
                <style>
                  {`
                    @keyframes spin {
                      0% { transform: rotate(0deg); }
                      100% { transform: rotate(360deg); }
                    }
                  `}
                </style>
              </>
            ) : (
              <div style={buttonContainerStyle}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button 
                  onClick={uploadFile} 
                  disabled={!file} 
                  variant="contained"
                  aria-label="Convert to Notes"
                  aria-disabled={!file}
                >
                  Upload
                </Button>
              </div>
            )} */}
              
        // <ResourceProcessingModal open={openResourceProcessingModal} setOpen={setOpenResourceProcessingModal} />
