import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserProvider';
import { Routes } from './routes';
import { useState } from 'react';
import { FileText, MessageCircle, Zap, HelpCircle, GraduationCap, Rabbit, ArrowLeft, Menu, X, Sparkles, Play, ShieldCheck, MessageSquareMore, Upload, MapPin, Check } from 'lucide-react';
import SchoolGoatBg from '../assets/SchoolGoatBg.png';
import { useLocation } from 'react-router-dom';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import { dbRefs, API } from '../misc/constants';
import { useParams } from 'react-router-dom';

const tabs = [
  { title: 'Source', route: Routes.SOURCE, icon: <Upload /> },
  { title: 'Chat', route: Routes.CHAT, icon: <MessageCircle /> },
  { title: 'Summary', route: Routes.SUMMARY, icon: <MapPin /> },
  { title: 'Notes', route: Routes.NOTES, icon: <FileText /> },
  { title: 'Tutor', route: Routes.TEACH, icon: <GraduationCap /> },
  { title: 'Flashcards', route: Routes.FLASHCARDS, icon: <Zap /> },
  { title: 'Quiz', route: Routes.QUIZ, icon: <ShieldCheck /> }, 
  { title: 'Test', route: Routes.TEST, icon: <HelpCircle /> },
]

const checkMaterialExists = ( item, data ) => {
  if ( item.route == Routes.SOURCE ) return true
  if ( item.route == Routes.SUMMARY ) return data?.summary?.content
  if ( item.route == Routes.NOTES ) return data?.notes?.urlText
  if ( item.route == Routes.TEACH ) return data?.teach?.content
  if ( item.route == Routes.FLASHCARDS ) return data?.flashcards?.content?.length > 0
  if ( item.route == Routes.QUIZ ) return data?.quiz?.content?.length > 0
  if ( item.route == Routes.TEST ) return true
  if ( item.route == Routes.CHAT ) return true
  return false
}

const Tile = ({ item, isActive, data,  }) => {
  const navigate = useNavigate();
  const { isPhoneAndBelow } = useBreakpoint();
  const materialExists = checkMaterialExists(item, data);

  if ( !isPhoneAndBelow ) return (
    <button style={{ fontSize: '1rem', color: 'var(--color-text3)', backgroundColor: 'var(--color-background)', borderTop: '1px solid var(--color-separatorOpaque)', borderLeft: '1px solid var(--color-separatorOpaque)', borderRight: '1px solid var(--color-separatorOpaque)', cursor: 'pointer', padding: '.75rem', fontSize: ".9rem",  borderTopRightRadius: '.5rem', borderTopLeftRadius: '.5rem', gap: '.5rem', position: 'relative',
      minWidth: '70px', flexShrink: 0,
      ...(isActive && { backgroundColor: 'var(--color-backgroundSecondary)', }),
     }}
      onClick={() => navigate(`${item.route}`)}
      onMouseEnter={e => !isActive && (e.target.style.backgroundColor = 'var(--color-backgroundSecondary)')}
      onMouseLeave={e => !isActive && (e.target.style.backgroundColor = 'var(--color-background)')}
    >
        { materialExists && (
          <Check size={10} color="var(--color-text3)" strokeWidth={2} style={{ position: 'absolute', top: '2px', right: '2px', backgroundColor: 'transparent', pointerEvents: 'none' }} />
        )}
      {item.title}
    </button>
  )
  else return (
    <button style={{ fontSize: '1rem', color: 'var(--color-text3)', backgroundColor: 'transparent', cursor: 'pointer', padding: '.5rem 1rem', fontSize: ".9rem",  borderRadius: '30px', position: 'relative', flexShrink: 0, marginBottom: '.5rem',
      ...(isActive && { backgroundColor: 'var(--color-backgroundTertiary)', }),
     }}
      onClick={() => navigate(`${item.route}`)}
    >
      {item.title}
    </button>
  )
}


const ResourceRouter = ({  }) => {
  const { user, setShowCheckoutModal } = useContext(UserContext);
  const { isTabletAndBelow, isMobileAndBelow } = useBreakpoint();
  const { resource_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState({});
  const [data, setData] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    let pathname = location.pathname;
    let lastPart = pathname.split('/').pop();

    if ( lastPart == resource_id ) {
      navigate(Routes.SUMMARY);
    }
    else {
      setActiveTab(lastPart);
    }
  }, [location]);

  useEffect(() => {
    getData();
  }, [resource_id, refreshData]);

  const getData = async () => {
    try {
      let result = await axios.get(API + '/generalFindOne', { params: { queryObj: JSON.stringify({ _id: resource_id }), dbRef: dbRefs.resources } });
      let results = result?.data
      setData(results);
    } 
    catch (error) {
      console.error('Error fetching text content:', error);
    }
  };

  if ( !data?._id || !data?.sourceContent?.url ) return null
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden', position: 'relative', backgroundColor: 'var(--color-background)' }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: "relative", backgroundColor: 'var(--color-background)', overflowX: 'hidden' }}>
        
        <div style={{           display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: isTabletAndBelow ? 0 : '0.75rem',
          paddingRight: '1rem',
          paddingLeft: !isTabletAndBelow ? '0' : !isMobileAndBelow ? '2rem' : '1rem',
          backgroundColor: 'var(--color-background)',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          gap: '2px',
          // Scrollbar styles
          // scrollbarWidth: 'thin',
          // scrollbarColor: 'var(--color-text3) transparent',
          overflowX: 'auto',
          maxWidth: '100vw',
          }}>

            {tabs.map((item, index) => (
              <Tile key={index} item={item} isActive={item.route === activeTab} data={data} />
            ))}

        </div>
        
        <Outlet context={{ data, setData, setRefreshData }} />
      </div>
    </div>
  )
};

export default ResourceRouter;
