'use client';
import React, { useContext } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { ArrowDown, ArrowRight, Circle, FileText, Mail, Mic, X } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';

const tabs = [
  { title: 'Listen', description: `Click below and School Goat will begin listening to your class`, icon: <Mic /> },
  { title: "Take Notes", description: "It will write everything down and create organized notes", icon: <FileText /> },
  { title: "Deliver", description: "Then it will email you the notes after class as PDF & Word doc", icon: <Mail /> },
]

const Tile = ({ item, index, arr }) => {
const { isPhoneAndBelow } = useBreakpoint();

  if (!isPhoneAndBelow) {
    return (
      <React.Fragment key={index}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',  flex: 1, backgroundColor: 'var(--color-backgroundSecondary)', padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}>
        <div style={{ backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '30px', padding: '.75rem', display: 'inline-flex' }}>
          {React.cloneElement(item.icon, { size: 24, color: 'var(--color-text1)', strokeWidth: 1.5, style: { } })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: '600',  }}
            >{item.title}
          </h3>
          <p style={{ fontSize: '0.9rem', color: 'var(--color-text3)', marginTop: '.35rem', lineHeight: '1.2' }}
            >{item.description}
          </p>
        </div>
      </div>
      {index < arr.length - 1 && (
        isPhoneAndBelow ? (
          <ArrowDown size={16} color="var(--color-text3)" style={{ flexShrink: 0 }} />
        ) : (
          <ArrowRight size={16} color="var(--color-text3)" style={{ flexShrink: 0 }} />
        )
      )}
    </React.Fragment>
    )
  }
  else {
    return (
      <React.Fragment key={index}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', flex: 1, backgroundColor: 'var(--color-backgroundSeoncary)', padding: '1rem', borderRadius: '.5rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)' }}>
        <div style={{ backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '30px', padding: '.75rem', display: 'inline-flex' }}>
          {React.cloneElement(item.icon, { size: 24, color: 'var(--color-text1)', strokeWidth: 1.5, style: { } })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column',  }}>
          <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: '600',  }}
            >{item.title}
          </h3>
          <p style={{ fontSize: '1rem', color: 'var(--color-text4)', marginTop: '.35rem', lineHeight: '1.2' }}
            >{item.description}
          </p>
        </div>
      </div>
      {index < arr.length - 1 && (
        <ArrowDown size={16} color="var(--color-text4)" style={{ flexShrink: 0 }} />
      )}
    </React.Fragment>
    )
  }
}




const AudioRecorderModal = ({ open, setOpen, setTriggerRecording }) => {
  const { user,  } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();


  const handleClose = () => {
    setOpen(false);
  }

  const handleOnClick = () => {
    handleClose();
    setTriggerRecording(true);
  }

  return (
      <Drawer.Root open={open} onOpenChange={() => {}} dismissible={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000 }} 
          onClick={handleClose}
        />
        <Drawer.Content style={{ display: "flex", flexDirection: "column", position: "fixed", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", zIndex: 1001, backgroundColor: "var(--color-backgroundSecondary)",
          ...(isPhoneAndBelow && { bottom: 0, left: 0, right: 0 }),
          ...(!isPhoneAndBelow && { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '500px', margin: '0 auto', maxHeight: '90vh', borderRadius: '10px', overflow: 'hidden', animation: "none", })
         }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ width: '100%', margin: '0 auto', overflowY: 'auto', padding: '1.5rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px',  }}>

            <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', fontSize: '.75rem', fontWeight: '500', padding: '.5rem', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.35rem' }}>
                LIVE LECTURE
              </div>

              <button style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', }}
                onClick={handleClose}
                aria-label="Close modal"
                onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
              >
                <X size={20} color="var(--color-text3)" />
              </button>
            </div>

            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', fontSize: '1.5rem' }}
              id="drawer-title"
              >Are you in class?
            </Drawer.Title>
            <Drawer.Description style={{ lineHeight: '1.4', marginTop: '1rem', color: 'var(--color-text3)', fontSize: '1rem', marginBottom: '1rem' }}
              id="drawer-description"
              >School Goat will take notes automatically for you. Just click below and let it run throughout your class. You'll be able to download them as PDF/Word.
            </Drawer.Description>



            <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-white)', borderRadius: '0.5rem', marginTop: '2rem', width: '100%', fontWeight: 500, fontSize: '1rem', padding: '.75rem', border: 'none', fontFamily: 'inherit', cursor: 'pointer' }}
              onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
              onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
              onClick={handleOnClick}
              >Take Notes For Me
              <ArrowRight style={{ width: '1rem', height: '1rem', color: 'var(--color-white)', marginLeft: '0.5rem' }} />
            </button>

          </div>

        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default AudioRecorderModal; 





{/* <div style={{ display: 'flex', flexDirection: isPhoneAndBelow ? 'column' : 'row', gap: '.5rem', marginTop: '1rem', alignItems: 'center', width: '100%' }}>
{tabs.map((item, index, arr) => (
  <Tile key={index} item={item} index={index} arr={arr} />
))}
</div> */}