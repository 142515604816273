import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { API } from '../misc/constants';
import { Resource_Loader } from '../components/Resource_Loader';
import { useBreakpoint } from '../misc/useBreakpoint';
import { AlertCircle, BarChart2, Clock } from 'lucide-react';
import { getResourceTextUrl } from '../misc/utils';

const TextStyle = { fontSize: '1rem', color: 'var(--color-text2)', lineHeight: '1.4rem' }

const Subheader = ({ title, styleOverride }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', marginBottom: '.75rem', marginTop: '2.5rem', ...styleOverride }}>
      <h1 style={{ fontSize: '1rem', fontWeight: '500', color: 'var(--color-text4)', textTransform: 'uppercase' }}>{title}</h1>
    </div>
  )
}

const TextItem = ({ text, }) => {
  return (
    <p style={TextStyle}>{text}</p>
  )
}

const ResourceSummaryScreen = ({ }) => {
  const { data, setData, setRefreshData } = useOutletContext();
  const [ loading, setLoading ] = useState(!data?.summary?.content || data?.summary?.content?.length === 0);
  const lastLoadedIdRef = useRef();
  
  useEffect(() => {
    const needsDataLoad = (
      data?._id && 
      data?._id !== lastLoadedIdRef.current && 
      !data?.summary?.content
    );

    if (needsDataLoad) {
      generateContent();
      lastLoadedIdRef.current = data?._id;
    }
  }, [data?._id]); 

  const generateContent = async () => {
    try {
      setLoading(true);
      let content_url = getResourceTextUrl(data)
      await axios.post(`${API}/generateSummary`, {resource_id: data._id, content_url });
      setRefreshData((prev) => !prev);
    } 
    catch (error) {
      console.error('Error fetching flashcards:', error);
    }
    finally {
      setLoading(false);
    }
  }
  
  return (
    <div id="wrapper">          
      <main id="main">
        <div className="inner">

          <Resource_Loader isActive={loading} text="Generating summary" subText="This may take ~10 seconds" />

          { !loading && (

            <div style={{ maxWidth: '910px', width: '100%', alignSelf: 'center', minHeight: '100%', position: 'relative' }}
            >

              <Subheader title="Main Concept" styleOverride={{ marginTop: '0rem' }}/>
              <p style={{ fontSize: '1.2rem', fontWeight: '600', color: 'var(--color-text1)', lineHeight: '1.5rem' }}>{data?.summary?.content?.mainTopic}</p>

              <Subheader title="Overview"  />
              <TextItem text={data?.summary?.content?.overview} />

              <Subheader title="Key Points" />
              <ul style={{ listStyleType: 'disc', paddingLeft: '2rem', margin: '0',  }}>
                {data?.summary?.content?.keyPoints?.map((point, index) => (
                  <li key={index} style={{ marginBottom: '0.75rem', ...TextStyle }}>{point}</li>
                ))}
              </ul>

              <Subheader title="Likely test topics" />
              {data?.summary?.content?.commonTestTopics?.map((topic, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', border: `1px solid var(--color-separatorOpaque)`, borderRadius: '0.5rem', padding: '1rem', marginBottom: '0.75rem', color: 'var(--color-text2)', backgroundColor: 'var(--color-background)' }}
                  >
                  <AlertCircle size={16} color="var(--color-text2)" />
                  <span style={{ ...TextStyle }}>{topic}</span>
                </div>
              ))}

              <Subheader title="Common mistakes" />
              <ul style={{ listStyleType: 'disc', paddingLeft: '2rem', margin: '0',  }}>
                {data?.summary?.content?.watchOutFor?.map((point, index) => (
                  <li key={index} style={{ marginBottom: '0.75rem', ...TextStyle }}>{point}</li>
                ))}
              </ul>

            </div>

          )}


        </div>
      </main>
    </div>
  );  
};


export default ResourceSummaryScreen;

