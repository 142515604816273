import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, COLOR_ARRAY, PAYWALL_TYPES } from '../misc/constants';
import { RotateCcw } from 'lucide-react';
import SolveMessage from '../components/SolveMessage';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENTS, dbRefs, OPENAI_CHAT_ROLES } from '../misc/constants';
import { v4 as uuidv4 } from 'uuid';
import { getResourceTextUrl, processChatStreamResponse } from '../misc/utils';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContentCreate_Input } from '../components/ContentCreateComponents';


const ResourceChatScreen = ({  }) => {
  const { data } = useOutletContext()
  const { user, checkUserPermission } = useContext(UserContext);
  const { isPhoneAndBelow, } = useBreakpoint();  
  const navigate = useNavigate()

  const messagesEndRef = useRef(null);
  const [ chatInput, setChatInput ] = useState('');
  const [ chatMessages, setChatMessages ] = useState([]);
  const [ loadingResponse, setLoadingResponse ] = useState(false);
  const [ error, setError ] = useState(false);
  const buttonIsLoadingRef = useRef(false)
  const [ refreshChat, setRefreshChat ] = useState(false)

  useEffect(() => {
    getInitialMessage()
  }, [data?.sourceContent?.url, refreshChat])

  const getInitialMessage = async () => {

    try {
      const initialMessages = [
        {
          role: OPENAI_CHAT_ROLES.assistant,
          content: `Hi! Let's talk about <u>${data?.title}</u>. Feel free to ask me questions. You can also use the other tabs to explore the material in different ways.`
        }
      ]

      setChatMessages(initialMessages)
    } 
    catch (error) {
      console.error('Error loading source content:', error)
      alert("Something went wrong. Please try again")
    }
  }

  const handleMessage = async () => {

    if ( buttonIsLoadingRef.current ) return
    buttonIsLoadingRef.current = true

    try {
      const newMessage = { role: OPENAI_CHAT_ROLES.user, content: chatInput }
      await handleSubmit(newMessage)
    }
    catch {
      alert("Something went wrong. Please try again")
    }
    finally {
      buttonIsLoadingRef.current = false
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [loadingResponse])

  const handleSubmit = async (newMessage, clearMessages = false) => {   
    try {

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.resource_chat)

      if ( !hasPermission ) {
        setChatInput("")
        return
      }

      let updatedMessages = clearMessages ? [newMessage] : [...chatMessages, newMessage];
      let updatedMessagesWithLoader = [...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }]
      setChatMessages(updatedMessagesWithLoader);
      setLoadingResponse(true);

      setChatInput("");

      let content_url = getResourceTextUrl(data)

      const response = await fetch(`${API}/streamResourceChat2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resource_id: data._id, user_id: user?._id, messages: updatedMessages,content_url, useBasicModel: true }),
      });
  
      const reader = response.body.getReader();
      await processChatStreamResponse({ reader, setChatMessages, handleError });

      await axios.put(`${API}/generalUpdateOne`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $inc: { [`usage.${PAYWALL_TYPES.resource_chat}`]: 1 } },
        dbRef: dbRefs.users
      });

      // mixpanel.track(MIXPANEL_EVENTS.solver, { user_id: user?._id, user_email: user?.email, })      
    } 
    catch (error) {
      console.error('Error in handleSubmit:', error);
      handleError();
    }
    finally {
      setLoadingResponse(false);
    }
  };

  const handleError = () => {
    setChatMessages((prev) => {
      const updatedMessages = [...prev];
      if (updatedMessages[updatedMessages.length - 1]?.role === OPENAI_CHAT_ROLES.assistant) {
        updatedMessages.pop();
      }
      return [...updatedMessages];
    });
    setError(true);
  }

  const ErrorComponent = () => {
    if ( error ) {

      const handleErrorRetry = () => {
        setError(false);
        let updatedMessages = [...chatMessages];
        let lastUserMessage;

        if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
          updatedMessages.pop();
        }

        for (let i = updatedMessages.length - 1; i >= 0; i--) {
          if (updatedMessages[i].role === OPENAI_CHAT_ROLES.user) {
            lastUserMessage = updatedMessages[i];
            updatedMessages.splice(i, 1);
            break;
          }
        }

        setChatMessages(updatedMessages);

        if (lastUserMessage) {
          handleSubmit(lastUserMessage);
        }
      }


      return (
        <button style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)", display: "flex", width: "15rem", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', color: 'var(--color-text3)', gap: '.5rem', backgroundColor: `${COLOR_ARRAY[1]}33`, borderRadius: '100px' }}
        onClick={handleErrorRetry}
      >
      <p style={{ fontSize: '1rem', color: COLOR_ARRAY[1] }}
        >Error, click to retry
      </p>
        <RotateCcw size={16} color={COLOR_ARRAY[1]}/>
      </button>
      )
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
  }


  return (

    <div id="wrapper" >

      { chatMessages.length > 0 && (
        <>
          <div id="main" style={{ overflowY: 'auto' }}>
            <div className="inner" style={{ }}>
              {chatMessages.length > 0 && (
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  {chatMessages.map((message, index) => (
                    <SolveMessage key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} isMember={user?.isMember} navigate={navigate} />
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              )}
              <ErrorComponent />
            </div>
          </div>

          <div style={{ width: "100%", paddingBottom: "1rem", backgroundColor: 'var(--color-backgroundSecondary)', paddingLeft: "1rem", paddingRight: "1rem" }}>


              
              <div style={{ maxWidth: "800px", display: 'flex', alignItems: 'center', margin: '0 auto', }}>

                <ContentCreate_Input
                  placeholder="Message..."
                  value={chatInput}
                  setValue={setChatInput}
                  handleSubmit={handleMessage}
                >

                <div style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem", width: "100%" }}>
                  <button style={{ padding: '.5rem .75rem', borderRadius: '.5rem', backgroundColor: 'var(--color-backgroundTertiary)', cursor: 'pointer', fontSize: '0.9rem', color: 'var(--color-text2)', display: 'flex', alignItems: 'center', gap: '4px' }}
                    onClick={() => {setRefreshChat(prev => !prev); setChatInput('')}}
                  >
                    Refresh Chat
                    <RotateCcw size={14} strokeWidth={1.5} />
                  </button>
                </div>
                </ContentCreate_Input>

              </div>


          </div>
        </>
      )}
    </div>

  );
};

export default ResourceChatScreen;