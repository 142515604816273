import React, { useState } from 'react';
import { useBreakpoint } from '../misc/useBreakpoint';
import { dbRefs } from '../misc/constants';

const inputStyle = { flex: 1, padding: '.75rem .75rem', border: '1px solid var(--color-separatorOpaque)', borderRadius: '.5rem', fontSize: '14px', outline: 'none' }


const Resource_Flashcards_Edit = ({ data, handleSave }) => {
  const { isMobileAndBelow } = useBreakpoint();
  const [ flashcards, setFlashcards ] = useState(data?.flashcards?.content || [])

  const handleEdit = (index, field, value) => {
    setFlashcards(cards => 
      cards.map((card, i) => 
        i === index ? { ...card, [field]: value } : card
      )
    );
  };

  const handleDelete = (index) => {
    setFlashcards(cards => cards.filter((card, i) => i !== index));
  };

  const handleAdd = () => {
    setFlashcards(cards => [...cards, { term: '', description: '' }]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '1rem' }}>
        <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', padding: '.75rem 1rem', fontSize: '.95rem', fontWeight: '500', cursor: 'pointer', display: 'flex', alignItems: 'center', cursor: 'pointer', }}
          onClick={async () => await handleSave(flashcards)}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
        >
          Save Changes
        </button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {flashcards.map((card, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
            <div style={{ display: 'flex', flexDirection: isMobileAndBelow ? 'column' : 'row', width: '100%', textAlign: 'left', maxWidth: "100%", position: "relative", gap: '.5rem' }}>
              <input style={{ ...inputStyle, flex: .5, fontSize: isMobileAndBelow ? '16px' : '14px' }}
                value={card.term}
                onChange={(e) => handleEdit(index, 'term', e.target.value)}
                placeholder="Enter term"
              />
              <input style={{ ...inputStyle, flex: 1, fontSize: isMobileAndBelow ? '16px' : '14px' }}
                value={card.description}
                onChange={(e) => handleEdit(index, 'description', e.target.value)}
                placeholder="Enter description"
                />
            </div>
            <button style={{ width: '32px', height: '32px', padding: '4px', backgroundColor: 'transparent', border: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'var(--color-text4)' }}
              title="Delete flashcard"
              onClick={() => handleDelete(index)}
              onMouseEnter={(e) => e.currentTarget.style.color = 'var(--color-text1)'}
              onMouseLeave={(e) => e.currentTarget.style.color = 'var(--color-text4)'}
            >
              ✕
            </button>
          </div>
        ))}

        <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-textButton)', border: 'none', borderRadius: '.5rem', padding: '.75rem 1rem', fontSize: '.95rem', fontWeight: '500', cursor: 'pointer', display: 'flex', alignItems: 'center', cursor: 'pointer', alignSelf: 'flex-start' }}
          onClick={handleAdd}
          onMouseEnter={(e) => e.currentTarget.style.opacity = '.7'}
          onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
        >
          + Add Flashcard
        </button>
      </div>

      {flashcards.length === 0 && (
        <div style={{ textAlign: 'center', padding: '16px', color: '#666' }}>
          No flashcards yet. Click "Add Flashcard" to create one.
        </div>
      )}
    </div>
  );
};

export default Resource_Flashcards_Edit;