// components/Loader.tsx
import { helix } from 'ldrs'
import { useEffect } from 'react'

helix.register()

export const Resource_Loader = ({ size = 45, speed = 2.5, color = "black", text = '', subText = '', isActive = true }) => {

  if ( !isActive ) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', paddingBottom: '3rem' }}>
      <l-helix size={size} speed={speed} color={color} />
      {text && (
        <p style={{ marginTop: '1rem', fontSize: '1.3rem', color: 'var(--color-text1)', animation: 'fadeInOut 3s ease-in-out infinite', fontWeight: '600', letterSpacing: '-0.05rem' }}>
          {text}
        </p>
      )}
      {subText && (
        <p style={{ marginTop: '0.5rem', fontSize: '0.8rem', color: 'var(--color-text2)', animation: 'fadeInOut 3s ease-in-out infinite' }}>
          {subText}
        </p>
      )}
      <style>
        {`
          @keyframes fadeInOut {
            0%, 100% { opacity: 1; }
            50% { opacity: 0.1; }
          }
        `}
      </style>
    </div>
  );
}
