import React, { useState, useRef, useEffect, } from 'react';
import { Lightbulb, Book, PenTool, Layout, MessageSquare, Check, ArrowRight } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { ChevronDown, ChevronUp, CheckCircle, XCircle, Wrench, ArrowLeft } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';

const riskLevels = {
  a: {
    value: .9,
    label: 'A',
    color: '#10b981',
    colorLight: '#d1fae5',
  },
  b: {
    value: .8,
    label: 'B',
    color: '#84cc16',
    colorLight: '#ecfccb',
  },
  c: {
    value: .7,
    label: 'C',
    color: '#eab308',
    colorLight: '#fef9c3',
  },
  d: {
    value: .6,
    label: 'D',
    color: '#f97316',
    colorLight: '#ffedd5',
  },
  f: {
    value: 0,
    label: 'F',
    color: '#ef4444',
    colorLight: '#fee2e2',
  },
}

const getRiskLevel = (value) => {
  if (value >= riskLevels.a.value) return riskLevels.a;
  if (value >= riskLevels.b.value) return riskLevels.b;
  if (value >= riskLevels.c.value) return riskLevels.c;
  if (value >= riskLevels.d.value) return riskLevels.d;
  return riskLevels.f;
}

const dummyMetrics = [
  { title: 'Technical', grade: 65, icon: Book },
  { title: 'Potential', grade: 95, icon: Lightbulb },
  { title: 'Creativity', grade: 82, icon: PenTool },
  { title: 'Structure', grade: 91, icon: Layout },
  { title: 'Clarity', grade: 92, icon: MessageSquare },
  { title: 'Grammar', grade: 50, icon: Check },
  { title: 'Grammar2', grade: 50, icon: Check }
];

const calculateWeightedAverage = (items) => {
  if (!Array.isArray(items) || items.length === 0) {
      return ''
  }

  let totalWeightedScore = 0;
  let totalWeight = 0;

  items.forEach((item, index) => {
      if (!item.hasOwnProperty('score') || !item.hasOwnProperty('weight')) {
          throw new Error(`Item at index ${index} is missing required properties`);
      }

      if (typeof item.score !== 'number' || typeof item.weight !== 'number') {
          throw new Error(`Score and weight must be numbers at index ${index}`);
      }

      if (item.weight <= 0) {
          throw new Error(`Weight must be positive at index ${index}`);
      }

      totalWeightedScore += item.score * item.weight;
      totalWeight += item.weight;
  });

  return Math.round(totalWeightedScore / totalWeight);
}

const Essay_Score = ({ isActive, responses, tabs, setActiveTab }) => {
  const { isMobileAndBelow } = useBreakpoint();
  const scores = responses;
  const ITEMS_PER_ROW = 7;

  const maxScore = 100;
  const avgScore = calculateWeightedAverage(scores);
  const riskLevel = getRiskLevel(avgScore/100);
  console.log(scores)

  if (!Array.isArray(responses) || responses.length === 0) {
    responses = dummyMetrics;
  }

  if (!isActive) return null;

  // Split scores into rows of 5
  const rows = [];
  for (let i = 0; i < scores.length; i += ITEMS_PER_ROW) {
    rows.push(scores.slice(i, i + ITEMS_PER_ROW));
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', }}>
      <div style={{ width: '100%', 
        // padding: '1.5rem', borderRadius: '.5rem', backgroundColor: 'var(--color-backgroundSecondary)', boxShadow: 'var(--borderShadow)', border: '1px solid var(--color-separatorOpaque)' 
        }}>
        <div style={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
          <div style={{ fontSize: '3rem', fontWeight: 'bold', margin: '0 0 4px 0', color: riskLevel.color, backgroundColor: riskLevel.colorLight, padding: '1rem', borderRadius: '.5rem', alignSelf: 'flex-start', width: "100%", textAlign: 'center' }}>
            {avgScore}
            <h2 style={{ fontSize: '1.2rem', fontWeight: 'normal', marginTop: '0.5rem' }}>
              Your Score
            </h2>
          </div>
          
          {/* <div style={{ color: 'var(--color-text3)', margin: 0 }}>
            Based on a 10 point scale, you would earn a {riskLevel.label === riskLevels.a.label ? 'n' : ''} {riskLevel.label}
          </div> */}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', }}>
          {rows.map((rowScores, rowIndex) => (
            <div key={rowIndex} style={{ display: 'grid', gridTemplateColumns: isMobileAndBelow ? '1fr' : '1fr 1fr', gap: '1.5rem' }}>
              {rowScores.map((item, index) => {
                const riskLevel = getRiskLevel(item.score/100);

                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'var(--color-backgroundSecondary)', padding: '1.5rem', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '4px', marginBottom: '4px', fontSize: '14px', fontWeight: '500',  }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', }}>
                        <div style={{ fontSize: '1rem', color: 'var(--color-text1)', width: '100%', fontWeight: '500' }}>
                          {item.title}
                        </div>
                        <span style={{ fontSize: '0.9rem', color: 'var(--color-text4)', marginTop: '4px' }}>
                        {item.weight}% weighted
                        </span>
                      </div>

                      <div style={{ backgroundColor: riskLevel.colorLight, color: riskLevel.color, padding: '.5rem', borderRadius: '.5rem', fontSize: '.9rem', fontWeight: "600" }}>
                        {item.score}%
                      </div>
                    </div>
                    
                    <div style={{ width: '100%', height: '8px', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem', position: 'relative', marginTop: '.5rem' }}>
                      <div style={{ width: `${(item.score / maxScore) * 100}%` , backgroundColor: 'black', height: '100%', borderRadius: '.5rem', transition: 'opacity 0.3s', position: 'absolute', bottom: 0 }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>

      <button style={{ padding: '.75rem 1.5rem', backgroundColor: 'var(--color-background)', color: 'var(--color-text3)', borderRadius: '.75rem', fontSize: '1rem', cursor: 'pointer', alignSelf: 'flex-start', marginTop: '1.5rem' }}
        onClick={() => setActiveTab(tabs.feedback)} 
        
        onMouseEnter={e => e.target.style.opacity = '0.7'}
        onMouseLeave={e => e.target.style.opacity = '1'}
      >
        Go to Feedback
        <ArrowRight size={16} color={'var(--color-text3)'} style={{ marginLeft: '4px' }} />
      </button>
    </div>
  );
};

export default Essay_Score;



{/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', gap: '1rem', alignItems: 'start' }}>
{responses.map((item, index) => {
  const riskLevel = getRiskLevel(item.grade/100);
  const isExpanded = expanded === index;

  return (
  <div style={{ display: 'flex', flexDirection: 'column', padding: '1.2rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)", borderRadius: '.5rem', cursor: 'pointer', transition: 'background-color 0.2s', height: 'fit-content' }}
    key={index}
    onClick={() => setExpanded((prev) => prev === index ? null : index)}
    onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
    onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pointerEvents: 'none' }}>
      <h3 style={{ fontSize: '1rem', fontWeight: 500, }}
        >{item.title}
      </h3>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <span style={{ fontSize: '.9rem', color: riskLevel.color, backgroundColor: riskLevel.colorLight, padding: '4px 8px', borderRadius: '4px' }}
          >{item.grade}%
        </span>
        <ChevronDown size={20} style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} color={'var(--color-text3)'} />
      </div>
    </div>
      <div style={{ 
        maxHeight: isExpanded ? '500px' : '0',
        opacity: isExpanded ? 1 : 0,
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        flexDirection: 'column', 
        gap: '.9rem',
        display: 'flex',
        marginTop: isExpanded ? '.7rem' : '0',
        pointerEvents: 'none',
      }}>
        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.3, }}
          ><span style={{ fontWeight: 600, textDecoration: 'underline' }}>Explanation:</span> {item.explanation}
        </p>
        <p style={{ fontSize: '1rem', color: 'var(--color-text3)', lineHeight: 1.3 }}
          ><span style={{ fontWeight: 600, textDecoration: 'underline' }}>Advice:</span> {item.actionableAdvice}
        </p>
      </div>
    </div>
  )
})}
</div> */}





// {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
// {scores.map((item, index) => {
//   const riskLevel = getRiskLevel(item.grade/100);
//   const scoreWidth = `${(item.grade / maxScore) * 100}%`;

//   return (
//     <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '16px', width: '100%' }}>
//       {!isMobileAndBelow && (
//         <div style={{ 
//           width: '128px', 
//           fontSize: '14px', 
//           color: 'var(--color-text3)', 
//           whiteSpace: 'nowrap', 
//           overflow: 'hidden', 
//           textOverflow: 'ellipsis' 
//         }}>
//           {item.title}
//         </div>
//       )}
      
//       <div style={{ position: 'relative', flexGrow: 1, height: '32px' }}>
//         {/* Background bar (grey for scores below 100) */}
//         <div style={{ 
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           backgroundColor: item.grade < 100 ? '#f3f4f6' : 'transparent',
//           borderRadius: '2px'
//         }} />
        
//         {/* Score bar */}
//         <div style={{
//           position: 'absolute',
//           height: '100%',
//           width: scoreWidth,
//           backgroundColor: 'black',
//           borderRadius: '2px',
//           transition: 'opacity 0.3s',
//           cursor: 'pointer'
//         }}
//           onMouseEnter={(e) => e.target.style.opacity = '0.8'}
//           onMouseLeave={(e) => e.target.style.opacity = '1'}
//         />
//       </div>

//       <div style={{ 
//         display: 'flex', 
//         alignItems: 'center', 
//         gap: '4px',
//         padding: '4px 8px',
//         borderRadius: '4px',
//         fontSize: '14px',
//         fontWeight: '500',
//         backgroundColor: riskLevel.colorLight,
//         color: riskLevel.color
//       }}>
//         {item.grade}
//         {riskLevel.label === riskLevels.a.label && (
//           <CheckCircle size={12} color={riskLevel.color} />
//         )}
//       </div>
//     </div>
//   );
// })}
// </div> */}