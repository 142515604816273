import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { GenerateComponent } from './Resource_Components';
import { API, RESOURCES_UPLOAD_METHODS, SOURCE_FILE_TYPES } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import { PAYWALL_TYPES } from '../misc/constants';
import { ArrowLeft, FileDown, ArrowDownToLine, Loader2 } from 'lucide-react';

const Button_Padding = "10px 15px"
const Button_Style = { padding: Button_Padding, cursor: 'pointer', borderRadius: '.75rem', display: 'inline-flex', alignItems: 'center', border: '1px solid var(--color-separatorOpaque)', backgroundColor: 'transparent', transition: 'all 0.3s', fontSize: '14px', fontWeight: '500', color: 'var(--color-backgroundTertiary)' }



const Resource_Notes = ({ isActive = true, data = {}, children }) => {
  const { } = useContext(UserContext)
  const { isPhoneAndBelow, isMobileAndBelow } = useBreakpoint()
  const [ content, setContent ] = useState('')

  ///////////////////////// GET DATA /////////////////////////
  useEffect(() => {
    if ( isActive ) {
      getData()
    }
  }, [data?.notes?.urlText, isActive])

  const getData = async () => {

    const resourceProcessingMessage = "Your notes are being processed. Please check back later!"

    if ( data?.notes?.urlText ) {
      try {
        let response = await axios.get(data.notes.urlText); 
        let text = response.data
        let cleanText = cleanTextCharacters(text)
        let html = convertNotesTextToHTML(cleanText)
        let fullHtml = convertNotesFullToHTML(data, html, )
        setContent(fullHtml); 
      } 
      catch (error) {
        console.error('Error fetching text content:', error);
        setContent(resourceProcessingMessage);
      }
    }
    else if ( data?.notes?.submitted || data?.uploadType === RESOURCES_UPLOAD_METHODS.recording || data?.sources?.[0]?.type === SOURCE_FILE_TYPES.audio ) {
      setContent(resourceProcessingMessage)
    }
  }

  const convertNotesTextToHTML = (text) => {
    const lines = text.trim().split('\n');
    let htmlContent = '';
    let listStack = [];
    let isFirstHeader = true;
  
    lines.forEach((line, index) => {
      // If the line is empty, skip it
      if (line.trim() === '') {
        return;
      }
  
      const currentLevel = countIndentationLevel(line);
      line = line.replace(/^\s*([*-])\s*/, '');
  
      if (currentLevel > 0) {
        line = line.replace(/\.$/, '');
        // Bullet point
        while (listStack.length > currentLevel) {
          htmlContent += '</li></ul>';
          listStack.pop();
        }
        if (listStack.length < currentLevel) {
          htmlContent += '<ul>';
          listStack.push(currentLevel);
        } else if (listStack.length > 0) {
          htmlContent += '</li>';
        }
        htmlContent += `<li style="margin-left: ${1 * currentLevel}rem;">${escapeHTML(line)}`;
      } else {
        // Header
        while (listStack.length > 0) {
          htmlContent += '</li></ul>';
          listStack.pop();
        }
        if (!isFirstHeader) {
          htmlContent += '<br>'; // Add line break before header, except for the first one
        }
        line = line.replace(/^#+\s*/, '');
        htmlContent += `<h3>${escapeHTML(line)}</h3>`;
        isFirstHeader = false;
      }
    });
  
    // Close any remaining open lists
    while (listStack.length > 0) {
      htmlContent += '</li></ul>';
      listStack.pop();
    }
  
    return htmlContent;
  };

  const convertNotesFullToHTML = (file, htmlContent, ) => {
    let effectiveFileName = formatFileName(file?.title);
    let dateObj = new Date(file?.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    // <div style="font-size: 14px; margin: 2px 0;">${userName}</div>
  
    const fullHTML = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${effectiveFileName}</title>
        <style>
          body { font-family: Arial, sans-serif; ; }
          h1 { font-weight: 600; font-size: 1.2rem; text-align: left; line-height: 1.5; }
          h3 { font-weight: 500; color: var(--color-text3);  font-size: 1rem; margin-bottom: 0px; margin-top: 10px; text-align: left; line-height: 1.3;  }
          ul { padding-left: 20px; margin-top: 5px;  }
          li { margin-bottom: 5px; font-size: 1rem; list-style-type: disc; text-align: left; line-height: 1.2; }
          .skip-link { position: absolute; top: -40px; left: 0; background: #000; color: white; padding: 8px; z-index: 100; }
          .skip-link:focus { top: 0; }
        </style>
      </head>
      <body>
      
        <header style="text-align: left; font-size: 16px; margin-bottom: 30px; margin-top: 0px; ">
        <p style="font-size: 1rem; margin-bottom: .5rem; color: var(--color-text4); text-transform: uppercase; font-weight: 500;"
          >${dateObj}
        </p>  
        <h1 style="font-size: 1.2rem; margin-bottom: 0px; margin-top: 0px; line-height: 1.5;"
          >${effectiveFileName}
        </h1>
          
        </header>

        <main id="main-content" role="document" aria-label="${effectiveFileName}">
          ${htmlContent}
        </main>
      </body>
    </html>
  `;
  
    return fullHTML;
  };

  function formatFileName(fileName) {
    return fileName.replace(/-[^-]*$/, '');
  }

  const countIndentationLevel = (line) => {
    const match = line.match(/^\s*([*-])/);
    return match ? 1 + Math.floor((match[0].length - 1) / 2) : 0;
  };

  const cleanTextCharacters = (text) => {
    return text
      // Fix common UTF-8 issues
      .replace(/â€œ/g, '"')      // matches full opening quote sequence
      .replace(/â€/g, '"')       // matches full closing quote sequence
      .replace(/â€˜/g, "'")      // matches full opening apostrophe sequence
      .replace(/â€™/g, "'")      // matches full closing apostrophe sequence
      .replace(/â€¢/g, '•')      // matches bullet
            
      // Handle scientific notation and subscripts
      .replace(/_(on|off|[A-Za-z])/g, '<sub>$1</sub>')  // Convert _X to subscript
      .replace(/\^([A-Za-z0-9+-]+)/g, '<sup>$1</sup>')  // Convert ^X to superscript
      
      // Normalize to closest ASCII equivalent
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      
      // Replace common Unicode with ASCII
      .replace(/[\u2013\u2014]/g, '-')   // en and em dashes
      .replace(/[\u2018\u2019]/g, "'")   // smart single quotes
      .replace(/[\u201C\u201D]/g, '"')   // smart double quotes
      .replace(/\u2022/g, '*')           // bullet to asterisk
      .replace(/\u2026/g, '...')         // ellipsis
    
      
      // Remove any remaining non-printable characters
      .replace(/[^\x20-\x7E\n\t]/g, '');
  };
  
  const escapeHTML = (str) => {
    return str
    // Don't escape already escaped entities
    .replace(/&(?!amp;|lt;|gt;|quot;|#039;)/g, '&amp;')
    // Don't escape <sub>, </sub>, <sup>, </sup> tags
    .replace(/<(?!\/?(?:sub|sup)>)/g, '&lt;')
    .replace(/(?<!<\/?(?:sub|sup))>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
  };

  if ( !isActive ) return null
  return (
    <div style={{ maxWidth: '910px', width: '100%', alignSelf: 'center', minHeight: '100%', position: 'relative' }}
      role="document"
      aria-label="Notes file content"
    >
      <div dangerouslySetInnerHTML={{ __html: content }} />
      {children}
    </div>
  );  
}


export default Resource_Notes;
