'use client';
import React, { useRef, useState, useContext, useEffect } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, COLOR_ARRAY, dbRefs, PAYWALL_TYPES,  } from '../misc/constants';
import { ArrowUpIcon, RotateCcw, Check, ArrowRight } from 'lucide-react';
import SolveMessage from '../components/SolveMessage';
import { useBreakpoint } from '../misc/useBreakpoint';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_EVENTS, OPENAI_CHAT_ROLES } from '../misc/constants';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getResourceTextUrl, getTextFromResource, processChatStreamResponse } from '../misc/utils';
import SchoolGoatBg from '../assets/SchoolGoatBg.png'
import axios from 'axios';

const ResourceTestScreen = ({  }) => {
  const { data } = useOutletContext()
  const { user, checkUserPermission } = useContext(UserContext);
  const { isPhoneAndBelow, } = useBreakpoint();  
  const navigate = useNavigate()
  const messagesEndRef = useRef(null);
  const [ chatInput, setChatInput ] = useState('');
  const [ chatMessages, setChatMessages ] = useState([]);
  const [ loadingResponse, setLoadingResponse ] = useState(false);
  const [ error, setError ] = useState(false);
  const buttonIsLoadingRef = useRef(false)

  useEffect(() => {
    setChatMessages([])
  }, [data?._id])

  const handleMessage = async (text = null) => {

    if ( buttonIsLoadingRef.current ) return
    buttonIsLoadingRef.current = true

    try {
      const newMessage = {
        role: OPENAI_CHAT_ROLES.user,
        content: text || chatInput
      };
  
      await handleSubmit(newMessage)
    }
    catch (error) {
      alert("Something went wrong. Please try again")
    }
    finally {
      buttonIsLoadingRef.current = false
    }
  }

  const handleSubmit = async (newMessage, ) => {   
    try {

      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.resource_test)

      if ( !hasPermission ) {
        setChatInput("")
        return
      }

      let updatedMessages = [...chatMessages, newMessage];
      let updatedMessagesWithLoader = [...updatedMessages, { role: OPENAI_CHAT_ROLES.assistant, content: '' }]
      setChatMessages(updatedMessagesWithLoader);
      setLoadingResponse(true);

      setChatInput("");

      let content_url = getResourceTextUrl(data)

      const response = await fetch(`${API}/streamTest2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resource_id: data._id, user_id: user?._id, messages: updatedMessages, content_url, useBasicModel: true, }),
      });
      const reader = response.body.getReader();  
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      
      await processChatStreamResponse({ reader, setChatMessages, handleError });
      await axios.put(`${API}/generalUpdateOne`, { 
        matchObj: { _id: user._id }, 
        updateObj: { $inc: { [`usage.${PAYWALL_TYPES.resource_test}`]: 1 } },
        dbRef: dbRefs.users
      });
      // mixpanel.track(MIXPANEL_EVENTS.solver, { user_id: user?._id, user_email: user?.email, })
    } 
    catch (error) {
      handleError();
    }
    finally {
      setLoadingResponse(false);
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const handleError = () => {
    setChatMessages((prev) => {
      const updatedMessages = [...prev];
      if (updatedMessages[updatedMessages.length - 1]?.role === OPENAI_CHAT_ROLES.assistant) {
        updatedMessages.pop();
      }
      return [...updatedMessages];
    });
    setError(true);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.preventDefault();
        setChatInput(prevInput => prevInput + '\n');
      } 
      else {
        if ( !loadingResponse && chatInput.trim() !== '') {
          e.preventDefault();
          handleMessage();
        }
      }
    }
  }


  const ErrorComponent = () => {
    if ( error ) {

      const handleErrorRetry = () => {
        setError(false);
        let updatedMessages = [...chatMessages];
        let lastUserMessage;

        if (updatedMessages[updatedMessages.length - 1].role === OPENAI_CHAT_ROLES.assistant) {
          updatedMessages.pop();
        }

        for (let i = updatedMessages.length - 1; i >= 0; i--) {
          if (updatedMessages[i].role === OPENAI_CHAT_ROLES.user) {
            lastUserMessage = updatedMessages[i];
            updatedMessages.splice(i, 1);
            break;
          }
        }

        setChatMessages(updatedMessages);

        if (lastUserMessage) {
          handleSubmit(lastUserMessage);
        }
      }


      return (
        <button style={{ position: "absolute", bottom: 10, left: "50%", transform: "translateX(-50%)", display: "flex", width: "15rem", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '1rem', margin: 0, background: 'none', border: 'none', cursor: 'pointer', color: 'var(--color-text3)', gap: '.5rem', backgroundColor: `${COLOR_ARRAY[1]}33`, borderRadius: '100px' }}
        onClick={handleErrorRetry}
      >
      <p style={{ fontSize: '1rem', color: COLOR_ARRAY[1] }}
        >Error, click to retry
      </p>
        <RotateCcw size={16} color={COLOR_ARRAY[1]}/>
      </button>
      )
    }
  }

  return (

    <div id="wrapper" style={{ width: "100%", margin: "0 auto" }}>
      
      { chatMessages.length == 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "95%", maxWidth: "400px",
// border: '1px solid var(--color-separatorOpaque)', borderRadius: '1rem', padding: '2rem', boxShadow: 'var(--borderShadow)', 

           }}>
            <img src={SchoolGoatBg} style={{ width: '100%', maxWidth: '150px', }} />
            <h1 style={{ fontSize: '1.5rem', color: 'var(--color-text1)', fontWeight: '600', marginTop: '1rem' }}
              >Interactive Test
            </h1>
            <p style={{ fontSize: '1rem', color: 'var(--color-text3)', textAlign: 'center', marginTop: '.75rem' }}
              >School Goat will test your knowledge of the material
            </p>

            {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1.5rem' }}>

              {[ 
                { title: "Interactive dialogue", description: "Questions are posed in a conversational manner", icon: <Check /> }, 
                { title: "Immediate feedback", description: "See what you know and what you don't", icon: <Check /> }, 
                { title: "Customizable", description: "You can tell School Goat to change the difficulty or focus on a certain topic", icon: <Check /> }, 
              ].map((item, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem', }}>
                  {React.cloneElement(item.icon, { size: 16, color: 'var(--color-text1)', width: "16px", style: { minWidth: "16px", minHeight: "16px" } })}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h3 style={{ fontSize: '1rem', color: 'var(--color-text1)', fontWeight: '600',  }}
                      >{item.title}
                    </h3>
                    <p style={{ fontSize: '0.9rem', color: 'var(--color-text3)', marginTop: '0.25rem', lineHeight: '1.2' }}
                    >{item.description}</p>
                  </div>
                </div>
              ))}
            </div> */}
            <button 
              style={{ padding: '1rem', width: '100%', fontSize: '1rem', backgroundColor: 'black', color: 'white', border: 'none', borderRadius: '100px', cursor: 'pointer', transition: 'transform 0.2s ease', transform: 'scale(1)', boxShadow: '0 4px 14px rgba(0,0,0,0.1)', marginTop: '1.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem' }}
              onMouseEnter={(e) => e.target.style.opacity = 0.7}
              onMouseLeave={(e) => e.target.style.opacity = 1}
              onClick={() => handleMessage('Start the test!')}
            >
              Begin
              <ArrowRight size={16} color="white" />
            </button>
          </div>
        </div>
      )}

      {chatMessages.length > 0 && 
        <>
      
          <div id="main" style={{ overflowY: 'auto',  }}>
            <div className="inner" style={{ maxWidth: '910px',  }}>
              
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  {chatMessages.map((message, index) => (
                    <SolveMessage key={index} item={message} index={index} isPhoneAndBelow={isPhoneAndBelow} isMember={user?.isMember} navigate={navigate} />
                  ))}
                  <div ref={messagesEndRef} />
                </div>
              <ErrorComponent />
            </div>
          </div>

          <div style={{ width: "100%", paddingBottom: "1rem", backgroundColor: 'var(--color-backgroundSecondary)', paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
            <div style={{ maxWidth: "800px", display: 'flex', alignItems: 'center', padding: ".5rem", backgroundColor: 'var(--color-background)', borderRadius: '30px',  margin: '0 auto', }}>
              <input style={{ width: '100%', color: 'var(--color-text1)', padding: '0 .6rem', paddingLeft: '1rem', border: 'none', outline: 'none', backgroundColor: 'transparent', fontSize: isPhoneAndBelow ? '16px' : '1rem' }}
                type="text"
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Message..."
              />
              <button style={{ backgroundColor: 'var(--color-text1)', color: 'white', borderRadius: '30px', cursor: 'pointer', border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: loadingResponse || chatInput.length == 0 ? 0.1 : 1, height: "40px", width: "40px" }}
                type="submit"
                onClick={handleMessage}
                disabled={loadingResponse || chatInput.length == 0}
              >
                <ArrowUpIcon size={ isPhoneAndBelow ? 16 : 24 } />
              </button>
            </div>
          </div>

        </>
      }

    </div>

  );
};

export default ResourceTestScreen;
