import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { UserContext } from '../contexts/UserProvider';
import { API, PAYWALL_TYPES, dbRefs } from '../misc/constants';
import axios from 'axios';
import ContentCreate from '../components/ContentCreate';
import Resource_Teach from '../components/Resource_Teach3';
import { Resource_Loader } from '../components/Resource_Loader';
import { v4 as uuidv4 } from 'uuid';

const TeachScreen = () => {
  const { user, checkUserPermission } = useContext(UserContext)
  const [ data, setData ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(false)
  
  const handleCreateContent = async ({ textInput }) => {      

    try {
      const { hasPermission } = await checkUserPermission(PAYWALL_TYPES.teach)
      if ( !hasPermission ) return

      setLoading(true)
      let result = await axios.post(`${API}/generateTeach2`, { text: textInput, });
      let results = result?.data?.content

      let teachDoc = {
        _id: uuidv4(),
        user_id: user._id,
        user_email: user.email,
        teach: results,
        date: new Date(),
        updatedAt: new Date(),
        text: textInput,
      }

      // insert teach
      await Promise.all([
        axios.post(`${API}/generalInsertOne`, { doc: teachDoc, dbRef: dbRefs.teach }),
        axios.put(`${API}/generalUpdateOne`, { 
          matchObj: { _id: user._id }, 
          updateObj: { $inc: { 'usage.teach': 1 } },
          dbRef: dbRefs.users
        })
      ]);
      setData(teachDoc)
    } 
    catch (error) {
      alert(error)
    }
    finally {
      setLoading(false)
    }
  }  

  const handleSave = async ({ messages, index }) => {
    try {
      await axios.put(`${API}/generalUpdateOne`, {
        matchObj: { _id: data?._id },
        updateObj: { $push: { [`teach.content.${index}.messages`]: { $each: messages } }},
        dbRef: dbRefs.teach,
      })

      setData(prev => ({
        ...prev,
        teach: {
          ...prev.teach,
          content: prev.teach.content.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                messages: [
                  ...item.messages,
                  ...messages
                ]
              }
            }
            return item
          })
        }
      }))
    }
    catch (error) {
      console.error('Error in handleSave:', error);
    }
  }

  const handleDemo = async () => {
    try {
      setLoading(true)
      let result = await axios.get(API + '/generalFindOne', { params: { 
        queryObj: JSON.stringify({ _id: "94a3b1b7-292b-4c48-a4ff-031212b97e11" }), 
        dbRef: dbRefs.teach 
      }});

      if ( result?.data ) {
        setData(result?.data)
      }
      else {
        alert('Demo is currently unavailable')
      }
    }
    catch (error) {
      console.error('Error in handleDemo:', error);
      alert('Demo is currently unavailable')
    }
    finally {
      setLoading(false)
    }
  }

  const handleBack = () => {
    setData({})
  }


  if ( !loading && !data?._id ) return (
    <div id="wrapper">        
      <div id="main">
        <div className="inner">
          <ContentCreate
            onSubmit={handleCreateContent}
            isActive={!data?._id} 
            header="Explain **this** to me"
            placeholder="e.g. How quantum entanglement works..."
            handleDemo={handleDemo}
          />
        </div>
      </div>
    </div>
  )
  else if ( loading ) return (
    <div id="wrapper" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', backgroundColor: 'var(--color-backgroundSecondary)' }}>   
    <Resource_Loader isActive={loading} text="Generating tutorial" subText="This may take ~10 seconds" />
    </div>
  )
  else return (
    <div id="wrapper" style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto', backgroundColor: 'var(--color-backgroundSecondary)' }}>   
      <Resource_Teach isActive={!loading} data={data} handleSave={handleSave} handleBack={handleBack} />
    </div>
  )
}


export default TeachScreen;




