'use client';
import { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { createCheckoutSession_v3, logPaywallAction, sendSlackNotification } from '../misc/utils';
import { SLACK_WEBHOOK_CHANNELS, API, PAYWALL_EVENTS, dbRefs, stripeSubscriptionStates } from '../misc/constants';
import { ArrowRight, Check, CheckCircle2, Circle, Sparkles } from 'lucide-react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { useBreakpoint } from '../misc/useBreakpoint';
import Member1 from '../assets/paywall0.webp';
import Member2 from '../assets/paywall1.webp';
import Member3 from '../assets/paywall2.webp';
import Chat from '../components/Chat';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const frequency = { weekly: 'Weekly', monthly: 'Monthly', annually: 'Annually', }
const tabs = { plans: "plans", checkout: "checkout" }


const features = [
  'Access to ALL features',
  'File downloads enabled',
  "Higher quality outputs",
  "Priority support",
]

const plans = [
  {
    // _id: 'price_1OkUkYLkURBDGvXaSMduncUg',
    _id: "price_1QRFwVLkURBDGvXa2BfNPl1j",  // live
    title: 'Maximum Flexibility',
    basePrice: 9.99,
    frequency: frequency.monthly,
    interval: 'mo, billed monthly',
    tag: "Most Popular"
  },
  {
    // _id: 'price_1OkUrmLkURBDGvXa8hLFAVZZ',
    _id: "price_1QSXc1LkURBDGvXaAjCE37zW",  // live
    title: 'Maximum Savings', 
    basePrice: 8.25,
    frequency: frequency.annually,
    interval: 'mo, billed annually',
    tag: "Save 20%"
  },

]


const CheckoutModalStripe = ({  }) => {
  const { user, setUser, showCheckoutModal, setShowCheckoutModal, setShowChatModal } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const buttonInProgressRef = useRef(false);

  useEffect(() => {
    if ( showCheckoutModal ) {
      logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_SHOWN, });
    }
  }, [showCheckoutModal]);

  const handlePlanClick = async ( ) => {
    if (buttonInProgressRef.current) return;
    buttonInProgressRef.current = true;
    
    try {
      await logPaywallAction({ user, eventType: PAYWALL_EVENTS.PLANS_CHECKOUT, type: selectedPlan.title, });
      await createCheckoutSession_v3({ 
        priceId: selectedPlan._id, 
        email: user.email,
      });
    } 
    catch (error) {
      console.error('Error creating checkout session:', error);
    }
    finally {
      buttonInProgressRef.current = false;
    }
  }

  const handleClose = () => {
    setShowCheckoutModal(false);
    setSelectedPlan(plans[0]);
  }

  return (
      <Drawer.Root open={showCheckoutModal} onOpenChange={() => {}} dismissible={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000 }} 
          onClick={handleClose}
        />
        <Drawer.Content style={{ display: "flex", flexDirection: "column", position: "fixed", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", zIndex: 1001, backgroundColor: "var(--color-backgroundSecondary)",
          ...(isPhoneAndBelow && { bottom: 0, left: 0, right: 0 }),
          ...(!isPhoneAndBelow && { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '500px', margin: '0 auto', maxHeight: '90vh', borderRadius: '10px', overflow: 'hidden', animation: "none", })
         }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ width: '100%', margin: '0 auto', overflowY: 'auto', padding: isPhoneAndBelow ? '1.5rem' : '2rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '1.5rem',  }}>

            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', fontSize: '1.8rem' }}
              id="drawer-title"
              >Get School Goat PLUS
            </Drawer.Title>
            <Drawer.Description style={{ lineHeight: '1.4', marginTop: '0.5rem', color: 'var(--color-text3)', fontSize: '16px' }}
              id="drawer-description"
              // >Upgrade to get access to all features
              >As a Plus member, you get:
            </Drawer.Description>

            <div style={{ marginTop: '1.25rem' }}>
              {features.map((feature, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '.8rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '18px', height: '18px', borderRadius: '50%', backgroundColor: 'var(--color-text2)', marginRight: '0.75rem' }}>
                    <svg width="11" height="11" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 6L9 17L4 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                  {/* <Check style={{ width: '1.2rem', height: '1.2rem', color: 'var(--color-primary)', marginRight: '0.5rem' }} /> */}
                  <span style={{ color: 'var(--color-text2)', fontSize: '1rem' }}
                    >{feature}
                  </span>
                </div>
              ))}
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', gap: '.75rem', marginTop: '1.25rem' }}>
              {plans.map((plan, index) => {
                const isSelected = plan.title === selectedPlan.title;

                return (
                  <div key={index} style={{ display: "flex", alignItems: "center", flex: 1, border: '1px solid var(--color-separatorOpaque)', borderRadius: '0.5rem', padding: '1.5rem', cursor: 'pointer', position: 'relative', boxShadow: 'var(--borderShadow)', transition: 'all 200ms ease-in-out',
                  ...(isSelected ? { backgroundColor: '#EEE7FE', borderColor: 'var(--color-primary)' } : { borderColor: 'var(--color-separatorOpaque)' }),
                  ...(isPhoneAndBelow && { padding: '1.5rem', })
                  }}
                    onClick={() => setSelectedPlan(plan)}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.65rem' }}>
                      <div style={{ fontSize: '1.1rem', fontWeight: 600, color: 'var(--color-text1)' }}
                        >{plan.title}
                      </div>
                      <div style={{ fontSize: '1rem', color: 'var(--color-text3)' }}
                        >${plan.basePrice}/{plan.interval}
                      </div>
                    </div>

                    {plan.tag && (
                      <div style={{ position: 'absolute', top: '.5rem', right: '.5rem', fontSize: '0.8rem', color: 'var(--color-primary)', backgroundColor: '#EEE7FE', padding: '0.35rem 0.5rem', borderRadius: '0.25rem', fontWeight: 500 }}
                        >{plan.tag}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>

            <button style={{ backgroundColor: 'var(--color-text1)', color: 'var(--color-white)', borderRadius: '0.5rem', marginTop: '1.25rem', width: '100%', fontWeight: 500, fontSize: '1.1rem', padding: '1rem', border: 'none', fontFamily: 'inherit', cursor: 'pointer' }}
              onClick={() => handlePlanClick()}
              >Continue
              <ArrowRight style={{ width: '1rem', height: '1rem', color: 'var(--color-white)', marginLeft: '0.5rem' }} />
            </button>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.75rem', }}>
              <div style={{ display: 'flex', marginRight: '.5rem' }}>
                {[Member1,Member2,Member3].map((user, index) => (
                  <img style={{ width: '22px', height: '22px', borderRadius: '50%', border: '1px solid white', marginLeft: index > 0 ? '-8px' : '0', backgroundColor: '#f4f4f4' }}
                    key={index}
                    src={user}
                    alt={`User ${index + 1}`}
                  />
                ))}
              </div>
              <span style={{color: 'var(--color-text3)', fontSize: '.9rem', fontWeight: '400' }}>
                Join 10,000+ students using School GOAT
              </span>
            </div>
          </div>

        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default CheckoutModalStripe; 



