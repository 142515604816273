import React, { useContext, useEffect, useState, useCallback } from 'react';
import { API, dbRefs, MIXPANEL_EVENTS, } from '../misc/constants';
import axios from 'axios';
import { UserContext } from '../contexts/UserProvider';
import { Check, CheckCircle, Circle, Upload, X, RotateCcw } from 'lucide-react';
import { COLOR_ARRAY } from '../misc/constants';
import { useBreakpoint } from '../misc/useBreakpoint';
import mixpanel from 'mixpanel-browser';

const colors = {
  error: {
    light: `${COLOR_ARRAY[1]}33`,
    dark: COLOR_ARRAY[1],
  },
  success: {
    light: `${COLOR_ARRAY[2]}33`,
    dark: `${COLOR_ARRAY[2]}`,
  }
}

const buttonStyle = { padding: '1rem', backgroundColor: 'var(--color-text1)', borderRadius: '.75rem', cursor: 'pointer', fontSize: '1rem', fontWeight: 400, color: 'var(--color-textButton)', border: '1px solid var(--color-separatorOpaque)', width: "8rem" }

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};


const Answers = ({ item,  }) => { 
  const [ selectedAnswers, setSelectedAnswers ] = useState([])
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  useEffect(() => {
    setShuffledAnswers(shuffleArray(item?.answers || []));
    setSelectedAnswers([])
  }, [item]);

  const handleAnswerSelect = (index) => {
    setSelectedAnswers([...selectedAnswers, index])
  }

  return (
    <ul style={{ listStyle: 'none', padding: 0, margin: 0, width: "100%" }}>
      {shuffledAnswers.map((answer, index) => {

        const selected = selectedAnswers.includes(index)
        const correct = answer.isCorrect
        const darkColor = !selected ? 'var(--color-text3)' : correct ? colors.success.dark : colors.error.dark
        const lightColor = !selected ? 'var(--color-text3)' : correct ? colors.success.light : colors.error.light

        return (
          <li key={index} style={{ marginBottom: '.3rem', width: "100%" }}>
            <button style={{ width: '100%', textAlign: 'left', padding: '1rem', borderRadius: '.5rem', backgroundColor: selected ? lightColor : 'transparent', border: "none", cursor: 'pointer', fontSize: '1rem', display: 'flex', position: 'relative', color: selected ? darkColor : 'var(--color-text3)', gap: '1rem', alignItems: 'center', lineHeight: '1.5', justifyContent: 'flex-start' }}
              onClick={() => handleAnswerSelect(index)}
            >
              {selected ? (
                correct ? (
                  <CheckCircle size={20} style={{ color: colors.success.dark, flexShrink: 0 }} />
                ) : (
                  <X size={20} style={{ color: colors.error.dark, flexShrink: 0 }} />
                )
              ) : (
                <Circle size={20} style={{ color: 'var(--color-text3)', flexShrink: 0 }} strokeWidth={1.5} />
              )}
              {answer.text}
            </button>
          </li>
        )
      })}
    </ul>
  );
};


const Resource_Quiz = ({ isActive = true, data = {}, }) => {
  const { user,  } = useContext(UserContext)
  const [ content, setContent ] = useState([])
  const { isPhoneAndBelow } = useBreakpoint()
  const [ selectedQuestionIndex, setSelectedQuestionIndex ] = useState(0)

  useEffect(() => {
    if ( isActive && data?.quiz?.content ) {
      setContent(data?.quiz?.content)
      setSelectedQuestionIndex(0)
    }
  }, [data, isActive])

  const onNextQuestion = () => {
    if (selectedQuestionIndex < content.length - 1) {
      setSelectedQuestionIndex((prevIndex) => (prevIndex + 1))
    }
    else {
      setSelectedQuestionIndex(0)
    }

    mixpanel.track(MIXPANEL_EVENTS.quiz_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  }

  const onPreviousQuestion = () => {
    if (selectedQuestionIndex > 0) {
      setSelectedQuestionIndex((prevIndex) => (prevIndex - 1))
    }
    else {
      setSelectedQuestionIndex(content.length - 1)
    }
    
    mixpanel.track(MIXPANEL_EVENTS.quiz_next, {
      user_id: user?._id,
      user_email: user?.email,
    });
  }

  if ( !isActive ) return null
  if ( data === null ) return null
  return (
    <div style={{ textAlign: "left", alignItems: "flex-start", 
      padding: isPhoneAndBelow ? '1.5rem' : '2rem', backgroundColor: 'var(--color-backgroundSecondary)', borderRadius: '1rem', border: '1px solid var(--color-separatorOpaque)', boxShadow: "var(--borderShadow)",
      ...isPhoneAndBelow && { padding: '1.5rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', }
     }}>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
        <span style={{ fontSize: '.9rem', color: 'var(--color-text3)' }}>Question {selectedQuestionIndex + 1} of { content?.length || 0 }</span>
      </div>

      <h2 style={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: '1rem', lineHeight: '1.5' }}
        >{content[selectedQuestionIndex]?.question}
      </h2>

      <Answers item={content[selectedQuestionIndex]} index={selectedQuestionIndex} />

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '.5rem', marginTop: "auto", width: "100%",  }}>
        <button style={{...buttonStyle, backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text3)'}} 
          onClick={() => onPreviousQuestion()}
          >Previous
        </button>
        <button style={{...buttonStyle, backgroundColor: 'var(--color-backgroundSecondary)', border: '1px solid var(--color-separatorOpaque)', color: 'var(--color-text3)'}} 
          onClick={() => onNextQuestion()}
          >Next
        </button>
      </div>
    </div>

    )
}

export default Resource_Quiz;



// const sampleQuizData = [
//   {
//     question: "Which scientific principle states that the entropy of an isolated system always increases over time?",
//     answers: [
//       { text: "First Law of Thermodynamics", isCorrect: false },
//       { text: "Second Law of Thermodynamics", isCorrect: true },
//       { text: "Law of Conservation of Energy", isCorrect: false },
//       { text: "Newton's Third Law", isCorrect: false }
//     ]
//   },
//   {
//     question: "Who is associated with the concept of the Categorical Imperative in moral philosophy?",
//     answers: [
//       { text: "Jean-Paul Sartre", isCorrect: false },
//       { text: "Friedrich Nietzsche", isCorrect: false },
//       { text: "Immanuel Kant", isCorrect: true },
//       { text: "John Locke", isCorrect: false }
//     ]
//   },
//   {
//     question: "Which psychological phenomenon describes the mental discomfort experienced when holding contradictory beliefs?",
//     answers: [
//       { text: "Cognitive Dissonance", isCorrect: true },
//       { text: "Classical Conditioning", isCorrect: false },
//       { text: "Confirmation Bias", isCorrect: false },
//       { text: "Operant Conditioning", isCorrect: false }
//     ]
//   },
//   {
//     question: "What astronomical phenomenon is used to determine that the universe is expanding?",
//     answers: [
//       { text: "Blue Shift", isCorrect: false },
//       { text: "Red Shift", isCorrect: true },
//       { text: "Solar Parallax", isCorrect: false },
//       { text: "Stellar Aberration", isCorrect: false }
//     ]
//   },
//   {
//     question: "Which artistic movement is characterized by small, visible brushstrokes and emphasis on light's changing qualities?",
//     answers: [
//       { text: "Cubism", isCorrect: false },
//       { text: "Surrealism", isCorrect: false },
//       { text: "Impressionism", isCorrect: true },
//       { text: "Abstract Expressionism", isCorrect: false }
//     ]
//   },
//   {
//     question: "In economics, what term describes the value of the next-best alternative foregone when making a choice?",
//     answers: [
//       { text: "Sunk Cost", isCorrect: false },
//       { text: "Opportunity Cost", isCorrect: true },
//       { text: "Marginal Cost", isCorrect: false },
//       { text: "Variable Cost", isCorrect: false }
//     ]
//   },
//   {
//     question: "What literary technique presents a character's thoughts and feelings as they occur, often without logical order?",
//     answers: [
//       { text: "Stream of Consciousness", isCorrect: true },
//       { text: "Magical Realism", isCorrect: false },
//       { text: "Dramatic Monologue", isCorrect: false },
//       { text: "Free Verse", isCorrect: false }
//     ]
//   },
//   {
//     question: "Which organelle is known as the 'powerhouse of the cell' because it generates ATP?",
//     answers: [
//       { text: "Golgi Apparatus", isCorrect: false },
//       { text: "Endoplasmic Reticulum", isCorrect: false },
//       { text: "Mitochondria", isCorrect: true },
//       { text: "Lysosome", isCorrect: false }
//     ]
//   },
//   {
//     question: "What period of geopolitical tension existed between the United States and Soviet Union from 1947-1991?",
//     answers: [
//       { text: "World War II", isCorrect: false },
//       { text: "The Great Depression", isCorrect: false },
//       { text: "The Cold War", isCorrect: true },
//       { text: "The Space Race", isCorrect: false }
//     ]
//   },
//   {
//     question: "In music theory, what term describes the relationship between multiple musical lines that are harmonically interdependent but independent in rhythm?",
//     answers: [
//       { text: "Harmony", isCorrect: false },
//       { text: "Counterpoint", isCorrect: true },
//       { text: "Polyphony", isCorrect: false },
//       { text: "Sonata Form", isCorrect: false }
//     ]
//   }
// ];