'use client';
import React, { useContext, useState, useEffect } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { RESOURCES_UPLOAD_METHODS } from '../misc/constants';
import { FileText, Image, Text, X, Youtube } from 'lucide-react';
import { useBreakpoint } from '../misc/useBreakpoint';
import Member1 from '../assets/paywall0.webp';
import Member2 from '../assets/paywall1.webp';
import Member3 from '../assets/paywall2.webp';
import Uploader_Text from '../components/Uploader_Text';
import Uploader_File from '../components/Uploader_File';
import Uploader_Image from '../components/Uploader_Image';

const subTabs = {
  file: { _id: RESOURCES_UPLOAD_METHODS.file, title: 'File', icon: <FileText />, },
  text: { _id: RESOURCES_UPLOAD_METHODS.text, title: 'Text', icon: <Text />, },
  // image: { _id: RESOURCES_UPLOAD_METHODS.image, title: 'Image', icon: <Image />, },
  // youtube: { _id: RESOURCES_UPLOAD_METHODS.youtube, title: 'Youtube', icon: <Youtube />, },
}


const UploaderModal = ({ open, setOpen, setOpenResourceProcessingModal }) => {
  const { user,  } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();
  const [ activeSubTab, setActiveSubTab ] = useState(subTabs.file.title);


  const handleClose = () => {
    setOpen(false);
  }

  return (
      <Drawer.Root open={open} onOpenChange={() => {}} dismissible={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000 }} 
          onClick={handleClose}
        />
        <Drawer.Content style={{ display: "flex", flexDirection: "column", position: "fixed", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", zIndex: 1001, backgroundColor: "var(--color-backgroundSecondary)",
          ...(isPhoneAndBelow && { bottom: 0, left: 0, right: 0 }),
          ...(!isPhoneAndBelow && { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '800px', margin: '0 auto', maxHeight: '90vh', borderRadius: '10px', overflow: 'hidden', animation: "none", })
         }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ width: '100%', margin: '0 auto', overflowY: 'auto', padding: isPhoneAndBelow ? '1.5rem' : '2rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '1.5rem',  }}>

          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', marginBottom: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem', fontSize: '.75rem', fontWeight: '500', padding: '.5rem', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.35rem', textTransform: 'uppercase' }}>
                Class Materials
              </div>

              <button style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', backgroundColor: 'transparent', cursor: 'pointer', }}
                onClick={handleClose}
                aria-label="Close modal"
                onMouseEnter={(e) => e.currentTarget.style.opacity = '0.7'}
                onMouseLeave={(e) => e.currentTarget.style.opacity = '1'}
              >
                <X size={20} color="var(--color-text3)" />
              </button>
            </div>

            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', fontSize: '1.5rem' }}
              id="drawer-title"
              >Upload
            </Drawer.Title>
            <Drawer.Description style={{ lineHeight: '1.4', marginTop: '0.5rem', color: 'var(--color-text3)', fontSize: '16px' }}
              id="drawer-description"
              // >Upgrade to get access to all features
              >Notes, files, powerpoints, recorded lectures, etc.
            </Drawer.Description>

            <div style={{ display: 'flex', gap: '.25rem', marginTop: '1rem', border: '1px solid var(--color-separatorOpaque)', borderBottom: 'none', borderTopLeftRadius: '.5rem', borderTopRightRadius: '.5rem', padding: '.5rem' }}>
                { Object.values(subTabs).map(tab => {
                  const isActive = tab.title === activeSubTab;
                  return (                    
                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', background: 'none', fontSize: '1rem', color: 'var(--color-text3)', padding: '8px 12px', cursor: 'pointer', transition: 'all 0.2s', borderRadius: '.5rem', border: '1px solid transparent', 
                      ...(isActive && { 
                        color: 'var(--color-text1)', backgroundColor: 'var(--color-backgroundTertiary)', borderRadius: '.5rem'
                        // color: 'var(--color-text1)', border: '1px solid var(--color-separatorOpaque)', boxShadow: 'var(--borderShadow)'
                      
                      })
                    }}
                      key={tab.title}
                      onClick={() => setActiveSubTab(tab.title)}
                      >
                        {React.cloneElement(tab.icon, { size: 16, color: isActive ? 'var(--color-text1)' : 'var(--color-text3)', style: { marginRight: '6px' } })}
                        <span>{tab.title}</span>
                    </button>
                  )
                })}
              </div>

              { activeSubTab === subTabs?.file?.title && (
                <Uploader_File setOpen={setOpen} setOpenResourceProcessingModal={setOpenResourceProcessingModal} />
              )}

              { activeSubTab === subTabs?.text?.title && (
                <Uploader_Text setOpen={setOpen} />
              )}

              { activeSubTab === subTabs?.image?.title && (
                <Uploader_Image setOpen={setOpen} />
              )}




            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.75rem', }}>
              <div style={{ display: 'flex', marginRight: '.5rem' }}>
                {[Member1,Member2,Member3].map((user, index) => (
                  <img style={{ width: '22px', height: '22px', borderRadius: '50%', border: '1px solid white', marginLeft: index > 0 ? '-8px' : '0', backgroundColor: '#f4f4f4' }}
                    key={index}
                    src={user}
                    alt={`User ${index + 1}`}
                  />
                ))}
              </div>
              <span style={{color: 'var(--color-text3)', fontSize: '.9rem', fontWeight: '400' }}>
                10,000+ students using School GOAT
              </span>
            </div>
          </div>

        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default UploaderModal; 

