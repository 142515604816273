import './css/App.css';
import './css/Drawer.css';
import React from 'react';
import { Routes } from './navigation/routes';
import { UserProvider } from './contexts/UserProvider';
import ErrorScreen from './screens/ErrorScreen';
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom";
import ProtectedRoute from './navigation/ProtectedRoute';
import TermsScreen from './screens/TermsScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicy';
import { ThemeProvider } from '@mui/material';
import theme from './contexts/ThemeProvider';
import ReactGA from "react-ga4";
import LandingScreen from './screens/LandingScreen4';
import SettingsScreen from './screens/SettingsScreen';
import TermsPartnerProgramScreen from './screens/TermsPartnerProgramScreen';
import NotesFeedbackScreen from './screens/NotesFeedbackScreen';
import SolveScreen from './screens/SolveScreen2';
import EssayEvaluationScreen from './screens/EssayEvaluationScreen';
import RefundScreen from './screens/RefundScreen';

// import JoinScreen from './screens/JoinScreen3';
import JoinScreen from './screens/JoinScreenPaddle';
import AIDetectionScreen from './screens/AIDetectionScreen';
import HomeScreen from './screens/HomeScreen4';
import HomeRouter from './navigation/HomeRouter3';
import CheckoutCompleteScreen from './screens/CheckoutCompleteScreen';
import ResourceRouter from './navigation/ResourceRouter2';
import ResourceFlashcardsScreen from './screens/ResourceFlashcardsScreen';
import ResourceQuizScreen from './screens/ResourceQuizScreen';

import ResourceTeachScreen from './screens/ResourceTeachScreen';
import ResourceNotesScreen from './screens/ResourceNotesScreen';

import TeachScreen from './screens/TeachScreen';
import ResourceSourceScreen from './screens/ResourceSourceScreen';
import ResourceSummaryScreen from './screens/ResourceSummaryScreen2';
import ResourceTestScreen from './screens/ResourceTestScreen';
import ResourcesScreen from './screens/ResourcesScreen';
import ResourceChatScreen from './screens/ResourceChatScreen';
// import StudyScreen from './zArchive/StudyScreen';
// import WritingScreen from './screens/WritingScreen';
// import EssayScreen from './screens/EssayScreen';
// import ResourcesProfileScreen from './screens/ResourceNotesScreen';
// import ReferralsScreen from './screens/ReferralsScreen';
// import CheckoutScreen from './screens/CheckoutScreen';
// import CheckoutModalStripe from './modals/CheckoutModalStripe2';

ReactGA.initialize("G-54F08M4G3Z");

const App = () => {

  const router = createBrowserRouter([

    { path: '/', element: <LandingScreen />, errorElement: <ErrorScreen /> },
    
    {
      path: Routes.APP, 
      element: <ProtectedRoute />,
      errorElement: <ErrorScreen />,
      children: [
        { index: true, element: <Navigate to={Routes.HOME} replace /> },
        { element: <HomeRouter />, children: [          
          { path: Routes.HOME, element: <HomeScreen /> },
          { path: Routes.SOLVE, element: <SolveScreen /> },
          { path: Routes.SETTINGS, element: <SettingsScreen /> },
          { path: Routes.ESSAY_EVALUATION, element: <EssayEvaluationScreen /> },
          { path: Routes.AI_DETECTION, element: <AIDetectionScreen /> },      
          { path: Routes.TEACH, element: <TeachScreen /> },
          // { path: Routes.WRITING, element: <WritingScreen /> },
          // { path: Routes.ESSAY, element: <EssayScreen /> },
          // { path: Routes.STUDY, element: <StudyScreen /> },
          // { path: 'stripe', element: <CheckoutModalStripe />},

          { path: Routes.JOIN, element: <JoinScreen /> },

          { path: Routes.RESOURCES, element: <ResourcesScreen /> },

          { path: 'resources/:resource_id', element: <ResourceRouter />, children: [          
            { path: Routes.SOURCE, element: <ResourceSourceScreen/> },
            { path: Routes.SUMMARY, element: <ResourceSummaryScreen /> },
            { path: Routes.TEACH, element: <ResourceTeachScreen /> },
            { path: Routes.NOTES, element: <ResourceNotesScreen /> },
            { path: Routes.QUIZ, element: <ResourceQuizScreen /> },
            { path: Routes.FLASHCARDS, element: <ResourceFlashcardsScreen /> },
            { path: Routes.TEST, element: <ResourceTestScreen /> },
            { path: Routes.CHAT, element: <ResourceChatScreen /> },
          ]},
          


        ]},




        
        
        
        { path: Routes.CHECKOUT_COMPLETE, element: <CheckoutCompleteScreen /> },

        // { path: Routes.REFERRALS, element: <ReferralsScreen /> },
        // { index: true, element: <Navigate to={Routes.HOME} replace /> },
        // { path: Routes.ESSAY_OUTLINE, element: <EssayOutlineScreen /> },
        // { path: Routes.SHORTCUT_READING, element: <ShortcutReadingScreen /> },
        // { path: Routes.HOME, element: <AppHomeScreen />  },
        // { path: Routes.NOTES_IN_CLASS, element: <NotesInClassScreen /> },
        // { path: Routes.UPLOAD_FILES, element: <NotesConvertScreen /> },
        // { path: Routes.FLASHCARDS, element: <FlashcardsScreen /> },
        // { path: Routes.QUIZ, element: <QuizScreen /> },
        // { path: Routes.MEMBERSHIP, element: <MembershipScreen /> },
        // { path: Routes.TEACH, element: <TeachScreen /> },

      ]
    },
    { path: Routes.PRIVACY, element: <PrivacyPolicyScreen /> },
    { path: Routes.TERMS, element: <TermsScreen /> },
    { path: Routes.REFUND, element: <RefundScreen /> },
    { path: Routes.TERMS_PARTNER_PROGRAM, element: <TermsPartnerProgramScreen /> },
    { path: Routes.NOTES_FEEDBACK, element: <NotesFeedbackScreen /> },
    { path: '*', element: <Navigate to="/" replace /> }

    // { path: 'home', element: <AppHomeScreen /> },
    // { path: Routes.CHECKOUT, element: <CheckoutScreen /> },
    // { path: '/emailtest', element: <EmailTest />, errorElement: <ErrorScreen /> },    

  ]);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;




    // {
    //   path: '/writeassist', 
    //   element: <JennyScreen />,
    //   errorElement: <ErrorScreen />,
    // },
    // {
    //   path: '/maninfinance', 
    //   element: <DataScreen />,
    //   errorElement: <ErrorScreen />,
    // },