import React, { useContext, useEffect, useState } from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { Check, Info, X } from "lucide-react";
import { useNavigate, Navigate } from "react-router-dom";
import { API, dbRefs, SEARCH_PARAMS } from "../misc/constants";
import { Routes } from "../navigation/routes";
import { UserContext } from "../contexts/UserProvider";
import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const returnToCheckoutText = "Return to checkout";

const STATUS_CONTENT_MAP = {
  succeeded: {
    _id: "succeeded",
    text: "Payment succeeded",
    description: `Your payment was successful. Welcome to School Goat!`,
    buttonText: "Return to site",
    iconColor: "#000000",
    icon: <Check />,
  },
  processing: {
    _id: "processing",
    text: "Payment processing",
    description: "Your payment is being processed. Please wait while we verify your payment details. You can refresh the page to check the status of your payment.",
    buttonText: "Return to site",
    iconColor: "#6D6E78",
    icon: <Info />,
  },
  requires_payment_method: {
    _id: "requires_payment_method",
    text: "Payment method required",
    description: "Your payment requires a payment method. Please try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#DF1B41",
    icon: <X />,
  },
  requires_action: {
    _id: "requires_action",
    text: "Action required",
    description: "Your payment requires action. Please take action on your payment as prompted. If nothing appears, please return to checkout and try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#6D6E78",
    icon: <Info />,
  },
  requires_confirmation: {
    _id: "requires_confirmation",
    text: "Payment confirmation required",
    description: "Please confirm your payment as prompted. If nothing appears, please return to checkout and try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#6D6E78",
    icon: <Info />,
  },
  canceled: {
    _id: "canceled",
    text: "Payment canceled",
    description: "Your payment was canceled. Please try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#DF1B41",
    icon: <X />,
  },
  failed: {
    _id: "failed",
    text: "Payment failed",
    description: "Your payment was not successful. Please try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#DF1B41",
    icon: <X />,
  },
  default: {
    _id: "default",
    text: "Something went wrong",
    description: "An error occurred while processing your payment. Please try again.",
    buttonText: returnToCheckoutText,
    iconColor: "#DF1B41",
    icon: <X />,
  }
};

const PaymentStatusContent = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const { user, setUser } = useContext(UserContext);
  const [status, setStatus] = useState(STATUS_CONTENT_MAP.processing._id);
  const [intentId, setIntentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPaymentStatus = async () => {
      try {
        if (!stripe) return;

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
          console.error("No client secret found in URL");
          setStatus(STATUS_CONTENT_MAP.default._id);
          return;
        }

        const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);
        
        if (error) {
          console.error("Error retrieving payment intent:", error);
          setStatus(STATUS_CONTENT_MAP.default._id);
          return;
        }

        if (!paymentIntent) {
          console.error("No payment intent found");
          setStatus(STATUS_CONTENT_MAP.default._id);
          return;
        }

        let stripeStatus = paymentIntent.status;
        let statusObj = STATUS_CONTENT_MAP?.[stripeStatus];

        if (statusObj) {
          setStatus(stripeStatus);
        } else {
          console.error("Unknown payment status:", stripeStatus);
          setStatus(STATUS_CONTENT_MAP.default._id);
        }
        
        setIntentId(paymentIntent.id);

      } catch (err) {
        console.error("Error processing payment status:", err);
        setStatus(STATUS_CONTENT_MAP.default._id);
      } finally {
        setIsLoading(false);
      }
    };

    getPaymentStatus();
  }, [stripe]);

  const handleButtonClick = async () => {
    const statusObj = STATUS_CONTENT_MAP[status];

    if (status === STATUS_CONTENT_MAP.succeeded._id) {
      setIsLoading("Updating your account...");
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      try {
        let result = await axios.get(`${API}/generalFindOne`, { 
          params: { 
            queryObj: JSON.stringify({ _id: user._id }),
            dbRef: dbRefs.users
          }
        });
        let results = result.data;
        setUser({ ...results, isMember: true });
        
        navigate(`/app/${Routes.NOTES}`);
      } catch (err) {
        console.error(err);
        alert("An error occurred while updating your account. Please try again.");
      } finally {
        setIsLoading(false);
      }
    } else if (statusObj.buttonText === returnToCheckoutText) {
      navigate({
        pathname: `/app/${Routes.NOTES}`,
        search: `?${SEARCH_PARAMS.checkout}=true`
      });
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
      {isLoading ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
          <div style={{ width: '50px', height: '50px', border: '3px solid var(--color-text1)', borderTop: '3px solid var(--color-textButton)', borderRadius: '50%', animation: 'spin 1s linear infinite' }} />
          <style>{`@keyframes spin {0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }}`}</style>
          {typeof isLoading === 'string' && (
            <p style={{ fontSize: '1rem', color: 'var(--color-text1)', textAlign: 'center', marginTop: '1rem' }}>
              {isLoading}
            </p>
          )}
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '.5rem', borderRadius: '50%', backgroundColor: STATUS_CONTENT_MAP[status].iconColor }}>
            {React.cloneElement(STATUS_CONTENT_MAP[status].icon, { style: { color: 'var(--color-textButton)' } })}
          </div>
          <h2 style={{ fontSize: '1.5rem', fontWeight: '600', textAlign: 'center', color: 'var(--color-text1)' }}>
            {STATUS_CONTENT_MAP[status].text}
          </h2>
          <p style={{ fontSize: '1rem', color: 'var(--color-text3)', textAlign: 'center', maxWidth: '600px' }}>
            {STATUS_CONTENT_MAP[status].description}
          </p>
          <button style={{ textAlign: 'center', background: 'var(--color-backgroundSecondary)', color: 'var(--color-text1)', padding: '1rem', borderRadius: '.5rem', fontSize: '1rem', fontWeight: '600', transition: 'all 300ms ease-in-out', position: 'relative', marginTop: '1rem', border: "1px solid var(--color-separatorOpaque)", boxShadow: "var(--borderShadow)" }}
            onMouseEnter={(e) => e.target.style.backgroundColor = 'var(--color-background)'}
            onMouseLeave={(e) => e.target.style.backgroundColor = 'var(--color-backgroundSecondary)'}
            onClick={handleButtonClick}
            >{STATUS_CONTENT_MAP[status].buttonText}
          </button>
        </>
      )}
    </div>
  );
};

const CheckoutCompleteScreen = () => {
  const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

  if (!clientSecret) {
    return <Navigate to="/" />;
  }

  return (
    <Elements 
      stripe={stripePromise} 
      options={{ clientSecret }}
    >
      <PaymentStatusContent />
    </Elements>
  );
};

export default CheckoutCompleteScreen;