'use client';
import React, { useContext, useEffect, useState } from 'react';
import { Drawer } from 'vaul';
import { UserContext } from '../contexts/UserProvider';
import { useBreakpoint } from '../misc/useBreakpoint';
import axios from 'axios';
import { API } from '../misc/constants';
import ReactMarkdown from 'react-markdown';
import { CircularProgress } from '@mui/material';
import { Resource_Loader } from '../components/Resource_Loader';
const defaultStyle = { marginTop: '8px', marginBottom: '10px', fontSize: "1rem", lineHeight: 1.4, textAlign: "left", color: "var(--color-text2)", userSelect: "text" };


const EssayFeedbackModal = ({ open, setOpen, essayText }) => {
  const { user,  } = useContext(UserContext)
  const { isPhoneAndBelow } = useBreakpoint();
  const [ data, setData ] = useState('');


  useEffect(() => {
    if (open) {
      getData();
    }
    else {
      setData('');
    }
  }, [open]);

  const getData = async () => {
    try {
      const result = await axios.post(`${API}/essayShowSuggestion`, { essayText, suggestionText: open });
      const results = result.data.content;
      console.log(results)
      setData(results);
    }
    catch (error) {
      console.error('Error evaluating essay:', error);
    }
  }


  const handleClose = () => {
    setOpen(false);
  }

  return (
      <Drawer.Root open={open} onOpenChange={() => {}} dismissible={false}>
      <Drawer.Portal>
        <Drawer.Overlay style={{ position: 'fixed', inset: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000 }} 
          onClick={handleClose}
        />
        <Drawer.Content style={{ display: "flex", flexDirection: "column", position: "fixed", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", zIndex: 1001, backgroundColor: "var(--color-backgroundSecondary)",
          ...(isPhoneAndBelow && { bottom: 0, left: 0, right: 0 }),
          ...(!isPhoneAndBelow && { top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', maxWidth: '800px', margin: '0 auto', maxHeight: '90vh', borderRadius: '10px', overflow: 'hidden', animation: "none", })
         }}
          aria-labelledby="drawer-title"
          role="alertdialog"
          aria-describedby="drawer-description"
          aria-modal="true"
        >
          <div style={{ width: '100%', margin: '0 auto', overflowY: 'auto', padding: isPhoneAndBelow ? '1.5rem' : '2rem', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', paddingBottom: '1.5rem',  }}>

            <Drawer.Title style={{ fontWeight: 600, color: 'var(--color-text)', fontSize: '1.8rem' }}
              id="drawer-title"
              >Essay Feedback
            </Drawer.Title>
            <Drawer.Description style={{ lineHeight: '1.3', marginTop: '0.5rem', color: 'var(--color-text3)', fontSize: '1rem', padding: "1rem", backgroundColor: "var(--color-backgroundTertiary)", borderRadius: ".5rem", }}
              id="drawer-description"
              // >Upgrade to get access to all features
              >"{open}"
            </Drawer.Description>

            { data ? (
              <div style={{ overflowY: 'auto', maxHeight: '70vh', userSelect: 'text' }}>
                 <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", textAlign: "left", marginTop: '1rem' }}>
                    <ReactMarkdown
                      components={{
                        h1: ({ node, ...props }) => <h1 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        h2: ({ node, ...props }) => <h2 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        h3: ({ node, ...props }) => <h3 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        h4: ({ node, ...props }) => <h4 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        h5: ({ node, ...props }) => <h5 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        h6: ({ node, ...props }) => <h6 style={{ ...defaultStyle, fontWeight: "bold" }} {...props} />,
                        ul: ({ node, ...props }) => <ul style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'disc' }} {...props} />,
                        ol: ({ node, ...props }) => <ol style={{ ...defaultStyle, paddingLeft: '30px', listStyle: 'decimal' }} {...props} />,
                        p: ({ node, ...props }) => <p style={defaultStyle} {...props}/>,
                        li: ({ node, ...props }) => <li style={{ paddingLeft: '0px', listStyle: 'inherit', lineHeight: 1.4 }} {...props} />,
                        pre: ({ node, ...props }) => <pre style={{ ...defaultStyle, backgroundColor: '#f4f4f4', padding: '10px', borderRadius: '4px', overflowX: 'auto' }} {...props} />,
                        a: ({ node, ...props }) => <a style={{ color: '#007bff' }} {...props} />,
                        hr: ({ node, ...props }) => <hr style={{ ...defaultStyle }} {...props} />
                      }}
                      >{data}
                    </ReactMarkdown>    
                  </div>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '4rem' }}>
                <Resource_Loader />
              </div>
            )}


          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

export default EssayFeedbackModal; 

